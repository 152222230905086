import axios from 'axios';
import { msalInstance } from '../..';
import { loginRequest } from '../../authConfig';
import { BFFUrl } from '../util/constants';
import { IOrderTableData } from '../util/Models/OrderTableDataModel';

const uri = `${BFFUrl}/api/v1`;
const stepApi = axios.create({
  baseURL: uri
});

stepApi.interceptors.request.use(async function (config) {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  });

  if (response.accessToken) {
    if (config.headers) {
      config.headers['Authorization'] = `Bearer ${response.accessToken}`;
    }
  }
  return config;
});

const StepService = {
  executeStepOnOrder: async (
    stepName: string,
    orderId: string,
    stepInformation: string[]
  ): Promise<IOrderTableData> => {
    const result = await stepApi.put(`/Step/ExecuteStep`, stepInformation, {
      params: {
        stepName: stepName,
        orderId: orderId
      }
    });

    if (result.data === null) {
      throw new Error('Could not execute step ' + stepName + 'on order ' + orderId);
    }

    return result.data;
  }
};

export default StepService;
