import axios from 'axios';
import { msalInstance } from '../..';
import { loginRequest } from '../../authConfig';
import { BFFUrl } from '../util/constants';
import { IContractInformation } from '../util/Models/ContractModel';

const uri = `${BFFUrl}/api/v1`;
const contractApi = axios.create({
  baseURL: uri
});

contractApi.interceptors.request.use(async function (config) {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  });

  if (response.accessToken) {
    if (config.headers) {
      config.headers['Authorization'] = `Bearer ${response.accessToken}`;
    }
  }
  return config;
});

const ContractService = {
  getContractInformation: async (): Promise<Array<IContractInformation>> => {
    const res = await contractApi.get<Array<IContractInformation>>('/Contract');
    return res.data;
  }
};

export default ContractService;
