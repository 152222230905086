import { ImageInfoModel } from '../Models/ImageInfoModel';

export interface IRawInstallationData {
  customerType: string;
  meterNumber: string;
  distanceFromPanelToCharger: string;
  numberOfHolesToDrill: string;
  construction: boolean;
  constructionInformation: string;
  pavementConstruction: boolean;
  extraInformation: string;
  customerReminderDate: Date | null;
}

export interface IInstallationData extends IRawInstallationData {}

export class InstallationData implements IInstallationData {
  customerType = '';
  meterNumber = '';
  distanceFromPanelToCharger = '';
  numberOfHolesToDrill = '';
  construction = false;
  constructionInformation = '';
  pavementConstruction = false;
  extraInformation = '';
  picturesUploaded = Array<ImageInfoModel>();
  customerReminderDate = null;
}

interface IValidation {
  isValid: (value: any) => boolean;
  message: string;
}

export interface IValidationFields {
  [key: string]: IValidation[];
}
