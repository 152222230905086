const danishCurrencyFormatter = new Intl.NumberFormat('da-DK', {
  style: 'currency',
  currency: 'DKK',
  maximumFractionDigits: 2
});

const danishNumberFormatter = new Intl.NumberFormat('da-DK', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
});

export class PriceService {
  static numberToCurrency(value: number) {
    try {
      return danishCurrencyFormatter.format(value);
    } catch (exception) {
      return value.toString();
    }
  }

  static currencyToNumber(stringNumber: string) {
    try {
      let thousandSeparator = Intl.NumberFormat('da-DK')
        .format(11111)
        .replace(/\p{Number}/gu, '');
      let decimalSeparator = Intl.NumberFormat('da-DK')
        .format(1.1)
        .replace(/\p{Number}/gu, '');

      return parseFloat(
        stringNumber
          .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
          .replace(new RegExp('\\' + decimalSeparator), '.')
      );
    } catch {
      return 0;
    }
  }

  static pointToDecimalSeparator(value: number): string {
    return danishNumberFormatter.format(value);
  }
}
