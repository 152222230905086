import { Box, Typography } from '@mui/material';
import React from 'react';
import { AccessTimeFilled } from '@mui/icons-material';
import { IOrderTableData } from '../../../util/Models/OrderTableDataModel';

interface Props {
  order: IOrderTableData;
}

const OrderInfoBoxHeader: React.FC<Props> = (props) => {
  return (
    <Box sx={OrderInfoBoxHeaderStyle}>
      <Box sx={{ paddingRight: '16px', marginTop: '10px' }}>
        {props.order.rightOfCancellationWaived ? (
          <AccessTimeFilled sx={{ color: 'orange', fontSize: '40px' }} />
        ) : (
          <AccessTimeFilled sx={{ color: 'ghostwhite', fontSize: '40px' }} />
        )}
      </Box>
      <Box sx={{ alignItems: 'center' }}>
        <Typography variant={'h6'} fontWeight={'bold'}>
          {props.order?.customer?.name}
        </Typography>
        <Typography variant={'body2'}>
          Kundenr. {props.order?.customer?.sonwinCustomerNumber}
        </Typography>
        <Typography variant={'body2'}>Order id: {props.order?.id}</Typography>
      </Box>
    </Box>
  );
};

export default OrderInfoBoxHeader;

const OrderInfoBoxHeaderStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  padding: '20px 20px 20px 41px',
  borderBottom: 1,
  borderColor: 'lightgrey',
  height: '90px'
};
