import axios from 'axios';
import { BFFUrl } from '../util/constants';
import LoadingService from './LoadingService';
import { msalInstance } from '../..';
import { loginRequest } from '../../authConfig';
import { FileData } from '../util/Models/FileData';
import { ImageInfoModel } from '../util/Models/ImageInfoModel';

const uri = `${BFFUrl}/api/v1`;
const dataApi = axios.create({
  baseURL: uri
  // baseURL: 'http://localhost:5217/api/v1/'
});

dataApi.interceptors.request.use(async function (config) {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  });

  if (response.accessToken) {
    if (config.headers) {
      config.headers['Authorization'] = `Bearer ${response.accessToken}`;
    }
  }
  return config;
});

const DataService = {
  uploadFile: async (file: FormData): Promise<boolean> => {
    LoadingService.showLoader('Uploader fil...');
    const res = await dataApi.post('/Data/UploadFile', file);
    LoadingService.showLoader('');
    return res.status === 200;
  },
  getFileList: async (count: number): Promise<Array<FileData>> => {
    LoadingService.showLoader('Henter liste...');
    const res = await dataApi.get(`/Data/GetFileList?count=${count}`);
    LoadingService.showLoader('');
    return res.data as Array<FileData>;
  },
  downloadFile: async (blobName: string): Promise<Uint32Array> => {
    LoadingService.showLoader('Henter fil...');
    const res = await dataApi.get(`/Data/DownloadBlob?blobName=${blobName}`);
    LoadingService.showLoader('');
    return res.data as Uint32Array;
  },
  uploadImages: async (files: FormData): Promise<Array<ImageInfoModel>> => {
    LoadingService.showLoader('Uploader billeder...');
    const res = await dataApi.post('/Data/UploadImages', files);
    LoadingService.showLoader('');
    return res.data as Array<ImageInfoModel>;
  },
  getImages: async (orderId: string): Promise<Array<ImageInfoModel>> => {
    const res = await dataApi.get(`/Data/GetImages?orderId=${orderId}`);
    return res.data as Array<ImageInfoModel>;
  },
  deleteImage: async (orderId: string, imageId: string): Promise<boolean> => {
    const res = await dataApi.delete(`/Data/DeleteImage?orderId=${orderId}&imageId=${imageId}`);
    return res.data as boolean;
  }
};

export default DataService;
