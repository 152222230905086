import { Box, Grid, Popover, Typography } from '@mui/material';
import { useState } from 'react';
import CommentField from '../OrderInfoBoxFields/CommentField';
import {
  getCustomerReminderField,
  getLastContactAttemptField,
  StatusField
} from '../OrderInfoBoxFields/StatusField';
import { FieldSettings, OrderInfoTabProps } from './CustomerTab';
import { OnboardingField } from '../OrderInfoBoxFields/OnboardingField';

export const OnboardingTab: React.FC<OrderInfoTabProps> = (props) => {
  const { order, canEdit, configuration, userName, secondaryTextColor } = props;
  const [isValidStatus, setIsValidStatus] = useState(true);
  const [isValidContactAttempt, setIsValidContactAttempt] = useState(true);
  const [isValidOnboardingSteps, setIsValidOnboardingSteps] = useState(true);
  const [isValidCustomerReminderDate, setIsValidCustomerReminderDate] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const fieldSettings: FieldSettings = {
    order: order,
    canEdit: canEdit,
    setOrder: props.setOrder,
    configuration: configuration,
    textColor: secondaryTextColor
  };

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid container direction={'row'} sx={GridStyle}>
        <Grid item xs={12} sx={RowGridStyle}>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontWeight={'bold'}>Status</Typography>
          </Grid>
          <Grid item xs={6}>
            <StatusField
              isValidStatus={isValidStatus}
              setIsValidStatus={setIsValidStatus}
              fieldSettings={fieldSettings}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sx={RowGridStyle}>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontWeight={'bold'}>Senest forsøgt kontaktet</Typography>
          </Grid>
          <Grid item xs={6}>
            {getLastContactAttemptField(
              isValidContactAttempt,
              setIsValidContactAttempt,
              fieldSettings
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sx={RowGridStyle}>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontWeight={'bold'}>Status hos Atea</Typography>
          </Grid>
          <Grid item xs={6}>
            {order?.ateaSubstatus && order?.ateaSubstatus.length > 0 ? order?.ateaSubstatus : ' - '}
          </Grid>
        </Grid>
        <Grid item xs={12} sx={RowGridStyle}>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontWeight={'bold'}>Atea kommentar</Typography>
          </Grid>
          <Grid item xs={6}>
            {order.ateaComments?.length > 0 ? (
              <Box
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                whiteSpace={'nowrap'}
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                maxWidth={'48rem'}
              >
                {order.ateaComments}
                <Popover
                  id="atea-comments-popover"
                  sx={{
                    pointerEvents: 'none'
                  }}
                  open={open}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Box maxWidth={'48rem'}>
                    <Typography
                      pb={'0.1rem'}
                      pt={'0.4rem'}
                      color={'white'}
                      bgcolor={'#1976d2'}
                      textAlign={'center'}
                    >
                      Atea kommentar
                    </Typography>
                    <Box p={'0.8rem'}>{order.ateaComments}</Box>
                  </Box>
                </Popover>
              </Box>
            ) : (
              <>-</>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sx={RowGridStyle}>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontWeight={'bold'}>Send SMS påmindelse</Typography>
          </Grid>
          <Grid item xs={6}>
            {order.installationData.customerReminderDate &&
              getCustomerReminderField(
                isValidCustomerReminderDate,
                setIsValidCustomerReminderDate,
                fieldSettings
              )}
          </Grid>
        </Grid>
      </Grid>
      <OnboardingField
        isValid={isValidOnboardingSteps}
        setIsValid={setIsValidOnboardingSteps}
        fieldSettings={fieldSettings}
      />
      <Box sx={{ marginTop: '10px' }}>
        <CommentField fieldSettings={fieldSettings} userName={userName} />
      </Box>
    </>
  );
};

const GridStyle = {
  borderBottom: 1,
  borderColor: 'lightgrey',
  marginTop: '10px'
};

const RowGridStyle = {
  display: 'flex',
  margin: '0 0 15px 10px'
};
