import { Customer, ICustomer, IRawCustomer } from './CustomerModel';
import { IComment, IRawComment } from './CommentModel';
import { IStep } from './StepModel';
import { OrderStatusEnum } from '../Enums/OrderStatusEnum';
import { EnergyCusomerStatusEnum } from '../Enums/EnergyCustomerStatusEnum';
import { HousingTypeEnum } from '../Enums/HousingTypeEnum';
import { OwnerTypeEnum } from '../Enums/OwnerTypeEnum';
import { OrderState } from '../Enums/OrderState';
import { ShopDetails, IShopDetails, IRawShopDetails } from './shopDetailsModel';
import {
  IInstallationData,
  InstallationData,
  IRawInstallationData
} from '../Interfaces/IInstallationData';

export interface IRawOrderTableData {
  id: string;
  crmOrderId: string;
  ateaOrderId: string;
  customerId: string;
  initializedBy: string;
  modifiedBy: string;
  contactAttempt: string;
  price: number;
  applicationDate: string;
  contractDate: string;
  modified: string;
  wishedInstallationMonth: string;
  flowId: string;
  energyCustomer: EnergyCusomerStatusEnum;
  deliveryAddressId: string;
  installationAddressId: string;
  deliveryAddress: string;
  installationAddress: string;
  separateDeliveryAddress: boolean;
  installationNumber: string;
  channel: string;
  partner: string;
  housingType: HousingTypeEnum;
  status: OrderStatusEnum;
  ownerType: OwnerTypeEnum;
  customer: IRawCustomer;
  shopDetails: IRawShopDetails;
  steps: Array<IStep>;
  comments: Array<IRawComment>;
  dealerFunded: boolean;
  rightOfCancellationWaived: boolean;
  evbNumber: string;
  removalOfChargingStand: boolean;
  extraInstallationCost: number;
  referenceId: string;
  subscriptionContractId: string;
  subscriptionContractCompanyId: string;
  installationContractId: string;
  installationContractCompanyId: string;
  numberOfConnectors: number;
  installationData: IRawInstallationData;
  ateaSubstatus: string;
  anchor: number;
  chargingStand: number;
}

export interface IRawOrderCreateData
  extends Omit<
    IRawOrderTableData,
    'id' | 'customerId' | 'initializedBy' | 'modifiedBy' | 'steps' | 'deliveryAddressId'
  > {
  deliveryAddressId: string | null;
}

export interface IOrderTableData
  extends Omit<
    IRawOrderTableData,
    | 'applicationDate'
    | 'contractDate'
    | 'contactAttempt'
    | 'modified'
    | 'wishedInstallationMonth'
    | 'customer'
    | 'comments'
    | 'price'
    | 'channel'
    | 'partner'
    | 'numberOfConnectors'
    | 'installationData'
  > {
  applicationDate: Date | null;
  contractDate: Date | null;
  contactAttempt: Date | null;
  modified: Date | null;
  wishedInstallationMonth: Date | null;
  shopDetails: IShopDetails;
  customer: ICustomer;
  comments: Array<IComment>;
  state: OrderState;
  lockedBy: string;
  price: number | null;
  channel: string | null;
  partner: string | null;
  numberOfConnectors: number;
  installationData: IInstallationData;
  ateaSubstatus: string;
  chargingStand: number;
  anchor: number;
  ateaComments: string;
  montaInstallJobUrl: string;
}

export const createNewOrderEntity = (): IOrderTableData => {
  const newOrder: IOrderTableData = {
    id: (Math.floor(Math.random() * 100) + 1).toString(),
    crmOrderId: '00000000-0000-0000-0000-000000000000',
    ateaOrderId: '',
    customerId: '',
    initializedBy: '',
    modifiedBy: '',
    contactAttempt: null,
    price: null,
    applicationDate: new Date(),
    contractDate: null,
    modified: new Date(),
    wishedInstallationMonth: null,
    flowId: '',
    energyCustomer: -1,
    deliveryAddressId: '00000000-0000-0000-0000-000000000000',
    installationAddressId: '',
    deliveryAddress: '',
    installationAddress: '',
    separateDeliveryAddress: false,
    installationNumber: '',
    channel: null,
    partner: null,
    housingType: HousingTypeEnum['Ikke Angivet'],
    status: -1,
    ownerType: -1,
    shopDetails: new ShopDetails(),
    customer: new Customer(),
    steps: Array<IStep>(),
    comments: new Array<IComment>(),
    state: OrderState.NEW,
    lockedBy: '',
    dealerFunded: false,
    rightOfCancellationWaived: false,
    evbNumber: '',
    removalOfChargingStand: false,
    extraInstallationCost: 0,
    referenceId: '',
    subscriptionContractId: '',
    subscriptionContractCompanyId: '',
    installationContractId: '',
    installationContractCompanyId: '',
    numberOfConnectors: 0,
    installationData: new InstallationData(),
    ateaSubstatus: '',
    chargingStand: 0,
    anchor: 0,
    ateaComments: '',
    montaInstallJobUrl: ''
  };
  return newOrder;
};
