import { createContext, useContext, useState } from 'react';

interface EditOrderContextType {
  editingState: boolean;
  setEditingState: React.Dispatch<React.SetStateAction<boolean>>;
  newComment: string;
  setNewComment: React.Dispatch<React.SetStateAction<string>>;
}

export const EditOrderContext = createContext<null | EditOrderContextType>(null);

interface Props {
  children: React.ReactNode;
}

export const EditOrderContextProvider = ({ children }: Props) => {
  const [editingState, setEditingState] = useState(false);
  const [newComment, setNewComment] = useState('');

  return (
    <EditOrderContext.Provider value={{ editingState, setEditingState, newComment, setNewComment }}>
      {children}
    </EditOrderContext.Provider>
  );
};

export const useEditOrderContext = () => {
  const editOrderContext = useContext(EditOrderContext);

  if (!editOrderContext) throw new Error('You need to use this context inside a provider');

  return editOrderContext;
};
