import { Grid } from '@mui/material';
import { IOrderTableData } from '../../../../util/Models/OrderTableDataModel';
import InstallationDataForm from '../../../InstallationDataForm/InstallationDataForm';

export interface InstallationDataTabProps {
  order: IOrderTableData;
  setOrder: React.Dispatch<React.SetStateAction<IOrderTableData>>;
  canEdit: boolean;
}

export const InstallationDataTab: React.FC<InstallationDataTabProps> = (props) => {
  const { order, canEdit, setOrder } = props;
  return (
    <Grid container direction={'column'} spacing={1} paddingLeft={0.5} sx={GridStyle}>
      <Grid item xs={12}>
        <InstallationDataForm canEdit={canEdit} order={order} setOrder={setOrder} />
      </Grid>
    </Grid>
  );
};

const GridStyle = {
  borderColor: 'lightgrey',
  marginTop: '10px'
};
