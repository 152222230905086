import React, { createContext, useContext, useReducer } from 'react';
import { orderReducer, OrderActions, IState, initialState } from './OrderReducer';

type OrdersContextType = {
  state: IState;
  dispatch: React.Dispatch<OrderActions>;
};

const OrderContext = createContext<OrdersContextType>({
  state: initialState,
  dispatch: () => undefined
});

const useOrderContext = (): OrdersContextType => useContext(OrderContext);

const OrderProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(orderReducer, initialState);

  return <OrderContext.Provider value={{ state, dispatch }}>{children}</OrderContext.Provider>;
};

export { OrderProvider, useOrderContext };
