import { Autocomplete, InputLabel, TextField, Typography } from '@mui/material';
import styled, { css } from 'styled-components';
import { IValidation, InputType, validateInput } from '../../util/Helpers/InputValidationHelper';
import { useEffect, useState } from 'react';

interface Props {
  value: string;
  handleChange: (value: string) => void;
  id: string;
  label: string;
  error: boolean;
  errorField: string;
  canEdit: boolean;
  meteringNumbers: Array<string>;
  validation?: IValidation;
  isValid: boolean;
  loading?: boolean;
  loadingText?: string;
}

const AutocompleteFormInput = ({ ...props }: Props) => {
  const [touched, setTouched] = useState(false);
  const inputType = props.validation ? props.validation.type : InputType.None;
  const validationErrorMessage = props.validation?.validationErrorMessage;

  useEffect(() => {
    setTouched(false);
  }, [props.canEdit]);

  const checkValidation = (targetValue: string) => {
    if (props.validation?.validationStatus) {
      props.validation.validationStatus(
        validateInput(inputType, targetValue, props.validation?.regex)
      );
    }
  };

  const handleChange = (value: string) => {
    checkValidation(value);
    props.handleChange(value);
    setTouched(true);
  };

  return (
    <InputWrapper fullWidth={true}>
      <Typography fontSize={14} fontWeight={'bold'}>
        {props.label}
      </Typography>
      {props.canEdit ? (
        <>
          <Autocomplete
            loading={props.loading}
            loadingText={props.loadingText}
            sx={{ paddingRight: 0 }}
            value={props.value}
            freeSolo
            options={props.meteringNumbers.map((value) => value)}
            renderInput={(params) => (
              <TextField
                onBlur={(e) => {
                  handleChange(e.target.value);
                }}
                error={!props.isValid && touched}
                value={props.value}
                {...params}
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
                variant="standard"
              />
            )}
            onChange={(e, val) => {
              if (val !== null) {
                handleChange(val);
              }
            }}
          />
          {!props.isValid && touched ? (
            <InputLabel sx={{ alignSelf: 'start', fontSize: 10 }}>
              {validationErrorMessage}
            </InputLabel>
          ) : null}
        </>
      ) : (
        <Typography color={'#858383'}>{props.value}</Typography>
      )}
    </InputWrapper>
  );
};

const InputWrapper = styled.div<{ fullWidth: boolean }>`
  display: flex;
  justify-content: left;
  padding-right: 1rem;
  ${(props) =>
    !props.fullWidth &&
    css`
      max-width: 200px;
    `}

  flex-direction: column;
`;

export default AutocompleteFormInput;
