import {
  Box,
  Button,
  Checkbox,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { useState } from 'react';
import StepService from '../../data-access/StepService';
import { StepStatus } from '../../util/Enums/StepStatusEnum';
import { IStep } from '../../util/Models/StepModel';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { IOrderTableData } from '../../util/Models/OrderTableDataModel';
import { OrderState } from '../../util/Enums/OrderState';
import { OrderStatusEnum } from '../../util/Enums/OrderStatusEnum';

interface Props {
  step: IStep;
  canEdit: boolean;
  order: IOrderTableData;
  setOrder: React.Dispatch<React.SetStateAction<IOrderTableData>>;
  disabled?: boolean;
}

const AutomaticStepButton = (props: Props) => {
  const { order, setOrder, canEdit } = props;
  const [openStartFlowDialog, setOpenStartFlowDialog] = useState(false);
  const [errorText, setErrorText] = useState(props.step.statusMessage);
  const [stepInformation, setStepInformation] = useState<string[]>([]);
  const [sendHasJustBeenPressed, setSendHasJustBeenPressed] = useState(false);

  const step = props.step;

  const theme = createTheme({
    palette: {
      secondary: {
        main: '#0971f1'
      }
    }
  });

  function getButtonText() {
    if (step.status === StepStatus.Started) return 'Igang';
    if (step.status === StepStatus.Error) return 'Fejl!';
    return 'Start';
  }

  function getButtonVariant() {
    if (step.status === StepStatus.Error) return 'contained';
    return 'outlined';
  }

  function getButtonColor() {
    if (step.status === StepStatus.Started) return 'secondary';
    if (step.status === StepStatus.Error) return 'error';
    return 'primary';
  }

  function getFlowText() {
    if (step.status === StepStatus.Started || step.status === StepStatus.Error) return 'Prøv igen';
    return 'Start automatisk flow';
  }

  function getDialogTitle() {
    if (step.name === 'contractAttachment') return 'Tilføj Kontrakt';
    return props.step.label;
  }

  function getDialogText() {
    if (step.status != StepStatus.Error) return <></>;

    return (
      <DialogContent sx={{ paddingBottom: '0px' }}>
        <DialogContentText sx={{ color: 'red' }}>{errorText}</DialogContentText>
      </DialogContent>
    );
  }

  function getStepInformation() {
    if (step.name === 'TopDkLead') {
      const flytteforsikring = 'Flytteforsikring';
      const top20 = 'Top20';
      return (
        <DialogContent>
          <div style={{ marginBottom: '10px' }}>
            <StepInformationText>
              Send bugseringsservice-lead med følgende tilvalg:
            </StepInformationText>
          </div>
          <div>
            <Checkbox
              checked={stepInformation.some((s) => s === flytteforsikring)}
              onChange={(e) => {
                if (e.target.checked) {
                  setStepInformation([...stepInformation, flytteforsikring]);
                } else {
                  setStepInformation([...stepInformation].filter((s) => s != flytteforsikring));
                }
              }}
            />
            <StepInformationText>{flytteforsikring}</StepInformationText>
          </div>
          <div>
            <Checkbox
              checked={stepInformation.some((s) => s === top20)}
              onChange={(e) => {
                if (e.target.checked) {
                  setStepInformation([...stepInformation, top20]);
                } else {
                  setStepInformation([...stepInformation].filter((s) => s != top20));
                }
              }}
            />
            <StepInformationText>{top20}</StepInformationText>
          </div>
        </DialogContent>
      );
    }
    if (step.name === 'contractAttachment') {
      return (
        <>
          {order.shopDetails?.campaign?.length > 0 && (
            <DialogContent
              sx={{
                display: 'flex',
                flexDirection: 'row',
                paddingBottom: '1rem'
              }}
            >
              <DialogContentText sx={{ color: 'black' }}>
                BEMÆRK: Denne ordre har en kampagnekode ({order.shopDetails?.campaign})
              </DialogContentText>
            </DialogContent>
          )}

          {(order.extraInstallationCost === undefined || order.extraInstallationCost === 0) && (
            <DialogContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: '0px',
                paddingTop: '0px'
              }}
            >
              <DialogContentText sx={{ color: 'black', paddingBottom: '0.5rem' }}>
                BEMÆRK: Der er ikke lagt ekstra installationsomkostninger på ordren.
              </DialogContentText>
            </DialogContent>
          )}
        </>
      );
    }
    if (step.name === 'picturesSentToAtea') {
      return (
        <DialogContent>
          <div style={{ marginBottom: '10px' }}>
            <StepInformationText>Send til Atea</StepInformationText>
          </div>
        </DialogContent>
      );
    }
    return <></>;
  }

  const handleStatusChange = (newStepStatus: StepStatus, orderStatus?: OrderStatusEnum) => {
    setOpenStartFlowDialog(false);
    let newSteps = order.steps.map((s, _index) => {
      if (step.name === s.name) {
        return { ...s, status: newStepStatus };
      } else {
        return s;
      }
    });

    let newOrderStatus = orderStatus ?? props.order.status;

    setOrder({ ...order, steps: newSteps, status: newOrderStatus });
  };

  const startAutomaticStep = async () => {
    if (sendHasJustBeenPressed) return;
    setSendHasJustBeenPressed(true);
    handleStatusChange(StepStatus.Started);
    try {
      let res = await StepService.executeStepOnOrder(
        props.step.name,
        props.order.id,
        stepInformation
      );
      setSendHasJustBeenPressed(false);

      for (let step of res.steps) {
        if (step.name == props.step.name) {
          setErrorText(step.statusMessage ? step.statusMessage : '');
        }
      }
    } catch (error) {
      setSendHasJustBeenPressed(false);
      let errorMessage = '';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      handleStatusChange(StepStatus.Error);
      setErrorText(errorMessage);
    }
  };

  function isButtonDisabled() {
    return (
      order.state === OrderState.DIRTY ||
      (step.name === 'contractAttachment' && step.status !== StepStatus.Error) ||
      step.name === 'picturesSentToAtea' ||
      step.name === 'sentToMonta'
    );
  }

  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
    >
      {step.name === 'contractAttachment' ? (
        ''
      ) : (
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-start'}
          alignItems={'center'}
        >
          <Checkbox checked={step.status === StepStatus.Completed} disabled={true} />
          <Typography>{step.label}</Typography>
        </Box>
      )}

      {step.name === 'contractAttachment' && step.status !== StepStatus.Error ? (
        ''
      ) : (
        <ThemeProvider theme={theme}>
          {canEdit && step.status != StepStatus.Completed && (
            <Tooltip
              title={
                order.state === OrderState.DIRTY
                  ? 'Gem ordren (CTRL + S) før du kan benytte knappen'
                  : ''
              }
            >
              <Box
                sx={{
                  width: step.name === 'contractAttachment' ? '100%' : '6rem'
                }}
              >
                <Button
                  fullWidth={true}
                  variant={getButtonVariant()}
                  color={getButtonColor()}
                  onClick={() => setOpenStartFlowDialog(true)}
                  disabled={isButtonDisabled() || props.disabled === true}
                  sx={{ marginRight: '10px' }}
                >
                  {(step.name === 'contractAttachment' ? 'Tilføj kontrakt ' : '') + getButtonText()}
                </Button>
              </Box>
            </Tooltip>
          )}

          <Dialog
            aria-labelledby="dialog-title"
            aria-describedby="dialog-descroption"
            open={openStartFlowDialog}
            onClose={() => setOpenStartFlowDialog(false)}
          >
            <IconButton
              style={{ position: 'absolute', top: '0', right: '0' }}
              onClick={() => setOpenStartFlowDialog(false)}
            >
              <CloseIcon />
            </IconButton>
            <DialogTitle id="dialog-title">{getDialogTitle()}</DialogTitle>
            {getDialogText()}
            {getStepInformation()}
            <StyledDialogActions>
              <Button
                autoFocus
                onClick={startAutomaticStep}
                variant={'contained'}
                color={'primary'}
                disabled={
                  sendHasJustBeenPressed ||
                  step.name === 'contractAttachment' ||
                  props.disabled === true
                }
              >
                {getFlowText()}
              </Button>
              <Button
                disabled={true}
                onClick={() => {
                  handleStatusChange(
                    StepStatus.Completed,
                    step.name === 'contractAttachment' ? OrderStatusEnum.Gennemført : undefined
                  );
                }}
              >
                Marker som afsluttet
              </Button>
            </StyledDialogActions>
          </Dialog>
        </ThemeProvider>
      )}
    </Box>
  );
};

const StepInformationText = styled.h4`
  display: inline;
  color: #0c0c0c;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
`;

const StyledDialogActions = styled(DialogActions)`
  display: block !important;
  padding-top: 0px !important;
  text-align: center;
`;

export default AutomaticStepButton;
