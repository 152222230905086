import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { OrderState } from '../../../../util/Enums/OrderState';
import { IOrderTableData } from '../../../../util/Models/OrderTableDataModel';

interface IAnchorFieldProps {
  order: IOrderTableData;
  setOrder: React.Dispatch<React.SetStateAction<IOrderTableData>>;
  canEdit: boolean;
  textColor: string;
}

export function getAnchorField(props: IAnchorFieldProps) {
  const { canEdit, order, setOrder, textColor } = props;

  return (
    <Box>
      {canEdit && (
        <FormControlLabel
          control={
            <Checkbox
              checked={order.anchor > 0}
              onChange={(e, checked) => {
                setOrder({
                  ...order,
                  anchor: checked ? 1 : 0,
                  state: OrderState.DIRTY
                });
              }}
            />
          }
          label={'Anker'}
        />
      )}
      {!canEdit && (
        <>
          <Typography fontSize={14} fontWeight={'bold'}>
            Anker
          </Typography>
          <Typography color={textColor}>{order.anchor > 0 ? 'Ja' : 'Nej'}</Typography>
        </>
      )}
    </Box>
  );
}
