import {
  Modal,
  Box,
  Button,
  SelectChangeEvent,
  Grid,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextareaAutosize,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import DateWithCopy from '../../ui/InputWithCopy/DateWithCopy';
import InputWithCopy, { InputWithCopyHandle } from '../../ui/InputWithCopy/InputWithCopy';
import SelectWithCopy from '../../ui/InputWithCopy/SelectWithCopy';
import { EnergyCusomerStatusEnum } from '../../util/Enums/EnergyCustomerStatusEnum';
import { IOrderTableData, createNewOrderEntity } from '../../util/Models/OrderTableDataModel';
import DawaSuggestionFeature from '../DawaSuggestion/DawaSuggestion';
import ConfigurationContext from '../../data-access/Context/ConfigurationContext';
import { OwnerTypeEnum } from '../../util/Enums/OwnerTypeEnum';
import moment from 'moment';
import { IComment } from '../../util/Models/CommentModel';
import MessageService, { SnackbarSeverity } from '../../data-access/MessageService';
import { useMsal } from '@azure/msal-react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Comment from './Comment';
import OrderService from '../../data-access/OrderService';
import { useSaveKeysDown } from '../../hooks/useKeyDown';
import { PriceService } from '../../util/Services/PriceService';
import { InputType } from '../../util/Helpers/InputValidationHelper';

interface ICreateOrder {
  showOrder: boolean;
  setShowOrder: React.Dispatch<React.SetStateAction<boolean>>;
  setNewOrderToDisplay: React.Dispatch<React.SetStateAction<IOrderTableData | null>>;
  setIsNewOrder: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateOrder = (props: ICreateOrder) => {
  const config = useContext(ConfigurationContext);
  const inputRefFromParent = useRef<InputWithCopyHandle | undefined>();
  const [order, setOrder] = useState<IOrderTableData>(createNewOrderEntity());
  const { accounts } = useMsal();
  const [commentsExpanded, setCommentsExpanded] = useState(false);
  const [salesInformationExpanded, setSalesInformationExpanded] = useState(false);

  //Validation
  const [isValidName, setIsValidName] = useState(false);
  const [isValidSecondaryName, setIsValidSecondaryName] = useState(true);
  const [isValidStatus, setIsValidStatus] = useState(order && order.status >= 0);
  const [isValidMail, setIsValidMail] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(false);
  const [isValidAddress, setIsValidAddress] = useState(false);
  const [isValidAlternativeAddress, setIsValidAlternativeAddress] = useState(false);
  const [isValidInstallationNumber, setIsValidInstallationNumber] = useState(false);
  const [isValidSonwinCustomerNumber, setIsValidSonwinCustomerNumber] = useState(false);
  const [isValidPrice, setIsValidPrice] = useState(order && order.price != null);
  const [isValidApplicationDate, setIsValidApplicationDate] = useState(true);
  const [isValidEnergyCustomer, setIsValidEnergyCustomer] = useState(order.energyCustomer >= 0);
  const [isValidChannel, setIsValidChannel] = useState(order.channel != null);
  const [isValidOwnerType, setIsValidOwnerType] = useState(order.ownerType >= 0);
  const [isValidContactAttempt, setIsValidContactAttempt] = useState(true);
  const [isValidInstallationMonth, setIsValidInstallationMonth] = useState(
    order.wishedInstallationMonth != null
  );
  const [isValidReferenceId, setIsValidReferenceId] = useState(true);

  const [newComment, setNewComment] = useState('');
  const [priceValues, setPriceValues] = useState<Array<string>>([]);
  const [allValid, setAllValid] = useState(false);

  const outboundAndPartnerValues = ['Partnerskab', 'Outbound', 'TCC', 'Firmabil'];

  useSaveKeysDown(() => {
    if (allValid) saveOrder();
  }, ['s', 'S']);

  useEffect(() => {
    setTimeout(() => {
      if (inputRefFromParent.current) {
        inputRefFromParent.current.setFocus();
      }
    }, 100);

    resetValidation();
  }, [props.showOrder]);

  useEffect(() => {
    if (config) {
      setPriceValues(setPriceArray());
    }
  }, [config]);

  function resetValidation() {
    setIsValidName(false);
    setIsValidSecondaryName(true);
    setOrder({ ...order, status: 1 });
    setIsValidStatus(order && order.status >= 0);
    setIsValidMail(false);
    setIsValidPhoneNumber(false);
    setIsValidAddress(false);
    setIsValidAlternativeAddress(false);
    setIsValidInstallationNumber(false);
    setIsValidSonwinCustomerNumber(false);
    setIsValidPrice(order && order.price != null);
    setIsValidApplicationDate(true);
    setIsValidEnergyCustomer(order.energyCustomer >= 0);
    setIsValidChannel(order.channel != null);
    setIsValidOwnerType(order.ownerType >= 0);
    setIsValidContactAttempt(true);
    setIsValidInstallationMonth(order.wishedInstallationMonth != null);
    setIsValidReferenceId(true);
  }

  function validateAndSetDate(
    e: Date | null,
    setValid: React.Dispatch<React.SetStateAction<boolean>>,
    field: string
  ) {
    if (!moment(e).isValid() && e != null) {
      setValid(false);
      return;
    }

    if (field === 'contactAttempt') {
      setOrder({ ...order, contactAttempt: e });
      setValid(true);
    } else {
      setOrder({ ...order, wishedInstallationMonth: e });
      setValid(e != null);
    }
  }

  const setPriceArray = () => {
    if (config?.prices) {
      const priceArray = [...config.prices.map((num) => PriceService.numberToCurrency(num))];
      return priceArray;
    }
    return [];
  };

  const saveOrder = async () => {
    let result: IOrderTableData | null = await OrderService.postOrder(order);

    if (result != null) {
      props.setNewOrderToDisplay(result);
      props.setIsNewOrder(true);
    }

    if (result) {
      close();

      MessageService.showSnackbar({
        message: 'Ordre oprettet med succes',
        severity: SnackbarSeverity.SUCCESS
      });
    } else {
      MessageService.showSnackbar({
        message: 'Fejl opstod under ordreoprettelse',
        severity: SnackbarSeverity.ERROR
      });
    }
  };

  function guidGenerator() {
    let S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
  }

  const saveComment = async () => {
    try {
      let comment = {
        content: newComment,
        author: accounts[0]?.username || '',
        created: new Date(),
        orderId: guidGenerator()
      } as IComment;
      setOrder({ ...order, comments: [...order.comments, comment] });
      setNewComment('');
    } catch (exception) {
      MessageService.showSnackbar({
        message: 'En fejl opstod under oprettelse af kommentar',
        severity: SnackbarSeverity.ERROR
      });
    }
  };

  const close = () => {
    setOrder(createNewOrderEntity());
    setNewComment('');
    props.setShowOrder(false);
  };

  const getOutboundTitle = () => {
    if (order.channel && outboundAndPartnerValues.includes(order.channel)) {
      return order.channel.toString();
    }
    return 'Ingen partnerskab valgt';
  };

  const validChannel = () => {
    if (order.channel && outboundAndPartnerValues.includes(order.channel)) {
      return order.partner != null && order.partner.length > 0;
    } else return true;
  };

  useEffect(() => {
    setAllValid(
      isValidName &&
        isValidSecondaryName &&
        isValidMail &&
        isValidAddress &&
        isValidPhoneNumber &&
        isValidStatus &&
        isValidPrice &&
        isValidSonwinCustomerNumber &&
        isValidEnergyCustomer &&
        isValidInstallationNumber &&
        isValidApplicationDate &&
        isValidChannel &&
        isValidOwnerType &&
        isValidContactAttempt &&
        isValidInstallationMonth &&
        isValidReferenceId &&
        validChannel() &&
        (order.separateDeliveryAddress ? isValidAlternativeAddress : true) &&
        newComment.length == 0
    );
  }, [
    isValidName,
    isValidSecondaryName,
    isValidMail,
    isValidAddress,
    isValidPhoneNumber,
    isValidStatus,
    isValidPrice,
    isValidSonwinCustomerNumber,
    isValidEnergyCustomer,
    isValidInstallationNumber,
    isValidApplicationDate,
    isValidChannel,
    isValidOwnerType,
    isValidContactAttempt,
    isValidInstallationMonth,
    isValidReferenceId,
    order.separateDeliveryAddress,
    isValidAlternativeAddress,
    newComment
  ]);

  const getOutboundOrChannelValues = () => {
    if (!config) {
      return [];
    }

    if ((order.channel === 'Outbound' || order.channel === 'TCC') && config.outbounds) {
      return config.outbounds;
    } else if (order.channel === 'Partnerskab' && config.partners) {
      return config.partners;
    } else if (order.channel === 'Firmabil' && config.partners) {
      return config.companyCars;
    }
    return [];
  };

  function validateAndSetApplicationDate(e: Date | null) {
    if (!moment(e).isValid() && e != null) {
      setIsValidApplicationDate(false);
      return;
    }

    let now = new Date();

    if (
      e?.getFullYear() === now.getFullYear() &&
      e.getMonth() === now.getMonth() &&
      e.getDate() === now.getDate()
    ) {
      e.setHours(now.getHours());
      e.setMinutes(now.getMinutes());
      e.setSeconds(now.getSeconds());
      e.setMilliseconds(now.getMilliseconds());
    }

    if (!moment(e).isValid() && e != null) {
      setIsValidApplicationDate(false);
      return;
    }

    setOrder({ ...order, applicationDate: e });
    setIsValidApplicationDate(true);
  }

  const orderExists = async (addressId: string, address: string) => {
    let result = await OrderService.orderExist(addressId);
    if (result) {
      MessageService.showDialog({
        title: 'Aktiv ordre fundet',
        message: `En aktiv ordre eksisterer allerede på adressen: ${address}.`
      });
    }
  };

  const handleGroupConsentChecked = useCallback(() => {
    setOrder({
      ...order,
      customer: { ...order.customer, groupConsent: !order.customer.groupConsent }
    });
  }, [order]);

  return (
    <Modal
      sx={{ marginTop: '16px' }}
      open={props.showOrder}
      onClose={() => {
        props.setShowOrder(false);
      }}
      aria-labelledby="opret-ordre"
      aria-describedby="opret-ordre"
    >
      <CreateOrderWrapper>
        <Box>
          <Divider sx={{ marginTop: '16px' }}>
            <Typography fontSize={'18px'} fontWeight={'bold'}>
              Kundedata
            </Typography>
          </Divider>

          <Grid container spacing={4} mt={0.5}>
            <Grid item xs={2.4}>
              <InputWithCopy
                ref={inputRefFromParent}
                showAsterisk={true}
                fullWidth={true}
                label="Kundenavn"
                value={order?.customer.name}
                validation={{
                  type: InputType.Name,
                  validationErrorMessage: 'Navn skal være udfyldt',
                  validationStatus: (valid: boolean) => {
                    setIsValidName(valid);
                  }
                }}
                onChange={(e: string) => {
                  setOrder({ ...order, customer: { ...order.customer, name: e } });
                }}
                dataTestId={'customer-name'}
              />
            </Grid>
            <Grid item xs={2.4}>
              <InputWithCopy
                fullWidth={true}
                label="Sekundært kundenavn"
                value={order?.customer.secondaryName}
                validation={{
                  type: InputType.Name,
                  validationErrorMessage: 'Minimum 2 ord og kun bogstaver',
                  validationStatus: (valid: boolean) => {
                    setIsValidSecondaryName(valid);
                  }
                }}
                onChange={(e: string) => {
                  setOrder({ ...order, customer: { ...order.customer, secondaryName: e } });
                }}
                allowEmpty={true}
                dataTestId={'secondary-customer-name'}
              />
            </Grid>
            <Grid item xs={2.4}>
              <InputWithCopy
                showAsterisk={true}
                fullWidth={true}
                label="Mail"
                value={order.customer.email}
                validation={{
                  type: InputType.Email,
                  validationErrorMessage: 'Email skal være udfyldt',
                  validationStatus: (valid: boolean) => {
                    setIsValidMail(valid);
                  }
                }}
                onChange={(e: string) => {
                  setOrder({ ...order, customer: { ...order.customer, email: e } });
                }}
                dataTestId={'customer-email'}
              />
            </Grid>
            <Grid item xs={2.4}>
              <InputWithCopy
                showAsterisk={true}
                fullWidth={true}
                label="Tlf."
                value={order.customer.phone}
                validation={{
                  type: InputType.Phone,
                  validationErrorMessage: 'Tlf. skal være udfyldt',
                  validationStatus: (valid: boolean) => {
                    setIsValidPhoneNumber(valid);
                  }
                }}
                onChange={(e: string) => {
                  setOrder({ ...order, customer: { ...order.customer, phone: e } });
                }}
                dataTestId={'customer-phone'}
              />
            </Grid>
            <Grid item xs={2.4}>
              <DawaSuggestionFeature
                fullWidth={true}
                showAsterisk={true}
                label="Inst. adresse"
                value={order.installationAddress}
                suggestionPicked={(item) => {
                  setIsValidAddress(true);
                  setOrder({
                    ...order,
                    installationAddress: item.tekst,
                    installationAddressId: item.adresse.id
                  });
                  orderExists(item.adresse.id, item.tekst);
                }}
                validation={{
                  type: InputType.Text,
                  validationErrorMessage: 'Adresse skal være udfyldt',
                  validationStatus: (valid: boolean) => {
                    setIsValidAddress(valid);
                  }
                }}
                onChange={() => {
                  setIsValidAddress(false);
                }}
                showValidationError={!isValidAddress}
                dataTestId={'customer-address'}
              />
            </Grid>

            {/* række 2 */}
            <Grid item xs={2.4}>
              <DateWithCopy
                error={!isValidApplicationDate}
                showAsterisk={true}
                fullWidth={true}
                label="Bestillingsdato"
                value={order.applicationDate}
                onChange={(e: Date | null) => {
                  validateAndSetApplicationDate(e);
                }}
                maxDate={new Date()}
                dataTestId={'order-date'}
              />
            </Grid>
            <Grid item xs={2.4}>
              <SelectWithCopy
                showAsterisk={true}
                label="Pris"
                fullWidth={true}
                values={priceValues}
                error={!isValidPrice}
                value={order.price != null && config ? config.prices.indexOf(order.price) : -1}
                onChange={(e: SelectChangeEvent) => {
                  setOrder({ ...order, price: PriceService.currencyToNumber(e.target.value) });
                  setIsValidPrice(true);
                }}
                dataTestId={'customer-price'}
              />
            </Grid>
            <Grid item xs={2.4}>
              <InputWithCopy
                fullWidth={true}
                label="Kundenummer"
                value={order.customer.sonwinCustomerNumber}
                onChange={(e: string) => {
                  setOrder({
                    ...order,
                    customer: { ...order.customer, sonwinCustomerNumber: e }
                  });
                }}
                validation={{
                  type: InputType.Numbers,
                  validationErrorMessage: 'Kundenummer skal bestå af tal eller være tomt',
                  validationStatus: (valid: boolean) => {
                    setIsValidSonwinCustomerNumber(valid);
                  }
                }}
                allowEmpty={true}
                dataTestId={'customer-number'}
              />
            </Grid>
            <Grid item xs={2.4}>
              <InputWithCopy
                fullWidth={true}
                label="El-installationsnummer"
                value={order.installationNumber}
                onChange={(e: string) => {
                  setOrder({ ...order, installationNumber: e });
                }}
                validation={{
                  type: InputType.Regex,
                  regex: '^[0-9]{1,9}$',
                  validationErrorMessage: 'Minimum 1 ciffer, max 9 cifre',
                  validationStatus: (valid: boolean) => {
                    setIsValidInstallationNumber(valid);
                  }
                }}
                dataTestId={'customer-el-number'}
                allowEmpty={true}
              />
            </Grid>
            <Grid item xs={2.4}>
              <Typography fontSize={'14px'} fontWeight={'bold'} marginLeft={'12px'}>
                Koncernsamtykke
              </Typography>
              <Checkbox
                checked={order.customer.groupConsent}
                onChange={handleGroupConsentChecked}
              ></Checkbox>
            </Grid>
          </Grid>

          <Divider sx={{ marginTop: '56px' }}>
            <Typography fontSize={'18px'} fontWeight={'bold'}>
              Information
            </Typography>
          </Divider>

          <Grid container mt={3}>
            <Grid container spacing={4} item xs={9.6}>
              <Grid item xs={3}>
                <SelectWithCopy
                  showAsterisk={true}
                  fullWidth={true}
                  label="Norlys Energikunde"
                  values={Object.keys(EnergyCusomerStatusEnum).filter((v) => isNaN(Number(v)))}
                  value={order.energyCustomer}
                  error={!isValidEnergyCustomer}
                  onChange={(e: SelectChangeEvent) => {
                    const newEnum: EnergyCusomerStatusEnum =
                      EnergyCusomerStatusEnum[
                        e.target.value as keyof typeof EnergyCusomerStatusEnum
                      ];
                    setOrder({ ...order, energyCustomer: newEnum });
                    setIsValidEnergyCustomer(true);
                  }}
                  dataTestId={'customer-norlys-energy'}
                />
              </Grid>

              <Grid item xs={3}>
                <SelectWithCopy
                  showAsterisk={true}
                  fullWidth={true}
                  label="Kanal"
                  values={config?.salesChannels || []}
                  value={config && order.channel ? config.salesChannels.indexOf(order.channel) : -1}
                  error={!isValidChannel}
                  onChange={(e: SelectChangeEvent) => {
                    setOrder({
                      ...order,
                      channel: e.target.value,
                      partner: ''
                    });
                    setIsValidChannel(true);
                  }}
                  dataTestId={'sales-channel'}
                />
              </Grid>

              <Grid item xs={3}>
                <SelectWithCopy
                  disabled={!outboundAndPartnerValues.includes(order.channel!)}
                  showAsterisk={
                    order.channel ? outboundAndPartnerValues.includes(order.channel) : false
                  }
                  fullWidth={true}
                  label={getOutboundTitle()}
                  values={getOutboundOrChannelValues()}
                  value={order.partner ? getOutboundOrChannelValues().indexOf(order.partner) : -1}
                  error={!isValidChannel}
                  onChange={(e: SelectChangeEvent) => {
                    setOrder({
                      ...order,
                      partner: e.target.value
                    });
                  }}
                  dataTestId={'outbound-partner-values'}
                />
              </Grid>

              <Grid item xs={3}>
                <SelectWithCopy
                  showAsterisk={true}
                  fullWidth={true}
                  label="Kontrakttype"
                  values={Object.keys(OwnerTypeEnum).filter((v) => isNaN(Number(v)))}
                  value={order.ownerType}
                  error={!isValidOwnerType}
                  onChange={(e: SelectChangeEvent) => {
                    if (e.target.value === 'Firmabil' && order.referenceId?.length === 0) {
                      setIsValidReferenceId(false);
                    }

                    setIsValidOwnerType(true);

                    const newEnum: OwnerTypeEnum =
                      OwnerTypeEnum[e.target.value as keyof typeof OwnerTypeEnum];
                    setOrder({ ...order, ownerType: newEnum });
                  }}
                  dataTestId={'contract-type'}
                />
              </Grid>

              <Grid item xs={3}>
                <DateWithCopy
                  fullWidth={true}
                  label="Kontaktforsøg"
                  value={order.contactAttempt}
                  onChange={(e: Date | null) => {
                    validateAndSetDate(e, setIsValidContactAttempt, 'contactAttempt');
                  }}
                  maxDate={new Date()}
                  error={!isValidContactAttempt}
                  dataTestId={'contact-attempt-date'}
                />
              </Grid>

              <Grid item xs={3}>
                <DateWithCopy
                  showAsterisk={true}
                  fullWidth={true}
                  label="Ønsket installationsmåned"
                  value={order.wishedInstallationMonth}
                  onlyMonth={true}
                  minDate={new Date(Date.now())}
                  maxDate={new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 3)}
                  onChange={(e: Date | null) => {
                    validateAndSetDate(e, setIsValidInstallationMonth, 'installationMonth');
                  }}
                  error={!isValidInstallationMonth}
                  dataTestId={'wished-installation-month'}
                />
              </Grid>

              <Grid item xs={3}>
                <InputWithCopy
                  fullWidth={true}
                  label="Reference-ID"
                  value={order.referenceId}
                  validation={{
                    type: InputType.Regex,
                    regex:
                      order.ownerType === OwnerTypeEnum.Firmabil_Leje ||
                      order.ownerType === OwnerTypeEnum.Firmabil_Eje
                        ? '^[a-zA-Z0-9]{1,30}'
                        : '^[a-zA-Z0-9]{0,30}$|^$',
                    validationErrorMessage:
                      order.ownerType === OwnerTypeEnum.Firmabil_Leje ||
                      order.ownerType === OwnerTypeEnum.Firmabil_Eje
                        ? '1 - 30 karakterer (A-Z og 0-9)'
                        : 'Max 30 karakterer (A-Z og 0-9)',
                    validationStatus: (valid: boolean) => {
                      setIsValidReferenceId(valid);
                    }
                  }}
                  onChange={(newReferenceId: string) => {
                    setOrder({
                      ...order,
                      referenceId: newReferenceId
                    });
                  }}
                  validateBeforeTouch={true}
                  dataTestId={'reference-id'}
                  showValidationError={!isValidReferenceId}
                />
              </Grid>

              <Grid item xs={3}>
                <div style={{ display: 'flex' }}>
                  <Checkbox
                    sx={{ minWidth: '50px' }}
                    checked={order.separateDeliveryAddress}
                    onChange={() => {
                      setOrder({
                        ...order,
                        separateDeliveryAddress: !order.separateDeliveryAddress,
                        deliveryAddress: '',
                        deliveryAddressId: '00000000-0000-0000-0000-000000000000'
                      });
                    }}
                    data-testid="alternative-delivery-address-checkbox"
                  />

                  <DawaSuggestionFeature
                    fullWidth={true}
                    label="Alternativ lev. adresse"
                    disabled={!order.separateDeliveryAddress}
                    value={order.deliveryAddress}
                    suggestionPicked={(item) => {
                      setIsValidAlternativeAddress(true);
                      setOrder({
                        ...order,
                        deliveryAddress: item.tekst,
                        deliveryAddressId: item.adresse.id
                      });
                    }}
                    validation={
                      order.separateDeliveryAddress
                        ? {
                            type: InputType.Text,
                            validationErrorMessage: 'Adresse skal være udfyldt',
                            validationStatus: (valid: boolean) => {
                              setIsValidAlternativeAddress(valid);
                            }
                          }
                        : undefined
                    }
                    showValidationError={
                      !isValidAlternativeAddress && order.separateDeliveryAddress
                    }
                    dataTestId={'alternative-delivery-address-select'}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid item xs={2.4} pl={4}>
              <Typography fontSize={'14px'} fontWeight={'bold'}>
                Optioner
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={order.removalOfChargingStand}
                      onClick={() => {
                        setOrder({
                          ...order,
                          removalOfChargingStand: !order.removalOfChargingStand
                        });
                      }}
                    />
                  }
                  label={'Udskiftning'}
                  data-testid={'removal-of-charging-stand'}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={order.dealerFunded}
                      onClick={() => {
                        setOrder({ ...order, dealerFunded: !order.dealerFunded });
                      }}
                    />
                  }
                  label={'Finansieret'}
                  data-testid={'dealer-funded'}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={order.rightOfCancellationWaived}
                      onClick={() => {
                        setOrder({
                          ...order,
                          rightOfCancellationWaived: !order.rightOfCancellationWaived
                        });
                      }}
                    />
                  }
                  label={'Fast track'}
                  data-testid={'fast-track'}
                />
              </FormGroup>
            </Grid>
          </Grid>

          <Accordion
            hidden={!outboundAndPartnerValues.includes(order.channel!)}
            expanded={salesInformationExpanded}
            onChange={() => {
              setSalesInformationExpanded(!salesInformationExpanded);
            }}
            sx={{ backgroundColor: 'whitesmoke', marginTop: '48px' }}
            data-testid={'seller-informations'}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontSize={'18px'} fontWeight={'bold'}>
                Sælgerinformationer
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <InputWithCopy
                    label="Butiksnavn"
                    value={order.shopDetails?.shopName ? order.shopDetails.shopName : ''}
                    onChange={(e: string) => {
                      setOrder({
                        ...order,
                        shopDetails: { ...order.shopDetails, shopName: e }
                      });
                    }}
                    dataTestId={'shop-name'}
                  />
                </Grid>

                <Grid item xs={2}>
                  <InputWithCopy
                    label="Sælgernavn"
                    value={order.shopDetails?.sellerName ? order.shopDetails.sellerName : ''}
                    onChange={(e: string) => {
                      setOrder({
                        ...order,
                        shopDetails: { ...order.shopDetails, sellerName: e }
                      });
                    }}
                    dataTestId={'seller-name'}
                  />
                </Grid>

                <Grid item xs={2}>
                  <InputWithCopy
                    label="Sælger email"
                    value={order.shopDetails?.sellerEmail ? order.shopDetails.sellerEmail : ''}
                    onChange={(e: string) => {
                      setOrder({
                        ...order,
                        shopDetails: { ...order.shopDetails, sellerEmail: e }
                      });
                    }}
                    dataTestId={'seller-email'}
                  />
                </Grid>

                <Grid item xs={2}>
                  <InputWithCopy
                    label="Sælger ID"
                    value={order.shopDetails?.sellerId ? order.shopDetails.sellerId : ''}
                    onChange={(e: string) => {
                      setOrder({
                        ...order,
                        shopDetails: { ...order.shopDetails, sellerId: e }
                      });
                    }}
                    dataTestId={'seller-id'}
                  />
                </Grid>

                <Grid item xs={2}>
                  <InputWithCopy
                    label="Kampagne"
                    value={order.shopDetails?.campaign ? order.shopDetails.campaign : ''}
                    onChange={(e: string) => {
                      setOrder({
                        ...order,
                        shopDetails: { ...order.shopDetails, campaign: e }
                      });
                    }}
                    dataTestId={'campaign'}
                  />
                </Grid>
                <Grid item xs={2}>
                  <InputWithCopy
                    label="Butiksnummer"
                    value={order.shopDetails?.shopId ? order.shopDetails.shopId : ''}
                    onChange={(e: string) => {
                      setOrder({
                        ...order,
                        shopDetails: { ...order.shopDetails, shopId: e }
                      });
                    }}
                    dataTestId={'shop-id'}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={commentsExpanded}
            onChange={() => {
              setCommentsExpanded(!commentsExpanded);
            }}
            sx={{ backgroundColor: 'whitesmoke', marginTop: '16px' }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography fontSize={'18px'} fontWeight={'bold'}>
                Kommentarer
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  <Box>
                    {order.comments.map((comment) => (
                      <Comment
                        author={comment.author}
                        comment={comment.content}
                        created={comment.created}
                      />
                    ))}
                  </Box>
                  <Box>
                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
                      <TextareaAutosize
                        placeholder="Skriv en kommentar..."
                        style={{ width: 600 }}
                        minRows={3}
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        data-testid={'comment-text-area'}
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Button
                          size="small"
                          disabled={newComment.length === 0}
                          style={{ placeSelf: 'flex-end', marginTop: '10px' }}
                          variant={'outlined'}
                          onClick={() => saveComment()}
                          data-testid={'comment-add'}
                        >
                          Tilføj
                        </Button>
                      </div>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box mt={'20px'}>
          <Button
            disabled={!allValid}
            variant="contained"
            onClick={saveOrder}
            data-testid={'save-button'}
          >
            Gem ordre
          </Button>
          <Button
            variant="contained"
            onClick={close}
            sx={{ marginLeft: '10px' }}
            color="error"
            data-testid={'cancel-button'}
          >
            Fortryd
          </Button>
        </Box>
      </CreateOrderWrapper>
    </Modal>
  );
};

const CreateOrderWrapper = styled(Box)`
  position: absolute;
  top: 1vh;
  left: 2vw;
  width: 96vw;
  min-height: 80vh;
  box-shadow: 24;
  padding: 24px;
  border-radius: 4px;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 95vh;
`;
