import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FIRMABIL, OUTBOUND, PARTNERSKAB, TCC } from '../../../../assets/PreselectedValues';
import { IConfiguration } from '../../../../util/Models/ConfigurationModel';
import { IOrderTableData } from '../../../../util/Models/OrderTableDataModel';
import {
  getCustomerNumberField,
  getEmailAddressField,
  getInstallationAddressField,
  getInstallationNumberField,
  getNameField,
  getPhoneNumberField,
  getSecondaryNameField
} from '../OrderInfoBoxFields/ContactInformationField';
import {
  getApplicationDateField,
  getWishedInstallationMonthField,
  getConfirmedInstallationDateField
} from '../OrderInfoBoxFields/DateInformationFields';
import {
  getChannelField,
  getContractTypeField,
  getDealerFundedField,
  getDeleveryAddressField,
  getEnergyCustomerField,
  getFastTrackField,
  getGroupConsentField,
  getPartnerField,
  getPriceField,
  getReferenceIdField,
  getRemovalOfChargingStandField
} from '../OrderInfoBoxFields/OrderInformationField';

import {
  getSellerEmailInformationField,
  getSellerIdField,
  getSellerInformationField,
  getShopNameField,
  getCampaignField,
  getShopIdField
} from '../OrderInfoBoxFields/SellerInformationField';

export interface OrderInfoTabProps {
  order: IOrderTableData;
  canEdit: boolean;
  setOrder: React.Dispatch<React.SetStateAction<IOrderTableData>>;
  setIsValid: (isValid: boolean) => void;
  configuration: IConfiguration;
  secondaryTextColor: string;
  userName: string;
}

export interface FieldSettings {
  order: IOrderTableData;
  canEdit: boolean;
  setOrder: React.Dispatch<React.SetStateAction<IOrderTableData>>;
  configuration: IConfiguration;
  textColor: string;
}

export const CustomerTab: React.FC<OrderInfoTabProps> = (props) => {
  const { order, canEdit, configuration } = props;
  const [isValidName, setIsValidName] = useState(true);
  const [isValidSecondaryName, setIsValidSecondaryName] = useState(true);
  const [isValidInstallationNumber, setIsValidInstallationNumber] = useState(true);
  const [isValidInstallationAddress, setIsValidInstallationAddress] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [isValidPrice, setIsValidPrice] = useState(true);
  const [isValidEnergyCustomer, setIsValidEnergyCustomer] = useState(order.energyCustomer >= 0);
  const [isValidChannel, setIsValidChannel] = useState(order.channel != null);
  const [isValidPartner, setIsValidPartner] = useState(true);
  const [isValidOwnerType, setIsValidOwnerType] = useState(order.ownerType >= 0);
  const [isValidDeliveryAddress, setIsValidDeliveryAddress] = useState(true);
  const [isValidReferenceId, setIsValidReferenceId] = useState(true);
  const [isValidApplicationDate, setIsValidApplicationDate] = useState(true);
  const [isValidInstallationMonth, setIsValidInstallationMonth] = useState(
    order.wishedInstallationMonth != null
  );
  const [isValidConfirmedInstallationDate, setIsValidConfirmedInstallationDate] = useState(true);
  const [isValidCustomerNumber, setIsValidCustomerNumber] = useState(true);
  const [channelValues, setChannelValues] = useState(configuration.salesChannels);
  const greyText = '#858383';

  const fieldSettings: FieldSettings = {
    order: order,
    canEdit: canEdit,
    setOrder: props.setOrder,
    configuration: configuration,
    textColor: greyText
  };

  useEffect(() => {
    props.setIsValid(
      isValidName &&
        isValidSecondaryName &&
        isValidInstallationAddress &&
        isValidEmail &&
        isValidPhoneNumber &&
        isValidPrice &&
        isValidEnergyCustomer &&
        isValidChannel &&
        isValidPartner &&
        isValidDeliveryAddress &&
        isValidReferenceId &&
        isValidApplicationDate &&
        isValidInstallationMonth &&
        isValidConfirmedInstallationDate &&
        isValidInstallationNumber &&
        isValidCustomerNumber
    );
  }, [
    isValidName,
    isValidSecondaryName,
    isValidInstallationAddress,
    isValidEmail,
    isValidPhoneNumber,
    isValidPrice,
    isValidEnergyCustomer,
    isValidChannel,
    isValidPartner,
    isValidOwnerType,
    isValidDeliveryAddress,
    isValidReferenceId,
    isValidApplicationDate,
    isValidInstallationMonth,
    isValidConfirmedInstallationDate,
    isValidInstallationNumber,
    isValidCustomerNumber
  ]);

  useEffect(() => {
    setChannelValues(setChannelArray(order.channel));
    setIsValidChannel(order.channel != null);
    setIsValidPartner(getIsValidPartner());
  }, [
    order.channel,
    configuration.salesChannels,
    order.partner,
    configuration.partners,
    configuration.outbounds,
    configuration.companyCars
  ]);

  const setChannelArray = (channel: string | null): Array<string> => {
    const tempChannel = [...configuration.salesChannels];
    if (channel != null && channel != '' && !tempChannel.includes(channel)) {
      tempChannel.push(channel);
    }
    return tempChannel;
  };

  function getIsValidPartner() {
    const channelShouldHavePartner =
      order.channel == PARTNERSKAB ||
      order.channel == OUTBOUND ||
      order.channel == TCC ||
      order.channel == FIRMABIL;
    if (!channelShouldHavePartner) return true;

    if (order.partner == null) return false;

    return order.partner.length > 0;
  }

  function showSellerInformation() {
    if (order.channel === null) {
      return false;
    }

    if (['Partnerskab', 'Outbound', 'TCC', 'Firmabil'].includes(order.channel)) {
      return true;
    }

    return false;
  }

  return (
    <>
      <Grid container direction={'row'} spacing={1} paddingLeft={0.5}>
        <Grid item xs={6}>
          {getNameField(isValidName, setIsValidName, fieldSettings)}
        </Grid>
        <Grid item xs={6}>
          {getSecondaryNameField(isValidSecondaryName, setIsValidSecondaryName, fieldSettings)}
        </Grid>
        <Grid item xs={6}>
          {getPhoneNumberField(setIsValidPhoneNumber, fieldSettings)}
        </Grid>
        <Grid item xs={6}>
          {getEmailAddressField(setIsValidEmail, fieldSettings)}
        </Grid>
        <Grid item xs={6}>
          {getInstallationAddressField(
            isValidInstallationAddress,
            setIsValidInstallationAddress,
            fieldSettings
          )}
        </Grid>
        <Grid item xs={6}>
          {getCustomerNumberField(setIsValidCustomerNumber, fieldSettings)}
        </Grid>
        <Grid item xs={6}>
          {getInstallationNumberField(setIsValidInstallationNumber, fieldSettings)}
        </Grid>
      </Grid>
      {/* ..................... */}
      <Grid container direction={'row'} spacing={2} sx={GridStyle}>
        <Grid item xs={6}>
          {getPriceField(isValidPrice, setIsValidPrice, fieldSettings)}
        </Grid>
        <Grid item xs={6}>
          {getEnergyCustomerField(isValidEnergyCustomer, setIsValidEnergyCustomer, fieldSettings)}
        </Grid>
        <Grid item xs={6}>
          {getChannelField(isValidChannel, setIsValidChannel, fieldSettings, channelValues)}
        </Grid>
        <Grid item xs={6}>
          {getPartnerField(isValidPartner, fieldSettings)}
        </Grid>
        <Grid item xs={6}>
          {getContractTypeField(isValidOwnerType, setIsValidOwnerType, fieldSettings)}
        </Grid>
        <Grid item xs={6}>
          {getDeleveryAddressField(
            isValidDeliveryAddress,
            setIsValidDeliveryAddress,
            fieldSettings
          )}
        </Grid>
        <Grid item xs={6}>
          {getReferenceIdField(setIsValidReferenceId, fieldSettings)}
        </Grid>
        <Grid item xs={6}>
          {getRemovalOfChargingStandField(fieldSettings)}
        </Grid>
        <Grid item xs={6}>
          {getDealerFundedField(fieldSettings)}
        </Grid>
        <Grid item xs={6}>
          {getFastTrackField(fieldSettings)}
        </Grid>
        <Grid item xs={6}>
          {getGroupConsentField(fieldSettings)}
        </Grid>
      </Grid>
      {/* ..................... */}
      <Grid container direction={'row'} spacing={2} sx={GridStyle}>
        <Box sx={BoxRowContainerStyle}>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontWeight={'bold'}>Bestillingsdato</Typography>
          </Grid>
          <Grid item xs={6} sx={{ marginLeft: '11px' }}>
            {getApplicationDateField(setIsValidApplicationDate, fieldSettings)}
          </Grid>
        </Box>
        <Box sx={BoxRowContainerStyle}>
          <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography fontWeight={'bold'}>Ønsket installationsmåned</Typography>
          </Grid>
          <Grid item xs={6} sx={{ marginLeft: '11px' }}>
            {getWishedInstallationMonthField(
              isValidInstallationMonth,
              setIsValidInstallationMonth,
              fieldSettings
            )}
          </Grid>
        </Box>
        <Box sx={BoxRowContainerStyle}>
          <Grid item xs={6}>
            <Typography fontWeight={'bold'} sx={{ display: 'flex', alignItems: 'center' }}>
              Installationsdato bekræftet
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ marginLeft: '11px' }}>
            {getConfirmedInstallationDateField(setIsValidConfirmedInstallationDate, fieldSettings)}
          </Grid>
        </Box>
      </Grid>
      {/* ..................... */}
      {showSellerInformation() && (
        <Grid container direction={'row'} spacing={2} sx={GridStyle}>
          <Grid item xs={6}>
            {getSellerInformationField(fieldSettings)}
          </Grid>
          <Grid item xs={6}>
            {getSellerEmailInformationField(fieldSettings)}
          </Grid>
          <Grid item xs={6}>
            {getShopNameField(fieldSettings)}
          </Grid>
          <Grid item xs={6}>
            {getSellerIdField(fieldSettings)}
          </Grid>
          <Grid item xs={6}>
            {getCampaignField(fieldSettings)}
          </Grid>
          <Grid item xs={6}>
            {getShopIdField(fieldSettings)}
          </Grid>
        </Grid>
      )}
    </>
  );
};

const GridStyle = {
  borderTop: 1,
  borderColor: 'lightgrey',
  marginTop: '0px',
  padding: '10px 0 15px 4px'
};

const BoxRowContainerStyle = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '10px 0 10px 15px',
  justifyContent: 'space-between'
};
