import React from 'react';
import { IComment } from '../../../util/Models/CommentModel';
import { Box, Popover, Typography } from '@mui/material';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import ThreePOutlinedIcon from '@mui/icons-material/ThreePOutlined';
import MultiLine from '../../../ui/MultiLine/MultiLine';

interface Props {
  comments: IComment[];
}

const OrderListCommentPopover: React.FC<Props> = (props) => {
  const { comments } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {comments.length > 0 ? (
        <Box
          component="div"
          sx={{ display: 'inline' }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <CommentOutlinedIcon
            aria-owns={open ? 'mouse-over-popover' : undefined}
            sx={{ marginLeft: '.25em', color: 'gray' }}
          />

          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none'
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Box sx={{ maxWidth: '48em', backgroundColor: '#1976d2' }} pb={0.5}>
              <Typography px={2} pt={1} align="right" sx={{ color: '#fafafa' }}>
                Antal kommentarer: {comments.length}
              </Typography>
              <Box sx={{ backgroundColor: 'white' }}>
                {comments
                  .sort((a, b) => +a.created - +b.created)
                  .reverse()
                  .map((comment) => (
                    <Box key={comment.id}>
                      <hr />
                      <Typography
                        pt={0.5}
                        pb={1}
                        sx={{
                          color: 'darkslategray',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Box component="span" px={2} sx={{ display: 'inline' }}>
                          <ThreePOutlinedIcon
                            sx={{ marginRight: '.5em', marginBottom: '-.35em', color: 'gray' }}
                          />
                          {comment.author}
                        </Box>
                        <Box component="span" px={2} sx={{ display: 'inline' }}>
                          {comment.created.toLocaleString()}
                        </Box>
                      </Typography>
                      <Typography pl={4} pr={2} pb={0.5}>
                        <MultiLine text={comment.content} />
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </Box>
          </Popover>
        </Box>
      ) : (
        <CommentOutlinedIcon sx={{ color: 'ghostwhite', marginLeft: '.25em' }} />
      )}
    </>
  );
};

export default OrderListCommentPopover;
