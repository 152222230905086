import { Modal, Box, Typography, Input, Button } from '@mui/material';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import LoadingService from '../../data-access/LoadingService';
import MessageService, { SnackbarSeverity } from '../../data-access/MessageService';
import OrderService from '../../data-access/OrderService';
import WarningIcon from '../../ui/WarningIcon';
import { IOrderTableData } from '../../util/Models/OrderTableDataModel';
import CloseIcon from '@mui/icons-material/Close';

interface IConfirmDeleteModalProps {
  callback: (item: IOrderTableData) => void;
}

const ConfirmDeleteModal = (props: IConfirmDeleteModalProps) => {
  const [confirmDelete, setConfirmDelete] = useState<IOrderTableData | null>(null);
  const [confirmText, setConfirmText] = useState<string>('');

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '4px'
  };

  useEffect(() => {
    window.addEventListener('confirmDelete', (e) => {
      let event = (e as CustomEvent).detail as IOrderTableData;
      setConfirmDelete(event);
    });
  }, []);

  const handleDelete = async () => {
    try {
      if (confirmDelete) {
        let result = await OrderService.deleteOrder(confirmDelete.id);

        if (result.length > 0) {
          MessageService.showSnackbar({
            message: 'Ordre slettet med succes',
            severity: SnackbarSeverity.SUCCESS
          });
          props.callback(confirmDelete);
          setConfirmText('');

          let event = new CustomEvent('orderDeleted');
          window.dispatchEvent(event);
        } else {
          MessageService.showSnackbar({
            message: 'Det lykkedes ikke at slette ordren',
            severity: SnackbarSeverity.ERROR
          });
        }

        setConfirmDelete(null);
      }
    } catch (exception) {
      setConfirmDelete(null);
      LoadingService.showLoader('');
      MessageService.showDialog({
        title: 'Fejl under sletning',
        message: 'Der opstod en fejl under sletningen. Prøv igen om lidt.'
      });
    }
  };

  return (
    <Modal
      open={confirmDelete != null}
      onClose={() => setConfirmDelete(null)}
      aria-labelledby="slet-ordre"
      aria-describedby="slet-ordre"
    >
      <Box sx={modalStyle}>
        <CloseIconWrapper
          onClick={() => {
            setConfirmDelete(null);
          }}
        >
          <CloseIcon />
        </CloseIconWrapper>

        <Typography variant="h6" component="h2" textAlign={'center'}>
          <WarningIcon title="Bekræft sletning af ordre"></WarningIcon>
          <span style={{ marginLeft: '5px' }}>Slet ordre?</span>
        </Typography>
        <Typography fontSize={14} sx={{ mt: 2 }}>
          <ul style={{ paddingInlineStart: '10px' }}>
            <li>Hvis der kun er 1 ordre tilknyttet kunden, slettes kunden også</li>
            <li style={{ marginTop: '5px' }}>Alle tilknyttede kommentarer slettes</li>
          </ul>
        </Typography>
        <Typography fontSize={14} marginTop={'20px'}>
          Bekræft sletning ved at skrive SLET ORDRE med store bogstaver i tekstfeltet.
        </Typography>
        <Input
          onChange={(e) => {
            setConfirmText(e.target.value);
          }}
          onPaste={(e) => {
            e.preventDefault();
          }}
          value={confirmText}
          fullWidth={true}
          data-testid="confirm-delete"
        ></Input>
        <div style={{ marginTop: '20px' }}>
          <Button
            variant="contained"
            disabled={confirmText !== 'SLET ORDRE'}
            onClick={handleDelete}
            data-testid="confirm-delete-button"
          >
            SLET ORDRE
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

export default ConfirmDeleteModal;
