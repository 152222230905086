import { EnergyCusomerStatusEnum } from '../Enums/EnergyCustomerStatusEnum';
import { OrderStatusEnum } from '../Enums/OrderStatusEnum';
import { OwnerTypeEnum } from '../Enums/OwnerTypeEnum';
import { IReport } from '../Models/ReportModel';
import { PriceService } from '../Services/PriceService';

const generateCsvHeader = () => {
  const header =
    'Id;' +
    'Modified by;' +
    'Price;' +
    'Channel;' +
    'Partner;' +
    'Energy customer;' +
    'Order status;' +
    'Owner type;' +
    'Postal code;' +
    'Application date;' +
    'Contract date;' +
    'Installation date;' +
    'Wished installation month;' +
    'Financed;' +
    'Fast track;' +
    'Charger replacement;' +
    'Shop;' +
    'Seller;' +
    'Sellers email;' +
    'Campaign code;' +
    'Consumer number;' +
    'Installation number;' +
    'Kundenummer;' +
    'Extra installation cost;' +
    'EVB-nummer;' +
    // 'Abonnement;' +  // Findes ikke i BFF 30/03-2023
    // 'Abonnementsrabat;' + -||-
    'Abonnement;' +
    'Abonnement Firma;' +
    'Installationskontrakt;' +
    'Installationskontrakt Firma;' +
    'Antal udtag;' +
    'Tlf.;' +
    'Email;' +
    'TopDanmark Lead;' +
    'InstallationAddressId;' +
    'DeliveryAddressId;';

  return header;
};

const generateCsvLine = (report: IReport) => {
  let applicationDateString = report.applicationDate?.toString().substring(0, 10);
  let contractDateString = report.contractDate?.toString().substring(0, 10);
  let installationDateString = report.installationDate?.toString().substring(0, 10);
  let wishedInstallationMonth = report.wishedInstallationMonth?.toString().substring(0, 10);

  let line: string =
    '\n' +
    `${report.id};` +
    `${report.modifiedBy};` +
    `${PriceService.pointToDecimalSeparator(report.price)};` +
    `${report.channel};` +
    `${report.partner};` +
    `${EnergyCusomerStatusEnum[report.energyCustomer]};` +
    `${OrderStatusEnum[report.status]};` +
    `${OwnerTypeEnum[report.ownerType]};` +
    `${report.postalCode};` +
    `${applicationDateString};` +
    `${contractDateString};` +
    `${installationDateString};` +
    `${wishedInstallationMonth};` +
    `${report.dealerFunded};` +
    `${report.rightOfCancellationWaived};` +
    `${report.removalOfChargingStand};`;
  if (report.shopDetails != null) {
    line += `${report.shopDetails.shopName};${report.shopDetails.sellerName};${report.shopDetails.sellerEmail};${report.shopDetails.campaign};`;
  } else {
    line += `${null};${null};${null};${null};`;
  }
  line +=
    `${report.consumerNumber};` +
    `${report.installationNumber};` +
    `${report.customerNumber};` +
    `${PriceService.pointToDecimalSeparator(report.extraInstallationCost)};` +
    `${report.evbNumber};` +
    //` ${null}; ` + // Abonnement
    //` ${null}; ` + // Abonnementsrabat
    `${report.subscriptionContractId};` +
    `${report.subscriptionContractCompanyId};` +
    `${report.installationContractId};` +
    `${report.installationContractCompanyId};` +
    `${report.numberOfConnectors};` +
    `${report.phone};` +
    `${report.email};` +
    `${report.topDKLead};` +
    `${report.installationAddressId};`;
  if (report.deliveryAddressId) {
    line += `${report.deliveryAddressId};`;
  }
  else {
    line += `${null};`;
  }

  return line;
};

const generateFilename = (from: string, to: string) => {
  return `report_from-${from}_to-${to}`;
};

const generateDownloadLink = (csv: string, from: string, to: string) => {
  const downloadElement = document.createElement('a');

  if (downloadElement) {
    let bom = new Uint8Array([0xef, 0xbb, 0xbf]); // Ensures that Excel can read æøå
    const file = new Blob([bom, csv], {
      type: 'data:text/csv;charset=utf-8'
    });

    downloadElement.href = URL.createObjectURL(file);
    downloadElement.download = `${generateFilename(from, to)}.csv`;
    document.body.appendChild(downloadElement);
    downloadElement.click();
  }
};

export const generateCsvFile = (data: IReport[], from: string, to: string) => {
  let csv = generateCsvHeader();

  data.forEach((item) => {
    csv += generateCsvLine(item);
  });

  generateDownloadLink(csv, from, to);
};
