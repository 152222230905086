import { useEffect } from 'react';

export const useKeyDown = (callback: any, keys: any[]) => {
  const onKeyDown = (event: KeyboardEvent) => {
    const wasAnyKeyPressed = keys.some((key: string) => event.key === key);
    if (wasAnyKeyPressed) {
      event.preventDefault();
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);
};

export const useSaveKeysDown = (callback: any, keys: any[]) => {
  const onKeysDown = (event: KeyboardEvent) => {
    const sPressed = keys.some((key: string) => event.key === key);
    if (event.ctrlKey && sPressed) {
      event.preventDefault();
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener('keydown', onKeysDown);
    return () => {
      document.removeEventListener('keydown', onKeysDown);
    };
  }, [onKeysDown]);
};
