const toTwoDigits = (number: number) => {
  return number.toString().padStart(2, '0');
};

const formatTime = (date: Date | undefined | null) => {
  if (date instanceof Date) {
    return [toTwoDigits(date.getHours()), toTwoDigits(date.getMinutes())].join('.');
  }

  return null;
};

export const formatDate = (date: Date | undefined | null | string) => {
  if (date instanceof Date) {
    return [toTwoDigits(date.getDate()), toTwoDigits(date.getMonth() + 1), date.getFullYear()].join(
      '-'
    );
  }

  return null;
};

export const formatDateTime = (date: Date | undefined | null) => {
  return `${formatDate(date)} ${formatTime(date)}`;
};
