import { Box, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography } from '@mui/material';
import DateWithCopy from '../../../../ui/InputWithCopy/DateWithCopy';
import { formatDate } from '../../../../util/Formatters/FormatDate';
import { ICompanyInformation, IContractInformation } from '../../../../util/Models/ContractModel';
import { FieldSettings } from '../OrderInfoBoxTabs/CustomerTab';

export function getCompanyField(
  fieldSettings: FieldSettings,
  companies: Array<ICompanyInformation>,
  handleSelectCompany: (newValue: string) => void,
  selectedValue: string,
  title: string,
  disabled: boolean
) {
  const { canEdit, textColor } = fieldSettings;

  return (
    <Box sx={{ marginBottom: '15px' }}>
      <Typography fontWeight={'bold'}>{title}</Typography>
      {canEdit ? (
        <Select
          sx={{ maxHeight: '2.5rem', marginTop: '0.1rem' }}
          fullWidth={true}
          value={selectedValue}
          onChange={(e: SelectChangeEvent) => handleSelectCompany(e.target.value)}
          disabled={disabled}
          input={<OutlinedInput style={{ textAlign: 'left', width: '100%' }} />}
          data-testid="company-dropdown"
        >
          {companies.map((company, index) => (
            <MenuItem key={index} value={company.name}>
              {company.name}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Typography color={textColor} data-testid={title}>
          {selectedValue == '' ? '-' : selectedValue}
        </Typography>
      )}
    </Box>
  );
}

interface IContractFieldProps {
  fieldSettings: FieldSettings;
  filteredContractArray: Array<IContractInformation>;
  handleSelectContract: (newValue: string) => void;
  selectedValue: IContractInformation | null;
  title: string;
  disabled: boolean;
}

export const ContractField: React.FC<IContractFieldProps> = (props) => {
  const { canEdit, textColor } = props.fieldSettings;

  function getContractName(contract: IContractInformation | null): string {
    return contract ? contract.contractName : '-';
  }

  return (
    <Box sx={{ marginBottom: '15px' }}>
      <Typography fontWeight={'bold'}>{props.title}</Typography>
      {canEdit ? (
        <Select
          sx={{ maxHeight: '2.5rem', marginTop: '0.1rem' }}
          fullWidth={true}
          value={props.selectedValue?.contractID ?? ''}
          onChange={(e: SelectChangeEvent) => props.handleSelectContract(e.target.value)}
          disabled={props.disabled}
          input={<OutlinedInput style={{ textAlign: 'left', width: '100%' }} />}
          data-testid={props.title + '-dropdown'}
        >
          {props.filteredContractArray.map((contract, index) => (
            <MenuItem key={index} value={contract.contractID}>
              {getContractName(contract)}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Typography color={textColor} data-testid={props.title}>
          {getContractName(props.selectedValue)}
        </Typography>
      )}
    </Box>
  );
};

export function getContractGroupField(
  fieldSettings: FieldSettings,
  contractGroupsArray: Array<IContractInformation>,
  handleSelectContractGroup: (newValue: string) => void,
  selectedContractGroup: string | undefined,
  contractData: Date | null
) {
  const { canEdit, textColor } = fieldSettings;

  return (
    <Box sx={{ marginBottom: '15px' }}>
      <Typography fontWeight={'bold'}>{'Kontraktgruppe'}</Typography>
      {canEdit ? (
        <Select
          sx={{ maxHeight: '2.5rem', marginTop: '0.1rem' }}
          fullWidth={true}
          value={selectedContractGroup}
          onChange={(e) => handleSelectContractGroup(e.target.value)}
          data-testid="contract-group-dropdown"
          disabled={contractData !== null}
        >
          {contractGroupsArray.map((contract, index) => (
            <MenuItem key={index} value={contract.contractGroup}>
              {contract.contractGroup + ': ' + contract.contractGroupText}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Typography color={textColor} data-testid={'contract-group-selector'}>
          {selectedContractGroup ? selectedContractGroup : '-'}
        </Typography>
      )}
    </Box>
  );
}

interface IContractDateField {
  fieldSettings: FieldSettings;
  isValidContractDate: boolean;
  handleSetContractDate: (newValue: Date | null) => void;
}

export const ContractDateField: React.FC<IContractDateField> = (props) => {
  const { canEdit, textColor, order } = props.fieldSettings;

  return (
    <Box sx={{ marginBottom: '15px' }}>
      <Typography fontWeight={'bold'}>Kontraktdato: </Typography>
      {canEdit ? (
        <DateWithCopy
          fullWidth={true}
          value={order.contractDate}
          onChange={(newDate: Date | null) => {
            props.handleSetContractDate(newDate);
          }}
          disabled
        />
      ) : (
        <Typography color={textColor}>{formatDate(order.contractDate)}</Typography>
      )}
    </Box>
  );
};
