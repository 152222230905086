import { createGlobalStyle } from 'styled-components';
import ContentContainer from './ContentContainer';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import { OrderProvider } from '../Libs/data-access/Context/OrderContext';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ErrorBoundary } from 'react-error-boundary';
import { Error } from './Error';
import { EditOrderContextProvider } from '../Libs/data-access/Context/EditOrderContext';

const GlobalStyles = createGlobalStyle`
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
  box-sizing: inherit;
  }
  body{
    margin: 0;
    overflow: hidden ;
  }
  tr {
    display: table-row;
  }
  .MuiTableCell-root {
    max-width: 100vw;
    max-height: inherit;
  }
`;

function ErrorComponent(error: any) {
  return <div>An Error Occurred: {error}</div>;
}

function LoadingComponent() {
  return <div>Authentication in progress...</div>;
}

function App() {
  return (
    <>
      <ErrorBoundary FallbackComponent={Error as any}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequest}
          errorComponent={ErrorComponent}
          loadingComponent={LoadingComponent}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <GlobalStyles />
            <div className="App">
              <EditOrderContextProvider>
                <OrderProvider>
                  <ContentContainer />
                </OrderProvider>
              </EditOrderContextProvider>
            </div>
          </LocalizationProvider>
        </MsalAuthenticationTemplate>
      </ErrorBoundary>
    </>
  );
}

export default App;
