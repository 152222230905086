import { Box, Typography } from '@mui/material';
import moment from 'moment';
import DateWithCopy from '../../../../ui/InputWithCopy/DateWithCopy';
import { OrderState } from '../../../../util/Enums/OrderState';
import { StepStatus } from '../../../../util/Enums/StepStatusEnum';
import { formatDate } from '../../../../util/Formatters/FormatDate';
import { FieldSettings } from '../OrderInfoBoxTabs/CustomerTab';

export function getApplicationDateField(
  setIsValidApplicationDate: React.Dispatch<React.SetStateAction<boolean>>,
  fieldSettings: FieldSettings
) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;

  function validateAndSetApplicationDate(e: Date | null) {
    if (!moment(e).isValid() && e != null) {
      setIsValidApplicationDate(false);
      return;
    }

    let now = new Date();

    if (
      e?.getFullYear() === now.getFullYear() &&
      e.getMonth() === now.getMonth() &&
      e.getDate() === now.getDate()
    ) {
      e.setHours(now.getHours());
      e.setMinutes(now.getMinutes());
      e.setSeconds(now.getSeconds());
      e.setMilliseconds(now.getMilliseconds());
    }

    if (!moment(e).isValid() && e != null) {
      setIsValidApplicationDate(false);
      return;
    }

    setOrder({ ...order, applicationDate: e, state: OrderState.DIRTY });
    setIsValidApplicationDate(true);
  }

  return (
    <>
      {canEdit ? (
        <DateWithCopy
          fullWidth={true}
          value={order.applicationDate}
          onChange={(e: Date | null) => {
            validateAndSetApplicationDate(e);
          }}
          maxDate={new Date()}
        />
      ) : (
        <Typography sx={{ paddingLeft: '9px' }} color={textColor}>
          {formatDate(order.applicationDate)}
        </Typography>
      )}
    </>
  );
}

export function getWishedInstallationMonthField(
  isValidInstallationMonth: boolean,
  setIsValidInstallationMonth: React.Dispatch<React.SetStateAction<boolean>>,
  fieldSettings: FieldSettings
) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;

  function validateAndSetDate(
    e: Date | null,
    setValid: React.Dispatch<React.SetStateAction<boolean>>,
    field: string
  ) {
    if (!moment(e).isValid() && e != null) {
      setValid(false);
      return;
    }

    switch (field) {
      case 'contactAttempt':
        setOrder({ ...order, contactAttempt: e });
        setValid(true);
        break;
      case 'installationMonth':
        setOrder({ ...order, wishedInstallationMonth: e });
        setValid(e != null);
        break;
    }
  }

  function translateToMonth(date: Date): string {
    const monthNames = [
      'januar',
      'februar',
      'marts',
      'april',
      'maj',
      'juni',
      'juli',
      'august',
      'september',
      'oktober',
      'november',
      'december'
    ];

    if (typeof date === 'string') {
      date = new Date(date);
    }

    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${month} ${year}`;
  }

  return (
    <Box data-testid={'wished-installation-month'}>
      {canEdit ? (
        <DateWithCopy
          fullWidth={true}
          value={order.wishedInstallationMonth}
          onlyMonth={true}
          minDate={new Date(Date.now())}
          maxDate={new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 3)}
          onChange={(e: Date | null) => {
            validateAndSetDate(e, setIsValidInstallationMonth, 'installationMonth');
          }}
          error={!isValidInstallationMonth}
          dataTestId={'wished-installation-month'}
        />
      ) : (
        <Typography sx={{ paddingLeft: '9px' }} color={textColor}>
          {order.wishedInstallationMonth ? translateToMonth(order.wishedInstallationMonth) : '-'}
        </Typography>
      )}
    </Box>
  );
}

export function getConfirmedInstallationDateField(
  setIsValidConfirmedInstallationDate: React.Dispatch<React.SetStateAction<boolean>>,
  fieldSettings: FieldSettings
) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;

  let step = order.steps.find((s) => s.name == 'confirmedInstallationDate');

  const handleChangeDate = (newValue: Date | null) => {
    if (!moment(newValue).isValid()) {
      setIsValidConfirmedInstallationDate(false);
      return;
    }

    let newSteps = order.steps.map((_step, _index) => {
      if (_step.name == 'confirmedInstallationDate') {
        setIsValidConfirmedInstallationDate(true);
        return {
          ..._step,
          value: newValue ? newValue.toISOString() : '',
          status: StepStatus.Completed
        };
      } else {
        return _step;
      }
    });

    setOrder({ ...order, steps: newSteps, state: OrderState.DIRTY });
  };

  return (
    <>
      {canEdit ? (
        <DateWithCopy
          fullWidth={true}
          value={step ? (step.value.length > 0 ? new Date(step.value) : null) : null}
          onChange={(newValue: Date | null) => {
            handleChangeDate(newValue);
          }}
        />
      ) : (
        <Typography sx={{ paddingLeft: '9px' }} color={textColor}>
          {formatDate(step && step.value.length > 0 ? new Date(step.value) : null) != null
            ? formatDate(step ? new Date(step.value) : null)
            : '-'}
        </Typography>
      )}
    </>
  );
}
