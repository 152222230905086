import { Box, Typography } from '@mui/material';

interface ICreateComment {
  comment: string;
  created: Date;
  author: string;
}

const Comment = ({ created, comment, author }: ICreateComment) => {
  let now = new Date();

  function parseTime() {
    if (
      created.getFullYear() == now.getFullYear() &&
      created.getMonth() === now.getMonth() &&
      created.getDate() === now.getDate()
    ) {
      return `i dag kl. ${created.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
    }
    return `${created.toLocaleDateString('da-DK', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    })} kl. ${created.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
  }

  return (
    <>
      <Box mt={'15px'}>
        <div style={{ display: 'flex' }}>
          <Typography fontWeight={'bold'} alignSelf={'center'} fontSize={'14px'}>
            {author}
          </Typography>
          <Typography color={'darkgray'} ml={'30px'} fontSize={'14px'} alignSelf={'center'}>
            {parseTime()}
          </Typography>
        </div>
        <div>
          <Typography fontSize={'14px'}>{comment}</Typography>
        </div>
      </Box>
    </>
  );
};

export default Comment;
