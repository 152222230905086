import { AlertColor } from '@mui/material';

export interface EMobilitySnackbar {
  message: string;
  severity: AlertColor | undefined;
  autoHideDuration?: number;
}

export interface EMobilityDialog {
  title: string;
  message: string;
}

export enum SnackbarSeverity {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success'
}

const MessageService = {
  showSnackbar: (props: EMobilitySnackbar) => {
    let event = new CustomEvent('EMobilitySnackbar', { detail: props });
    window.dispatchEvent(event);
  },
  showDialog: (props: EMobilityDialog) => {
    let event = new CustomEvent('EMobilityDialog', { detail: props });
    window.dispatchEvent(event);
  }
};

export default MessageService;
