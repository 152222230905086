import { Backdrop, CircularProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export const LoadingBackdrop = () => {
  const [loadingText, setLoadingText] = useState<string>('');

  const handleShowLoader = (e: Event) => {
    let event = (e as CustomEvent).detail;
    setLoadingText(event.toString());
  };

  useEffect(() => {
    window.addEventListener('showLoader', handleShowLoader);
    window.addEventListener('showLoader', handleShowLoader);
  }, []);

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loadingText.length > 0}
    >
      <CircularProgress color="inherit" />
      <Typography sx={{ marginLeft: '10px' }}>{loadingText}</Typography>
    </Backdrop>
  );
};
