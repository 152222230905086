import { AppBar, Box, Tab, Typography } from '@mui/material';
import { useEffect, useState, SyntheticEvent, useCallback } from 'react';
import { TabContext } from '@mui/lab';
import TabList from '@mui/lab/TabList';
import { Link } from 'react-router-dom';
import { useEditOrderContext } from '../../data-access/Context/EditOrderContext';
import { useMsal } from '@azure/msal-react';

const Navbar = () => {
  const [navbarImg, setNavbarImg] = useState('Nemo_Tombstone.png');
  const [navValue, setNavValue] = useState('1');
  const editOrderContext = useEditOrderContext();
  const { accounts } = useMsal();

  useEffect(() => {
    const month = new Date().getMonth();
    if (month === 11) {
      setNavbarImg('Nemo_Tombstone_Christmas.png');
    } else {
      setNavbarImg('Nemo_Tombstone.png');
    }

    updateSelectedTab();
  }, []);

  const updateSelectedTab = () => {
    if (window.location.pathname.includes('report')) setNavValue('2');
    if (window.location.pathname.includes('admin')) setNavValue('3');
    if (window.location.pathname.includes('data')) setNavValue('4');
    if (window.location.pathname.includes('plug')) setNavValue('5');
  };

  const handleTabChange = useCallback((_event: SyntheticEvent, value: string) => {
    setNavValue(value);
  }, []);

  const isAdmin = () => {
    if (accounts.length === 0) return false;
    return accounts[0].idTokenClaims?.roles?.includes('Role.Admin');
  };

  const hasDataAccess = () => {
    if (accounts.length === 0) return false;
    return accounts[0].idTokenClaims?.roles?.includes('Role.DataAccess');
  };

  return (
    <AppBar sx={NavBarStyle}>
      <Box sx={NavBarBoxStyle}>
        <TabContext value={navValue}>
          <Box ml={2}>
            <TabList onChange={handleTabChange}>
              <Tab
                disabled={editOrderContext.editingState}
                label="Ordrer"
                value="1"
                component={Link}
                to={'/'}
                sx={NavBarTabsStyle}
              />
              <Tab
                label="Rapportering"
                value="2"
                component={Link}
                to={'/report'}
                sx={NavBarTabsStyle}
                disabled={editOrderContext.editingState}
              />
              <Tab
                disabled={editOrderContext.editingState}
                label="Admin"
                value="3"
                component={Link}
                to={'/admin'}
                sx={NavBarTabsStyle}
              />
              {(isAdmin() || hasDataAccess()) && (
                <Tab
                  disabled={editOrderContext.editingState}
                  label="Data"
                  value="4"
                  component={Link}
                  to={'/data'}
                  sx={NavBarTabsStyle}
                />
              )}
              {isAdmin() && (
                <Tab
                  disabled={editOrderContext.editingState}
                  label="Prop i energy order"
                  value="5"
                  component={Link}
                  to={'/plug'}
                  sx={NavBarTabsStyle}
                />
              )}
            </TabList>
          </Box>
        </TabContext>

        <Box sx={NavBarTitleStyle}>
          <Box>
            <img src={navbarImg} alt="" style={{ height: '50px' }} />
          </Box>
          <Box ml={2}>
            <Typography fontSize={'16px'}>Norlys Emobility Onboarding</Typography>
            <Typography fontSize={'12px'} fontWeight={'600'} align={'right'} mt={0.5}>
              NEMO
            </Typography>
          </Box>
        </Box>
      </Box>
    </AppBar>
  );
};

const NavBarStyle = {
  position: 'sticky',
  display: 'flex',
  backgroundColor: 'white',
  boxShadow: 'none'
};

const NavBarTitleStyle = {
  display: 'flex',
  color: 'black',
  marginRight: 2,
  marginTop: -1
};

const NavBarBoxStyle = {
  paddingTop: 2,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  borderBottom: 1,
  borderColor: 'divider'
};

const NavBarTabsStyle = {
  fontWeight: 600,
  paddingLeft: 4,
  paddingRight: 4
};

export default Navbar;
