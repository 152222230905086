import {
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

interface Props {
  values: Array<string>;
  error: boolean;
  value: number;
  onChange: (e: SelectChangeEvent) => void;
  dataTestId?: string;
  label?: string;
  minWidth?: number;
  fullWidth?: boolean;
  disabled?: boolean;
  showAsterisk?: boolean;
  showWarningIcon?: boolean;
  touchedInitially?: boolean;
}

const SelectWithCopy: React.FC<Props> = (props) => {
  const [iconColor, setIconColor] = useState('black');
  const [hidden, setHidden] = useState(true);
  const [selectedValue, setSelectedValue] = useState('');
  const [touched, setTouched] = useState(props.touchedInitially ? props.touchedInitially : false);

  useEffect(() => {
    const specifiedValue = props.values[props.value ? props.value : 0];
    setSelectedValue(specifiedValue ? specifiedValue : '');
  }, [props.value]);

  const copy = async () => {
    await navigator.clipboard.writeText(selectedValue);
    setIconColor('green');
  };

  return (
    <div style={{ width: props.fullWidth ? '100%' : '' }}>
      {props.label && (
        <>
          <Typography fontSize={'14px'} fontWeight={'bold'}>
            {props.label}{' '}
            {props.showAsterisk && (
              <span>
                <span
                  style={{
                    fontSize: '18px',
                    verticalAlign: 'sub',
                    position: 'absolute',
                    paddingLeft: '10px',
                    color: props.error && touched ? 'red' : ''
                  }}
                >
                  *
                </span>
              </span>
            )}
          </Typography>
        </>
      )}

      <InputWrapper
        fullWidth={props.fullWidth || false}
        onMouseEnter={() => setHidden(false)}
        onMouseLeave={() => {
          setHidden(true);
          setIconColor('black');
        }}
      >
        <Select
          onFocus={() => {
            setTouched(true);
          }}
          disabled={props.disabled}
          sx={{ minWidth: props.minWidth + 'px' }}
          labelId="select-with-copy-button"
          id="select-with-copy-button"
          value={selectedValue}
          error={props.error && touched}
          onChange={(e) => props.onChange(e)}
          input={
            <OutlinedInput
              style={{ textAlign: 'left', width: props.fullWidth ? '100%' : '200px' }}
            />
          }
          MenuProps={{ PaperProps: { sx: { maxHeight: '300px' } } }}
          data-testid={props.dataTestId}
        >
          {props.values.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item.replace(/_/g, ' ')}
            </MenuItem>
          ))}
        </Select>
        {!hidden && (
          <ButtonWrapper>
            <IconButton aria-label="copy content" onClick={copy} tabIndex={-1}>
              <ContentCopyRoundedIcon sx={{ color: iconColor, fontSize: '1rem' }} />
            </IconButton>
          </ButtonWrapper>
        )}
      </InputWrapper>
    </div>
  );
};

const InputWrapper = styled.div<{ fullWidth: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-right: 1rem;
  ${(props) =>
    !props.fullWidth &&
    css`
      max-width: 200px;
    `}

  > .MuiInputBase-root {
    max-height: 2.5rem;
    width: 200px;
  }
`;

const ButtonWrapper = styled.div`
  display: hidden !important;
  margin: 0.1rem -2rem 0 0;
`;

export default SelectWithCopy;
