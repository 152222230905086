import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { IOrderTableData } from '../../util/Models/OrderTableDataModel';
import { IConfiguration } from '../../util/Models/ConfigurationModel';
import { Box } from '@mui/material';
import OrderPageHeader from './OrderPageHeader';
import OrderTable from './OrderTable/OrderTable';
import OrderInfoBox from './OrderInfoBox/OrderInfoBox';
import { useOrderContext } from '../../data-access/Context/OrderContext';
import { useMsal } from '@azure/msal-react';
import { useEditOrderContext } from '../../data-access/Context/EditOrderContext';
import { IContractInformation } from '../../util/Models/ContractModel';
import { OrderLock } from '../../util/Models/OrderLock';

interface Props {
  filteredOrders: Array<IOrderTableData>;
  readOnly: boolean;
  configuration?: IConfiguration;
  orderLocks: OrderLock[];
  setFilteredOrders: Dispatch<SetStateAction<IOrderTableData[]>>;
  allContracts: Array<IContractInformation>;
  onlyFastTrackOrders: boolean;
  setOnlyFastTrackOrders: Dispatch<SetStateAction<boolean>>;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

let unchangedOrder: null | IOrderTableData = null;

const OrderPage: React.FC<Props> = (props) => {
  const editOrderContext = useEditOrderContext();
  const {
    filteredOrders,
    readOnly,
    configuration,
    setFilteredOrders,
    onlyFastTrackOrders,
    setOnlyFastTrackOrders
  } = props;
  const { accounts } = useMsal();
  const { state } = useOrderContext();
  const [chosenOrderId, setChosenOrderId] = useState('');
  const [newOrderToDisplay, setNewOrderToDisplay] = useState<IOrderTableData | null>(null);
  const [isNewOrder, setIsNewOrder] = useState<boolean>(false);

  const showOrder = (orderId: string) => {
    if (!editOrderContext.editingState) {
      let order: IOrderTableData | undefined = filteredOrders.find((order) => order.id == orderId);
      if (order == undefined) return;

      unchangedOrder = structuredClone(order);
      setChosenOrderId(orderId);
    }
  };

  const cancelEdit = () => {
    if (unchangedOrder) {
      let arr = filteredOrders.filter((x) => x.id !== unchangedOrder?.id);
      arr.push(unchangedOrder);
      setFilteredOrders(arr);
      unchangedOrder = null;
    }
  };

  useEffect(() => {
    if (newOrderToDisplay != null) {
      showOrder(newOrderToDisplay.id);
      setNewOrderToDisplay(null);
    }
  }, [newOrderToDisplay]);

  return (
    <Box sx={OrderPageStyle}>
      <Box sx={OrderTableStyle}>
        <OrderPageHeader
          setFilteredOrders={setFilteredOrders}
          state={state}
          readOnly={readOnly}
          configuration={configuration}
          setNewOrderToDisplay={setNewOrderToDisplay}
          setIsNewOrder={setIsNewOrder}
          onlyFastTrackOrders={props.onlyFastTrackOrders}
          setCurrentPage={props.setCurrentPage}
        />
        <OrderTable
          selectedOrderId={chosenOrderId}
          orderLocks={props.orderLocks}
          filteredOrders={filteredOrders}
          readOnly={readOnly}
          configuration={configuration}
          onlyFastTrackOrders={onlyFastTrackOrders}
          setOnlyFastTrackOrders={setOnlyFastTrackOrders}
          currentPage={props.currentPage}
          setCurrentPage={props.setCurrentPage}
          onSelectOrder={(orderData: IOrderTableData) => showOrder(orderData.id)}
        />
      </Box>
      {chosenOrderId != '' &&
        filteredOrders.find((order) => order.id == chosenOrderId) != undefined &&
        configuration && (
          <Box sx={OrderInfoBoxStyle}>
            <OrderInfoBox
              cancelEdit={cancelEdit}
              order={filteredOrders.find((order) => order.id == chosenOrderId)!}
              onClose={() => {
                setChosenOrderId('');
                setIsNewOrder(false);
              }}
              configuration={configuration}
              userName={accounts[0]?.username || ''}
              allContracts={props.allContracts}
              orderLocks={props.orderLocks}
              isNewOrder={isNewOrder}
            />
          </Box>
        )}
    </Box>
  );
};

export default OrderPage;

const OrderPageStyle = {
  position: 'absolute',
  top: '65px',
  bottom: 0,
  width: '100vw',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch'
};

const OrderTableStyle = {
  flexDirection: 'column',
  flex: 2
};

const OrderInfoBoxStyle = {
  maxWidth: 100 / 3 + '%',
  flex: 1
};
