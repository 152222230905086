import axios from 'axios';
import { msalInstance } from '../..';
import { loginRequest } from '../../authConfig';
import { BFFUrl } from '../util/constants';
import { IConfiguration } from '../util/Models/ConfigurationModel';

const uri = `${BFFUrl}/api/v1`;
const configurationApi = axios.create({
  baseURL: uri
});

configurationApi.interceptors.request.use(async function (config) {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  });

  if (response.accessToken) {
    if (config.headers) {
      config.headers['Authorization'] = `Bearer ${response.accessToken}`;
    }
  }
  return config;
});

const ConfigurationService = {
  getConfiguration: async (): Promise<IConfiguration> => {
    const res = await configurationApi.get<IConfiguration>('/Configuration');

    const configuration: IConfiguration = {
      prices: res.data.prices,
      salesChannels: res.data.salesChannels,
      partners: res.data.partners,
      outbounds: res.data.outbounds,
      companyCars: res.data.companyCars,
      automaticSteps: res.data.automaticSteps
    };
    return configuration;
  },
  addConfiguration: async (configuration: IConfiguration): Promise<IConfiguration | null> => {
    const res = await configurationApi.post<IConfiguration>('/Configuration', configuration);
    if (res.data) {
      const config: IConfiguration = {
        prices: res.data.prices,
        salesChannels: res.data.salesChannels,
        partners: res.data.partners,
        outbounds: res.data.outbounds,
        companyCars: res.data.companyCars
      };
      return config;
    }
    return null;
  }
};

export default ConfigurationService;
