import { Box, Grid, styled } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { OrderState } from '../../../../util/Enums/OrderState';
import { ICompanyInformation, IContractInformation } from '../../../../util/Models/ContractModel';
import {
  ContractDateField,
  ContractField,
  getCompanyField,
  getContractGroupField
} from '../OrderInfoBoxFields/ContractFields';
import { FieldSettings, OrderInfoTabProps } from './CustomerTab';
import { OwnerTypeEnum } from '../../../../util/Enums/OwnerTypeEnum';

export interface ContractTabProps extends OrderInfoTabProps {
  allContracts: Array<IContractInformation>;
}

export const ContractTab: React.FC<ContractTabProps> = (props) => {
  const { order, canEdit, setOrder, allContracts, configuration } = props;
  const companyGroupNumber = '39003';
  const subscriptionContractTypes = [1, 2];
  const installationContractTypes = [3, 4];
  const [isValidContractDate, setIsValidContractDate] = useState<boolean>(true);
  const [contractGroups, setContractGroups] = useState<IContractInformation[]>([]);
  const [selectedContractGroup, setSelectedContractGroup] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [companyName, setCompanyName] = useState('');
  const [companies, setCompanies] = useState<ICompanyInformation[]>([]);
  const [installationContracts, setInstallationContracts] = useState<IContractInformation[]>([]);

  useEffect(() => {
    props.setIsValid(isValidContractDate);
  }, [isValidContractDate]);

  useEffect(() => {
    determineContractGroups();
  }, [order.id, order.ownerType, allContracts]);

  useEffect(() => {
    let chosenContractGroup = getChosenContractGroup();
    setSelectedContractGroup(chosenContractGroup ? chosenContractGroup : '');

    setCompanyName(
      allContracts.find((contract) => contract.contractID == order.installationContractId)
        ?.companyName ?? ''
    );
  }, [order.id, contractGroups]);

  useEffect(() => {
    setIsDisabled(selectedContractGroup.length <= 0);
  }, [selectedContractGroup]);

  useEffect(() => {
    const distinctCompanyNames = new Set<string>();

    allContracts.forEach((contract) => {
      if (!distinctCompanyNames.has(contract.companyName)) {
        distinctCompanyNames.add(contract.companyName);
        return true;
      }
      return false;
    });

    const distinctCompanies = new Array<ICompanyInformation>();

    distinctCompanyNames.forEach((name) => {
      let subscriptionCompanyId = allContracts.find(
        (contract) =>
          contract.companyName === name &&
          contract.contractGroup == companyGroupNumber &&
          subscriptionContractTypes.includes(contract.emobilityContractType)
      )?.companyId;

      let installationCompanyId = allContracts.find(
        (contract) =>
          contract.companyName === name &&
          contract.contractGroup == companyGroupNumber &&
          installationContractTypes.includes(contract.emobilityContractType)
      )?.companyId;

      let company: ICompanyInformation = {
        name: name,
        subscriptionCompanyId: subscriptionCompanyId ?? '',
        installationCompanyId: installationCompanyId ?? ''
      };
      distinctCompanies.push(company);
    });

    setCompanies(distinctCompanies);
  }, [allContracts]);

  const greyText = '#858383';

  const fieldSettings: FieldSettings = {
    order: order,
    canEdit: canEdit,
    textColor: greyText,
    setOrder: setOrder,
    configuration: configuration
  };

  useEffect(() => {
    setInstallationContracts(getContractsByContractType(installationContractTypes));
  }, [companyName, order.id, selectedContractGroup]);

  function determineContractGroups() {
    let uniqueContractGroups = Array<string>();

    let uniqueContracts = Array<IContractInformation>();
    allContracts.forEach((contract) => {
      if (!uniqueContractGroups.includes(contract.contractGroup)) {
        uniqueContractGroups.push(contract.contractGroup);
        uniqueContracts.push(contract);
      }
    });

    let filteredContracts = uniqueContracts.filter((contract) =>
      contract.contractGroupText.includes(OwnerTypeEnum[order.ownerType])
    );

    setContractGroups(filteredContracts);
  }

  function isCompanyGroup(): boolean {
    return selectedContractGroup.includes(companyGroupNumber);
  }

  function getChosenContractGroup(): string | undefined {
    if (order.subscriptionContractId)
      return getContractById(order.subscriptionContractId, allContracts)?.contractGroup;
    if (order.installationContractId)
      return getContractById(order.installationContractId, allContracts)?.contractGroup;
    if (contractGroups.length == 1) return contractGroups[0].contractGroup;
    return '';
  }

  function getContractById(
    contractId: string,
    allContracts: Array<IContractInformation>
  ): IContractInformation | null {
    let contract = allContracts.find((c) => c.contractID === contractId);
    return contract ? contract : null;
  }

  function getContractsByContractType(listOfContractTypes: number[]): Array<IContractInformation> {
    return allContracts.filter((contract) => {
      return (
        listOfContractTypes.includes(contract.emobilityContractType) &&
        contract.contractGroup === selectedContractGroup &&
        (!isCompanyGroup() || contract.companyName == companyName)
      );
    });
  }

  function handleSelectContractGroup(newValue: string) {
    setCompanyName('');
    setSelectedContractGroup(newValue);
    resetContractIds();
  }

  function handleSelectCompany(newValue: string) {
    setCompanyName(newValue);
    let company = companies.find((company) => company.name == newValue);
    setOrder({
      ...order,
      subscriptionContractId: '',
      subscriptionContractCompanyId: company?.subscriptionCompanyId ?? '',
      installationContractId: '',
      installationContractCompanyId: company?.installationCompanyId ?? '',
      state: OrderState.DIRTY
    });
  }

  function handleSelectInstallationContract(newValue: string) {
    setOrder({
      ...order,
      installationContractId: newValue,
      state: OrderState.DIRTY
    });
  }

  function handleSelectContractDate(newValue: Date | null) {
    if (newValue == null || moment(newValue).isValid()) {
      setIsValidContractDate(true);
      setOrder({
        ...order,
        contractDate: newValue,
        state: OrderState.DIRTY
      });
    } else {
      setIsValidContractDate(false);
    }
  }

  function resetContractIds() {
    setOrder({
      ...order,
      subscriptionContractId: '',
      subscriptionContractCompanyId: '',
      installationContractId: '',
      installationContractCompanyId: '',
      state: OrderState.DIRTY
    });
  }

  return (
    <Box>
      <Grid container direction={'column'} sx={GridStyle}>
        <WidthControlledGrid>
          <Grid item xs={12}>
            {getContractGroupField(
              fieldSettings,
              contractGroups,
              handleSelectContractGroup,
              selectedContractGroup,
              order.contractDate
            )}
          </Grid>
        </WidthControlledGrid>
        <WidthControlledGrid>
          {isCompanyGroup() && (
            <Grid item xs={12}>
              {getCompanyField(
                fieldSettings,
                companies,
                handleSelectCompany,
                companyName,
                'Firma',
                isDisabled || order.contractDate !== null
              )}
            </Grid>
          )}
        </WidthControlledGrid>
        <WidthControlledGrid>
          {!order.dealerFunded && (
            <Grid item xs={12}>
              <ContractField
                fieldSettings={fieldSettings}
                filteredContractArray={installationContracts}
                handleSelectContract={handleSelectInstallationContract}
                selectedValue={getContractById(order.installationContractId, allContracts)}
                title={'Installationskontrakt'}
                disabled={isDisabled || order.contractDate !== null}
              />
            </Grid>
          )}
        </WidthControlledGrid>
        <WidthControlledGrid>
          <Grid item xs={12}>
            <ContractDateField
              fieldSettings={fieldSettings}
              handleSetContractDate={handleSelectContractDate}
              isValidContractDate={isValidContractDate}
            />
          </Grid>
        </WidthControlledGrid>
      </Grid>
    </Box>
  );
};

const GridStyle = {
  marginTop: '0px',
  padding: '10px 0 15px 4px'
};

const WidthControlledGrid = styled(Grid)`
  max-width: 100%;
`;

export default ContractTab;
