import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IState } from '../../data-access/Context/OrderReducer';
import { IOrderTableData } from '../../util/Models/OrderTableDataModel';
import DateFilterButton from './DateFilterButton/DateFilterButton';
import FilterButton from './FilterButton/FilterButton';
import { DateFilterElement, StatusFilterElement } from './FilterTypes';
import { OrderState } from '../../util/Enums/OrderState';
import { FilterStepModel } from '../../util/Models/FilterStepModel';
import { IStep } from '../../util/Models/StepModel';
import { StepStatus } from '../../util/Enums/StepStatusEnum';
import { Tooltip, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from 'styled-components';

interface IFilterProps {
  state: IState;
  setFilteredOrders: React.Dispatch<React.SetStateAction<IOrderTableData[]>>;
  resetFilter: boolean;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const FilterWrap: React.FC<IFilterProps> = (props: IFilterProps) => {
  const { state, setFilteredOrders, resetFilter } = props;
  const [filters, setFilters] = useState<
    Array<FilterStepModel | StatusFilterElement | DateFilterElement>
  >([]);
  const [steps, setSteps] = useState<FilterStepModel[]>([]);
  const [showResetButton, setShowResetButton] = useState<boolean>(false);
  const [localResetFilter, setLocalResetFilter] = useState<boolean>(false);

  useEffect(() => {
    findSteps();
    applyFilters();
  }, [state]);

  useEffect(() => {
    window.removeEventListener('clearSearch', clearFilters);
    window.addEventListener('clearSearch', clearFilters);
  }, []);

  useEffect(() => {
    applyFilters();
    props.setCurrentPage(0);
  }, [filters]);

  useEffect(() => {
    clearFilters();
    props.setCurrentPage(0);
  }, [resetFilter]);

  const clearFilters = () => {
    setFilters([]);
    setLocalResetFilter(!localResetFilter);
  };
  const applyFilters = () => {
    let ordersToShow = [...state.orders];
    let showReset = false;

    let activeOrder = state.orders.find((o) => o.id === sessionStorage.getItem('activeOrder'));

    for (let filter of filters) {
      ({ ordersToShow, showReset } = applySingleFilter(filter, ordersToShow, showReset));
    }

    if (activeOrder && !ordersToShow.some((o) => o.id == activeOrder?.id)) {
      ordersToShow.push(activeOrder);
    }
    setShowResetButton(showReset);
    setFilteredOrders(ordersToShow);
  };

  function applySingleFilter(
    filter: FilterStepModel | StatusFilterElement | DateFilterElement,
    ordersToShow: IOrderTableData[],
    showReset: boolean
  ) {
    if ((filter as StatusFilterElement).status != undefined) {
      ordersToShow = ordersToShow.filter((o) => o.status == (filter as StatusFilterElement).status);
      showReset = true;
    } else if ((filter as DateFilterElement).date != undefined) {
      const dateFilter = filter as DateFilterElement;
      ordersToShow = applyDateFilter(dateFilter, ordersToShow);
      showReset = true;
    } else if ((filter as FilterStepModel).name != undefined) {
      const stepFilter = filter as FilterStepModel;
      if (stepFilter.filterOnItem) {
        ordersToShow = ordersToShow.filter((o) =>
          o.steps.some((s) => s.name == stepFilter.name && s.status == StepStatus.Completed)
        );
      } else if (!stepFilter.filterOnItem) {
        ordersToShow = ordersToShow.filter(
          (o) => !o.steps.some((s) => s.name == stepFilter.name && s.status == StepStatus.Completed)
        );
      }
      showReset = true;
    }
    return { ordersToShow, showReset };
  }

  function applyDateFilter(dateFilter: DateFilterElement, ordersToShow: IOrderTableData[]) {
    const fromDate = dateFilter.fromDate == null ? null : new Date(dateFilter.fromDate);
    const toDate = dateFilter.toDate;
    if (dateFilter.date == 'confirmedInstallationDate') {
      ordersToShow = ordersToShow.filter((o) =>
        o.steps.some(
          (s) =>
            s.name == 'confirmedInstallationDate' &&
            DateFilter(fromDate, toDate, s.value as unknown as Date)
        )
      );
    } else {
      ordersToShow = ordersToShow.filter((o) =>
        DateFilter(
          fromDate,
          toDate,
          o[
            dateFilter.date as
              | 'applicationDate'
              | 'contactAttempt'
              | 'modified'
              | 'wishedInstallationMonth'
          ]
        )
      );
    }
    return ordersToShow;

    function DateFilter(from: Date | null, to: Date | null, val: Date | null): boolean | null {
      return (
        (!val && !from && !to) ||
        (val &&
          ((!from && to && to >= val) ||
            (from && from <= val && !to) ||
            (from && from <= val && to && to >= val)))
      );
    }
  }

  const addFilter = function (
    elm: FilterStepModel | StatusFilterElement | DateFilterElement
  ): void {
    setFilters([...filters, elm]);
  };

  const removeFilter = function (
    elm: FilterStepModel | StatusFilterElement | DateFilterElement
  ): void {
    let spliceIndex = filters.findIndex(
      (element) => JSON.stringify(element) === JSON.stringify(elm)
    );
    if (spliceIndex >= 0) {
      while (spliceIndex !== -1) {
        filters.splice(spliceIndex, 1);
        spliceIndex = filters.findIndex(
          (element) => JSON.stringify(element) === JSON.stringify(elm)
        );
      }
      applyFilters();
    }
  };

  function findSteps() {
    let items: FilterStepModel[] = [];

    function addItems(s: IStep) {
      items.push({
        label: s.label,
        name: s.name,
        shouldApplyFilter: false,
        filterOnItem: false
      });
    }

    if (state.orders.length <= 0) return;

    let oldOrder = state.orders.find((o) => o.state != OrderState.NEW);
    if (typeof oldOrder == 'undefined') return;

    for (let i = 0; i < oldOrder.steps.length; i++) {
      let step = oldOrder.steps.find((s) => s.sequenceNumber == i);
      if (typeof step == 'undefined') return;

      if (step.label !== 'Marketing mails') {
        addItems(step);
      }
    }
    setSteps(items);
  }

  return (
    <Wrapper>
      {showResetButton && (
        <Tooltip title="Nulstil filtre">
          <IconButton
            onClick={clearFilters}
            sx={{ width: '40px', margin: '0 -5px 0 -35px ' }}
            aria-label="clear filters"
            size="small"
            color="info"
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      )}
      <FilterButton
        addFilter={addFilter}
        removeFilter={removeFilter}
        steps={steps}
        resetFilter={localResetFilter}
      />
      <DateFilterButton
        addFilter={addFilter}
        removeFilter={removeFilter}
        resetFilter={localResetFilter}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-left: 20px;
  min-width: 140px;
  display: flex;
  justify-content: end;
  margin-left: 20px;
`;
export default FilterWrap;
