export interface IRawShopDetails {
  shopId: string;
  shopName: string;
  sellerId: string;
  sellerName: string;
  sellerEmail: string;
  campaign: string;
}

export interface IShopDetails extends IRawShopDetails {}

export class ShopDetails implements IShopDetails {
  shopId = '';
  shopName = '';
  sellerId = '';
  sellerName = '';
  sellerEmail = '';
  campaign = '';
}
