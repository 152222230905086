import axios from 'axios';
import { msalInstance } from '../..';
import { loginRequest } from '../../authConfig';
import { BFFUrl } from '../util/constants';
import { PlugIntervalModel } from '../util/Models/IntervalModel';

const uri = `${BFFUrl}/api/v1`;
const plugApi = axios.create({
  baseURL: uri
});

plugApi.interceptors.request.use(async function (config) {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  });

  if (response.accessToken) {
    if (config.headers) {
      config.headers['Authorization'] = `Bearer ${response.accessToken}`;
    }
  }
  return config;
});

const PlugService = {
  getPlugs: async (): Promise<PlugIntervalModel[]> => {
    const res = await plugApi.get<PlugIntervalModel[]>('/Plug');

    return res.data ?? [];
  },

  setPlugs: async (plugs: PlugIntervalModel[]): Promise<PlugIntervalModel[]> => {
    const res = await plugApi.post<PlugIntervalModel[]>('/Plug', plugs);
    return res.data ?? [];
  },

  isPlugActive: async (): Promise<boolean> => {
    const res = await plugApi.get<boolean>('/Plug/IsActive');

    return res.data ?? [];
  }
};

export default PlugService;
