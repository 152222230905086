import React from 'react';
import { TableCell, TableRow, Tooltip } from '@mui/material';
import { IOrderTableData } from '../../../util/Models/OrderTableDataModel';
import { formatDateTime } from '../../../util/Formatters/FormatDate';
import { OrderStatusEnum } from '../../../util/Enums/OrderStatusEnum';
import { AccessTimeFilled } from '@mui/icons-material';
import styled from 'styled-components';
import LockIcon from '@mui/icons-material/Lock';
import { useEditOrderContext } from '../../../data-access/Context/EditOrderContext';
import OrderListCommentPopover from './OrderListCommentPopover';

interface Props {
  data: IOrderTableData;
  userName: string;
  onClick: () => void;
  lockedBy: string;
  isSelected: boolean;
}

const OrderListItem: React.FC<Props> = (props) => {
  const { data } = props;
  const editOrderContext = useEditOrderContext();

  return (
    <>
      <TableRow
        onClick={props.onClick}
        sx={{
          cursor: editOrderContext.editingState ? 'default' : 'pointer',
          backgroundColor: props.isSelected ? '#f1f8fe' : ''
        }}
      >
        <TableCell>
          <IconWrapper>
            {data.rightOfCancellationWaived ? (
              <AccessTimeFilled sx={{ color: 'orange' }} />
            ) : (
              <AccessTimeFilled sx={{ color: 'ghostwhite' }} />
            )}

            {props.lockedBy.length > 0 ? (
              <Tooltip title={props.lockedBy.split('@')[0]} placement="top">
                <LockIcon className="lock-icon"></LockIcon>
              </Tooltip>
            ) : (
              <OrderListCommentPopover comments={data.comments} />
            )}
          </IconWrapper>
        </TableCell>
        <TableCell component="th" scope="row" data-testid={'list-item-customer-name'}>
          {data.customer.name}
        </TableCell>
        <TableCell data-testid={'list-item-installation-address'}>
          {data.installationAddress}
        </TableCell>
        <TableCell data-testid={'list-item-customer-number'}>
          {data.customer.sonwinCustomerNumber}
        </TableCell>
        <TableCell data-testid={'list-item-customer-phone'}>{data.customer.phone}</TableCell>
        <TableCell data-testid={'list-item-application-date'}>
          {formatDateTime(data.applicationDate)}
        </TableCell>
        <TableCell data-testid={'list-item-status'}>{OrderStatusEnum[data.status]}</TableCell>
      </TableRow>
    </>
  );
};

const IconWrapper = styled.div`
  min-width: 60px;

  .lock-icon {
    color: #635e5e;
    margin-left: 5px;
  }
`;

export default OrderListItem;
