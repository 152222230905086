import InputWithCopy from '../../ui/InputWithCopy/InputWithCopy';
import addressAutocomplete from '../../data-access/DawaService';
import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { IDawaSuggestion } from '../../util/Interfaces/IDawaSuggestion';
import DawaSuggestionList, { DawaSuggestionHandle } from './DawaSuggestionList';
import { useOnClickOutside } from '../../hooks/useClickOutside';
import { IValidation } from '../../util/Helpers/InputValidationHelper';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export interface IDawaSuggestionProps {
  value: string;
  suggestionPicked: (suggestion: IDawaSuggestion) => void;
  validation?: IValidation;
  setIsValid?: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
  onChange?: (val: string) => void;
  showValidationError?: boolean;
  dataTestId?: string;
  label?: string;
  fullWidth?: boolean;
  showAsterisk?: boolean;
}

const DawaSuggestionFeature = (props: IDawaSuggestionProps) => {
  const [searchText, setSearchText] = useState<string>(props.value);
  const [suggestions, setSuggestions] = useState<Array<IDawaSuggestion>>([]);
  const [suggestion, setSuggestion] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);

  let latestRequest: string = '';
  let isWaiting: boolean = false;

  const childRef = useRef<DawaSuggestionHandle>(null);
  const outerRef = useRef(null);

  const [paste, setPaste] = useState(false);

  useOnClickOutside(outerRef, () => {
    childRef.current?.toggleOpen(false);
  });

  useEffect(() => {
    setSearchText(props.value);
  }, [props]);

  useEffect(() => {
    if (suggestions.length === 1 && paste) {
      pickResult(0);
    }

    setPaste(false);
  }, [suggestions]);

  const handleWrapperClick = () => {
    childRef.current?.toggleOpen(true);
  };

  const getSuggestions = (query: string, waitTime = 200) => {
    latestRequest = query;

    if (!isWaiting) {
      isWaiting = true;

      setTimeout(() => {
        addressAutocomplete(latestRequest, true).then((res) => {
          if (res.status === 429) {
            getSuggestions(query, waitTime + 500);
            return;
          }

          setSuggestions(res.data as Array<IDawaSuggestion>);
          childRef.current?.toggleOpen(true);
          isWaiting = false;
        });
      }, waitTime);
    }
  };

  const handleTextChange = async (val: string) => {
    if (val.length > 1) {
      getSuggestions(val, 500);
    } else if (val.length === 0) {
      childRef.current?.toggleOpen(false);
      setSuggestions([]);
      setSearchText('');
    }
    setSelectedIndex(0);

    if (props.onChange) {
      props.onChange(val);
    }
  };

  const handleKeyChange = (e: any) => {
    if (e === 'ArrowUp') {
      if (selectedIndex <= 0) {
        setSelectedIndex(suggestions.length - 1);
      } else {
        setSelectedIndex(selectedIndex - 1);
      }
    }

    if (e === 'ArrowDown') {
      if (selectedIndex >= suggestions.length - 1) {
        setSelectedIndex(0);
      } else {
        setSelectedIndex(selectedIndex + 1);
      }
    }

    if (e === 'Enter') {
      if (suggestions.length > 0) {
        pickResult(selectedIndex);
      }
    }
  };

  const pickResult = (index: number) => {
    childRef.current?.toggleOpen(false);
    const target = suggestions[index];
    setSearchText(target.tekst);
    setSuggestion(target.tekst);
    props.suggestionPicked(target);
  };

  const handlePaste = () => {
    setPaste(true);
  };

  return (
    <Wrapper ref={outerRef} onClick={handleWrapperClick}>
      <InputWithCopy
        fullWidth={props.fullWidth}
        showAsterisk={props.showAsterisk}
        label={props.label}
        onPaste={handlePaste}
        disabled={props.disabled}
        onKeyUp={handleKeyChange}
        onChange={handleTextChange}
        value={searchText}
        validation={props.validation}
        suggestion={suggestion}
        showValidationError={props.showValidationError}
        dataTestId={props.dataTestId}
      ></InputWithCopy>
      <DawaSuggestionList
        ref={childRef}
        open={false}
        click={(item) => {
          childRef.current?.toggleOpen(false);
          setSuggestion(item.tekst);
          props.suggestionPicked(item);
        }}
        data={suggestions}
        selected={selectedIndex}
      ></DawaSuggestionList>
    </Wrapper>
  );
};

export default DawaSuggestionFeature;
