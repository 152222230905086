import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App/App';
import { PublicClientApplication, EventMessage, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { AuthenticationResult } from '@azure/msal-common';
import { msalConfig } from './authConfig';
import { BrowserRouter } from 'react-router-dom';

declare global {
  interface Window {
    _env_: any;
  }
}

export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>
);
