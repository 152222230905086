interface IProps {
  text: string;
}

const MultiLine: React.FC<IProps> = (props) => {
  return (
    <>
      {props.text.split(/\n/g).map((line, index) => {
        if (index === 0) {
          return <span key={index}>{line}</span>;
        } else {
          return (
            <span key={index}>
              <br />
              {line}
            </span>
          );
        }
      })}
    </>
  );
};

export default MultiLine;
