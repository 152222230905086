export interface IRawCustomer {
  id: string;
  sonwinCustomerNumber: string;
  groupConsent: boolean;
  name: string;
  secondaryName: string;
  email: string;
  phone: string;
}

export interface ICustomer extends IRawCustomer {}

export class Customer implements ICustomer {
  id = '';
  sonwinCustomerNumber = '';
  groupConsent = false;
  name = '';
  secondaryName = '';
  email = '';
  phone = '';
}
