import { Checkbox, Switch } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { FilterStepModel } from '../../../util/Models/FilterStepModel';

interface IFilterStepItem {
  item: FilterStepModel;
  onchange: React.Dispatch<FilterStepModel>;
}

const FilterStepItem = (props: IFilterStepItem) => {
  const { item, onchange } = props;
  const [switchOn, setSwitchOn] = useState(item.shouldApplyFilter);
  const [checked, setChecked] = useState(item.filterOnItem);

  useEffect(() => {
    onchange(item);
  }, [switchOn, checked]);

  const handleSwitch = (e: ChangeEvent<HTMLInputElement>) => {
    setSwitchOn(e.target.checked);
  };

  const handleCheck = (e: ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
  };

  item.shouldApplyFilter = switchOn;
  item.filterOnItem = checked;

  return (
    <>
      <Switch checked={switchOn} onChange={handleSwitch}></Switch>
      <Checkbox checked={checked} onChange={handleCheck} disabled={!switchOn}></Checkbox>
    </>
  );
};

export default FilterStepItem;
