import { useEffect, useState } from 'react';
import ReportService from '../Libs/data-access/ReportService';
import MessageService, { SnackbarSeverity } from '../Libs/data-access/MessageService';
import { Box, Grid, Button, TextField, Typography, TextFieldProps } from '@mui/material';
import daLocale from 'date-fns/locale/da';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import LoadingService from '../Libs/data-access/LoadingService';

const Report: React.FC = () => {
  const [fromDate, setFromDate] = useState<Date>(new Date());
  const [toDate, setToDate] = useState<Date>(new Date());
  const [validDate, setValidDate] = useState<boolean>(false);

  useEffect(() => {
    validateToDate();
  }, [fromDate, toDate]);

  const getReport = async () => {
    let fromValue: string, toValue: string;

    if (fromDate != null && toDate != null) {
      fromValue = new Date(fromDate).toISOString().slice(0, 10);
      toValue = new Date(toDate).toISOString().slice(0, 10);

      try {
        LoadingService.showLoader('Henter rapport...');

        let result = await ReportService.getReport(fromValue, toValue);

        if (result) {
          MessageService.showSnackbar({
            message: 'Rapport genereret',
            severity: SnackbarSeverity.SUCCESS
          });
        } else {
          MessageService.showDialog({
            title: 'En fejl opstod',
            message: 'Det lykkedes ikke at genere rapporten'
          });
        }

        LoadingService.showLoader('');
      } catch (error) {
        LoadingService.showLoader('');

        let errorMessage = '';
        if (error instanceof Error) {
          errorMessage = error.message;
        }
        MessageService.showDialog({
          title: 'En fejl opstod',
          message: errorMessage
        });
      }
    }
  };

  function validateToDate() {
    if (fromDate == null || toDate == null || fromDate > toDate) {
      setValidDate(false);
    } else {
      setValidDate(true);
    }
  }

  return (
    <Box px={3}>
      <Grid item xs={12}>
        <Typography py={4} data-testid="report-text">
          Vælg periode der ønskes data fra
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={daLocale}>
            <DatePicker
              mask=""
              inputFormat={'dd-MM-yyyy'}
              views={['year', 'month', 'day']}
              label="Fra dato"
              value={fromDate}
              onChange={(date) => {
                if (date) {
                  setFromDate(date);
                }
              }}
              renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                <TextField {...params} />
              )}
            />

            <Box ml={3} />

            <DatePicker
              label="Til dato"
              inputFormat={'dd-MM-yyyy'}
              value={toDate}
              minDate={fromDate}
              onChange={(date) => {
                if (date) {
                  setToDate(date);
                }
              }}
              renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                <TextField {...params} />
              )}
            />
          </LocalizationProvider>
          <Button
            sx={{ mx: 3 }}
            id="get-report"
            disabled={!validDate}
            onClick={() => getReport()}
            variant="contained"
          >
            Hent data
          </Button>
        </Box>
      </Grid>
    </Box>
  );
};

export default Report;
