import { SelectChangeEvent, Typography } from '@mui/material';
import moment from 'moment';
import { useCallback } from 'react';
import DateWithCopy from '../../../../ui/InputWithCopy/DateWithCopy';
import SelectWithCopy from '../../../../ui/InputWithCopy/SelectWithCopy';
import { OrderState } from '../../../../util/Enums/OrderState';
import {
  OrderedOrderStatusEnum,
  OrderStatusEnum,
  statusStringToEnumNumber,
  statusToAppearanceSequence
} from '../../../../util/Enums/OrderStatusEnum';
import { formatDate } from '../../../../util/Formatters/FormatDate';
import { FieldSettings } from '../OrderInfoBoxTabs/CustomerTab';

interface StatusFieldProps {
  isValidStatus: boolean;
  setIsValidStatus: React.Dispatch<React.SetStateAction<boolean>>;
  fieldSettings: FieldSettings;
}
export const StatusField: React.FC<StatusFieldProps> = (props) => {
  const { order, canEdit, setOrder } = props.fieldSettings;

  const handleChange = useCallback(
    (e: SelectChangeEvent<string>) => {
      setOrder({
        ...order,
        status: statusStringToEnumNumber(e.target.value),
        state: OrderState.DIRTY
      });
      props.setIsValidStatus(true);
    },
    [order]
  );

  return (
    <>
      {canEdit ? (
        <SelectWithCopy
          fullWidth={true}
          values={Object.keys(OrderedOrderStatusEnum).filter((v) => isNaN(Number(v)))}
          error={!props.isValidStatus}
          value={statusToAppearanceSequence(order.status)}
          onChange={handleChange}
          dataTestId={'customer-status'}
        />
      ) : (
        <Typography>{OrderStatusEnum[order.status]}</Typography>
      )}
    </>
  );
};

export function getLastContactAttemptField(
  isValidContactAttempt: boolean,
  setIsValidContactAttempt: React.Dispatch<React.SetStateAction<boolean>>,
  fieldSettings: FieldSettings
) {
  const { order, canEdit, setOrder } = fieldSettings;

  function validateAndSetDate(
    e: Date | null,
    setValid: React.Dispatch<React.SetStateAction<boolean>>,
    field: string
  ) {
    if (!moment(e).isValid() && e != null) {
      setValid(false);
      return;
    }

    switch (field) {
      case 'contactAttempt':
        setOrder({ ...order, contactAttempt: e, state: OrderState.DIRTY });
        setValid(true);
        break;
      case 'installationMonth':
        setOrder({ ...order, wishedInstallationMonth: e });
        setValid(e != null);
        break;
    }
  }

  return (
    <>
      {canEdit ? (
        <DateWithCopy
          value={order.contactAttempt}
          onChange={(e: Date | null) => {
            validateAndSetDate(e, setIsValidContactAttempt, 'contactAttempt');
          }}
          fullWidth={true}
          maxDate={new Date()}
          error={!isValidContactAttempt}
          dataTestId={'contact-attempt-date'}
        />
      ) : (
        <Typography>
          {order.contactAttempt == null ? '-' : formatDate(order.contactAttempt)}
        </Typography>
      )}
    </>
  );
}

export function getCustomerReminderField(
  isValidCustomerReminderDate: boolean,
  setIsValidCustomerReminderDate: React.Dispatch<React.SetStateAction<boolean>>,
  fieldSettings: FieldSettings
) {
  const { order, canEdit, setOrder } = fieldSettings;

  function validateAndSetDate(
    e: Date | null,
    setValid: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    if (!moment(e).isValid() && e != null) {
      setValid(false);
      return;
    }
    setOrder({
      ...order,
      installationData: { ...order.installationData, customerReminderDate: e },
      state: OrderState.DIRTY
    });
    setValid(true);
  }

  return (
    <>
      {canEdit ? (
        <DateWithCopy
          value={order.installationData.customerReminderDate}
          onChange={(e: Date | null) => {
            validateAndSetDate(e, setIsValidCustomerReminderDate);
          }}
          fullWidth={true}
          error={!isValidCustomerReminderDate}
          dataTestId={'customer-reminder-date'}
        />
      ) : (
        <Typography>
          {order.installationData.customerReminderDate == null
            ? '-'
            : formatDate(order.installationData.customerReminderDate)}
        </Typography>
      )}
    </>
  );
}
