import { IconButton, Input, InputLabel, Typography } from '@mui/material';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { IValidation, InputType, validateInput } from '../../util/Helpers/InputValidationHelper';

export type InputWithCopyHandle = {
  setFocus(): void;
};

interface Props {
  value: any;
  onChange?: (text: string) => void;
  onKeyUp?: (value: string) => void;
  validation?: IValidation;
  disabled?: boolean;
  validateBeforeTouch?: boolean;
  suggestion?: string;
  onBlur?: () => void;
  showValidationError?: boolean;
  onPaste?: (event: any) => void;
  dataTestId?: string;
  label?: string;
  fullWidth?: boolean;
  showAsterisk?: boolean;
  allowEmpty?: boolean;
}

const InputWithCopy = forwardRef<InputWithCopyHandle | undefined, Props>(
  ({ allowEmpty = false, ...props }: Props, ref) => {
    const [value, setValue] = useState(props.value);
    const [iconColor, setIconColor] = useState('black');
    const [hidden, setHidden] = useState(true);
    const [touched, setTouched] = useState(false);
    const [inputValid, setInputValid] = useState<boolean>(true);

    const inputType = props.validation ? props.validation.type : InputType.None;
    const validationErrorMessage = props.validation?.validationErrorMessage;

    const splitCharacters = [' ', '-', "'"];

    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      setFocus: () => {
        if (inputRef) {
          (inputRef.current?.children[0] as HTMLInputElement).focus();
        }
      }
    }));

    const copy = async () => {
      await navigator.clipboard.writeText(value);
      setIconColor('green');
    };

    const capitalStartLetters = (name: string): string => {
      try {
        let result = '';

        let arr = [...name];

        for (let i = 0; i < arr.length; ++i) {
          if (i === 0) {
            arr[0] = arr[0].charAt(0).toUpperCase() + arr[i].slice(1);
            continue;
          }
          if (splitCharacters.includes(arr[i - 1])) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
          }
        }

        arr.forEach((word) => {
          result += word;
        });

        return result;
      } catch {
        return name;
      }
    };

    useEffect(() => {
      if (props.validation?.validationStatus) {
        props.validation.validationStatus(inputValid);
      }
    }, [inputValid]);

    useEffect(() => {
      if (props.validateBeforeTouch || touched) {
        setInputValid(validateInput(inputType, value, props.validation?.regex, allowEmpty));
      }
    }, [value, props.validation]);

    useEffect(() => {
      if (props.suggestion != undefined && props.suggestion.length > 0) {
        setValue(props.suggestion);
      }
    }, [props.suggestion]);

    return (
      <InputWrapper
        fullWidth={props.fullWidth || false}
        onMouseEnter={() => setHidden(false)}
        onMouseLeave={() => {
          setHidden(true);
          setIconColor('black');
        }}
      >
        {props.label && (
          <div>
            <Typography fontSize={14} fontWeight={'bold'}>
              {props.label}{' '}
              {props.showAsterisk && (
                <span
                  style={{
                    fontSize: '18px',
                    verticalAlign: 'sub',
                    position: 'absolute',
                    paddingLeft: '10px',
                    color:
                      !validateInput(inputType, value, props.validation?.regex, allowEmpty) &&
                      touched
                        ? 'red'
                        : ''
                  }}
                >
                  *
                </span>
              )}
            </Typography>
          </div>
        )}
        <Input
          ref={inputRef}
          onPaste={(e) => {
            if (props.onPaste) {
              props.onPaste(e);
            }
          }}
          error={!inputValid || props.showValidationError}
          onKeyUp={(e) => {
            props.onKeyUp?.(e.key);
          }}
          onChange={(e) => {
            if (props.validation?.type == InputType.Name) {
              setValue(capitalStartLetters(e.target.value));
            } else {
              setValue(e.target.value);
            }

            props.onChange?.(e.target.value);
          }}
          onBlur={() => {
            setInputValid(validateInput(inputType, value, props.validation?.regex, allowEmpty));
            if (props.onBlur) {
              props.onBlur();
            }
            setTouched(true);
          }}
          value={value}
          inputProps={{ min: 0 }}
          endAdornment={
            !hidden && (
              <ButtonWrapper>
                <IconButton aria-label="copy content" onClick={copy} tabIndex={-1}>
                  <ContentCopyRoundedIcon sx={{ color: iconColor, fontSize: '1rem' }} />
                </IconButton>
              </ButtonWrapper>
            )
          }
          disabled={props.disabled}
          data-testid={props.dataTestId}
        />
        {(!inputValid || props.showValidationError) && (
          <InputLabel
            sx={{ alignSelf: 'start', fontSize: 10 }}
            data-testid={props.dataTestId + '-error-message'}
          >
            {validationErrorMessage}
          </InputLabel>
        )}
      </InputWrapper>
    );
  }
);

const InputWrapper = styled.div<{ fullWidth: boolean }>`
  display: flex;
  justify-content: left;
  padding-right: 1rem;
  ${(props) =>
    !props.fullWidth &&
    css`
      max-width: 200px;
    `}

  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: hidden !important;
  margin: 0 -2rem 0 0;
  display: flex;
`;

export default InputWithCopy;
