import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { useEffect, useState } from 'react';
import { BFFUrl } from '../../util/constants';
import { Dictionary } from '../../util/Interfaces/IDictionary';
import { IComment } from '../../util/Models/CommentModel';
import { IConfiguration } from '../../util/Models/ConfigurationModel';
import { OrderLock } from '../../util/Models/OrderLock';
import { IOrderTableData, IRawOrderTableData } from '../../util/Models/OrderTableDataModel';

interface IRecieveOrdersInterface {
  updateOrderCb: (order: IOrderTableData) => void;
  newOrderCb: (order: IOrderTableData) => void;
  orderLockChange: (lock: Array<OrderLock>) => void;
  configurationUpdates: (conf: IConfiguration) => void;
  orderDeleted: (orderId: IOrderTableData[]) => void;
}

export const ReceiveOrder = ({
  updateOrderCb,
  newOrderCb,
  orderLockChange,
  configurationUpdates,
  orderDeleted
}: IRecieveOrdersInterface) => {
  const [connection, setConnection] = useState<null | HubConnection>(null);
  useEffect(() => {
    const connect = new HubConnectionBuilder()
      .withUrl(BFFUrl + '/signalr/order')
      .withAutomaticReconnect()
      .build();
    setConnection(connect);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          connection.on('ReceiveOrderUpdate', (order: IRawOrderTableData) => {
            updateOrderCb(cleanDates(order));
          });
          connection.on('ReceiveOrderAdded', (order) => {
            newOrderCb(cleanDates(order));
          });
          connection.on('ReceiveOrderLocks', (lock: Dictionary<string>) => {
            let arr = Array<OrderLock>();

            Object.entries(lock).forEach((item) => {
              arr.push(new OrderLock(item[0], item[1]));
            });

            orderLockChange(arr);
          });
          connection.on('ReceiveConfigurationUpdated', (configuration) => {
            configurationUpdates(configuration);
          });

          connection.on('ReceiveOrderDeleted', (orders: IOrderTableData[]) => {
            orderDeleted(orders);
          });
        })
        .catch((error) => console.log(error));
    }
  }, [connection]);
  return <></>;
};

const cleanDates = (item: IRawOrderTableData): IOrderTableData => {
  return {
    ...item,
    applicationDate: item.applicationDate ? new Date(addZeros(item.applicationDate)) : null,
    contractDate: item.contractDate ? new Date(addZeros(item.contractDate)) : null,
    contactAttempt: item.contactAttempt ? new Date(addZeros(item.contactAttempt)) : null,
    modified: item.modified ? new Date(addZeros(item.modified)) : null,
    wishedInstallationMonth: item.wishedInstallationMonth
      ? new Date(new Date(addZeros(item.wishedInstallationMonth)))
      : null,
    comments: item.comments.map(
      (comment) =>
        ({
          ...comment,
          created: new Date(addZeros(comment.created))
        } as IComment)
    ),
    installationData: {
      ...item.installationData,
      customerReminderDate: item.installationData.customerReminderDate
        ? new Date(addZeros(item.installationData.customerReminderDate.toString()))
        : null
    }
  } as IOrderTableData;
};

const addZeros = (date: string): string => {
  let dateWithZeros = date;
  if (!date.includes('+00:00')) {
    dateWithZeros += '+00:00';
  }
  return dateWithZeros;
};
