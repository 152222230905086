export const validateInput = (
  inputType: InputType,
  value: any,
  regex?: string,
  allowEmpty?: boolean
) => {
  if (allowEmpty && (value === null || value === undefined || value === '')) {
    return true;
  }

  if (inputType === InputType.Text) {
    return value.length > 0;
  }
  if (inputType === InputType.Name) {
    let name = value.toString().trimEnd();
    if (!containsTwoWords(name)) {
      return false;
    }

    return /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒæÆČŠŽ∂ð ,.'-]+$/.test(
      name
    );
  }
  if (inputType === InputType.Phone) {
    return /^\d{8,50}$/.test(value);
  }
  if (inputType === InputType.Email) {
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value);
  }
  if (inputType === InputType.CPR) {
    return /^\d{10}$|^\d{6}-\d{4}$|^\d{6}\s\d{4}$/.test(value);
  }
  if (inputType === InputType.Kroner) {
    return /^[0-9,]+$/.test(value);
  }
  if (inputType === InputType.EVB) {
    return /^EVB-P+\d{8}$|^$/.test(value);
  }
  if (inputType === InputType.Regex && regex) {
    return new RegExp(regex).test(value);
  }
  if (inputType === InputType.Numbers) {
    return /^\d+$/.test(value);
  }
  return true;
};

const containsTwoWords = (value: string): boolean => {
  let trimmedValue = value.split(/\s+/g);
  return trimmedValue.length > 1 && trimmedValue.every((val) => val.length > 0);
};

export enum InputType {
  None,
  Text,
  Name,
  Phone,
  Email,
  CPR,
  Kroner,
  EVB,
  Regex,
  Numbers
}

export interface IValidation {
  type: InputType;
  validationErrorMessage: string;
  validationStatus?(valid: boolean): void;
  regex?: string;
}
