import { Box, Typography } from '@mui/material';
import MessageService from '../../../../data-access/MessageService';
import OrderService from '../../../../data-access/OrderService';
import InputWithCopy from '../../../../ui/InputWithCopy/InputWithCopy';
import { OrderState } from '../../../../util/Enums/OrderState';
import DawaSuggestionFeature from '../../../DawaSuggestion/DawaSuggestion';
import { FieldSettings } from '../OrderInfoBoxTabs/CustomerTab';
import { InputType } from '../../../../util/Helpers/InputValidationHelper';

export function getNameField(
  isValidName: boolean,
  setIsValidName: React.Dispatch<React.SetStateAction<boolean>>,
  fieldSettings: FieldSettings
) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;
  return (
    <Box sx={{ marginBottom: '15px' }}>
      <Typography fontWeight={'bold'}>Navn</Typography>
      {canEdit ? (
        <InputWithCopy
          onChange={(e) => {
            setOrder({
              ...order,
              customer: { ...order.customer, name: e },
              state: OrderState.DIRTY
            });
          }}
          validation={{
            type: InputType.Name,
            validationErrorMessage: 'Navn skal udfyldes',
            validationStatus: (valid: boolean) => {
              setIsValidName(valid);
            }
          }}
          fullWidth={true}
          value={order.customer.name}
          showValidationError={!isValidName}
          dataTestId={'customer-name'}
          validateBeforeTouch={true}
        />
      ) : (
        <Typography color={textColor} data-testid={'customer-name'}>
          {order.customer.name}
        </Typography>
      )}
    </Box>
  );
}

export function getSecondaryNameField(
  isValidSecondaryName: boolean,
  setIsValidSecondaryName: React.Dispatch<React.SetStateAction<boolean>>,
  fieldSettings: FieldSettings
) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;
  return (
    <Box sx={{ marginBottom: '15px' }}>
      <Typography fontWeight={'bold'}>Sekundært navn</Typography>
      {canEdit ? (
        <InputWithCopy
          onChange={(e) => {
            setOrder({
              ...order,
              customer: { ...order.customer, secondaryName: e },
              state: OrderState.DIRTY
            });
          }}
          validation={{
            type: InputType.Name,
            validationErrorMessage: 'Mindst to navne, kun bogstaver',
            validationStatus: (valid: boolean) => {
              setIsValidSecondaryName(valid);
            }
          }}
          allowEmpty={true}
          fullWidth={true}
          value={order.customer.secondaryName}
          showValidationError={!isValidSecondaryName}
          dataTestId={'secondary-customer-name'}
          validateBeforeTouch={true}
        />
      ) : (
        <Typography color={textColor} data-testid={'secondary-customer-name'}>
          {order.customer.secondaryName}
        </Typography>
      )}
    </Box>
  );
}

const orderExists = async (addressId: string, address: string) => {
  let result = await OrderService.orderExist(addressId);
  if (result) {
    MessageService.showDialog({
      title: 'Aktiv ordre fundet',
      message: `En aktiv ordre eksisterer allerede på adressen: ${address}.`
    });
  }
};

export function getInstallationAddressField(
  isValidInstallationAddress: boolean,
  setIsValidInstallationAddress: React.Dispatch<React.SetStateAction<boolean>>,
  fieldSettings: FieldSettings
) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;
  return (
    <Box sx={{ marginBottom: '15px' }}>
      <Typography fontWeight={'bold'}>Adresse</Typography>
      {canEdit ? (
        <DawaSuggestionFeature
          fullWidth={true}
          value={order.installationAddress}
          suggestionPicked={(item) => {
            if (item.adresse.id !== order.installationAddressId) {
              orderExists(item.adresse.id, item.tekst);
              setIsValidInstallationAddress(true);
              setOrder({
                ...order,
                installationAddress: item.tekst,
                installationAddressId: item.adresse.id,
                state: OrderState.DIRTY
              });
            }
          }}
          validation={{
            type: InputType.Text,
            validationErrorMessage: 'Adresse skal være udfyldt',
            validationStatus: (valid: boolean) => {
              setIsValidInstallationAddress(valid);
            }
          }}
          onChange={() => {
            setIsValidInstallationAddress(false);
          }}
          showValidationError={!isValidInstallationAddress}
          dataTestId={'customer-address'}
        />
      ) : (
        <Typography color={textColor} data-testid={'customer-address'}>
          {order.installationAddress}
        </Typography>
      )}
    </Box>
  );
}

export function getEmailAddressField(
  setIsValidEmail: React.Dispatch<React.SetStateAction<boolean>>,
  fieldSettings: FieldSettings
) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;

  return (
    <Box sx={{ marginBottom: '15px' }}>
      <Typography fontWeight={'bold'}>Email</Typography>
      {canEdit ? (
        <InputWithCopy
          value={order.customer.email}
          validation={{
            type: InputType.Email,
            validationErrorMessage: 'Email skal være udfyldt',
            validationStatus: (valid: boolean) => {
              setIsValidEmail(valid);
            }
          }}
          onChange={(newEmail: string) => {
            setOrder({
              ...order,
              customer: { ...order.customer, email: newEmail },
              state: OrderState.DIRTY
            });
          }}
          fullWidth={true}
          dataTestId={'customer-email'}
          validateBeforeTouch={true}
        />
      ) : (
        <Typography color={textColor} data-testid={'customer-email'}>
          {order.customer.email}
        </Typography>
      )}
    </Box>
  );
}

export function getPhoneNumberField(
  setIsValidPhoneNumber: React.Dispatch<React.SetStateAction<boolean>>,
  fieldSettings: FieldSettings
) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;

  return (
    <Box sx={{ marginBottom: '15px' }}>
      <Typography fontWeight={'bold'}>Telefon</Typography>
      {canEdit ? (
        <InputWithCopy
          value={order.customer.phone}
          validation={{
            type: InputType.Phone,
            validationErrorMessage: 'Tlf. skal være udfyldt',
            validationStatus: (valid: boolean) => {
              setIsValidPhoneNumber(valid);
            }
          }}
          onChange={(newPhoneNumber: string) => {
            setOrder({
              ...order,
              customer: { ...order.customer, phone: newPhoneNumber },
              state: OrderState.DIRTY
            });
          }}
          fullWidth={true}
          dataTestId={'customer-phone'}
          validateBeforeTouch={true}
        />
      ) : (
        <Typography color={textColor} data-testid={'customer-phone'}>
          {order.customer.phone}
        </Typography>
      )}
    </Box>
  );
}

export function getCustomerNumberField(
  setIsValidCustomerNumber: React.Dispatch<React.SetStateAction<boolean>>,
  fieldSettings: FieldSettings
) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;

  return (
    <Box sx={{ marginBottom: '15px' }}>
      <Typography fontWeight={'bold'}>Kundenummer</Typography>
      {canEdit ? (
        <InputWithCopy
          value={order.customer.sonwinCustomerNumber}
          onChange={(customerNumber: string) => {
            setOrder({
              ...order,
              customer: { ...order.customer, sonwinCustomerNumber: customerNumber },
              state: OrderState.DIRTY
            });
          }}
          fullWidth={true}
          dataTestId={'customer-number'}
          validation={{
            type: InputType.Regex,
            regex: '(^[0-9]+$|^$|^s$)',
            validationErrorMessage: 'Kundenummer skal bestå af tal eller være tomt',
            validationStatus: (valid: boolean) => {
              setIsValidCustomerNumber(valid);
            }
          }}
        />
      ) : (
        <Typography color={textColor} data-testid={'customer-number'}>
          {order.customer.sonwinCustomerNumber || '-'}
        </Typography>
      )}
    </Box>
  );
}

export function getInstallationNumberField(
  setIsValidInstallationNumber: React.Dispatch<React.SetStateAction<boolean>>,
  fieldSettings: FieldSettings
) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;

  return (
    <Box sx={{ marginBottom: '15px' }}>
      <Typography fontWeight={'bold'}>Installationsnummer</Typography>
      {canEdit ? (
        <InputWithCopy
          value={order.installationNumber}
          validation={{
            type: InputType.Regex,
            regex: '^[0-9]{1,9}$|^$',
            validationErrorMessage: 'Minimum 1 ciffer, max 9 cifre',
            validationStatus: (valid: boolean) => {
              setIsValidInstallationNumber(valid);
            }
          }}
          onChange={(installationNumber: string) => {
            setOrder({
              ...order,
              installationNumber: installationNumber,
              state: OrderState.DIRTY
            });
          }}
          fullWidth={true}
          dataTestId={'installation-number'}
          validateBeforeTouch={true}
        />
      ) : (
        <Typography color={textColor} data-testid={'installation-number'}>
          {order.installationNumber || '-'}
        </Typography>
      )}
    </Box>
  );
}
