import axios from 'axios';
import {
  IOrderTableData,
  IRawOrderCreateData,
  IRawOrderTableData
} from '../util/Models/OrderTableDataModel';
import { msalInstance } from '../..';
import { loginRequest } from '../../authConfig';
import { IComment, IRawComment } from '../util/Models/CommentModel';
import { BFFUrl } from '../util/constants';
import { LockModel } from '../util/Models/LockModel';
import LoadingService from './LoadingService';
import { InstallationData } from '../util/Interfaces/IInstallationData';

const uri = `${BFFUrl}/api/v1`;
const orderApi = axios.create({
  baseURL: uri
});

orderApi.interceptors.request.use(async function (config) {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  });

  if (response.accessToken) {
    if (config.headers) {
      config.headers['Authorization'] = `Bearer ${response.accessToken}`;
    }
  }
  return config;
});

const useCleanData = (item: IRawOrderTableData): IOrderTableData => {
  return {
    ...item,
    applicationDate: item.applicationDate ? new Date(item.applicationDate) : null,
    contractDate: item.contractDate ? new Date(item.contractDate) : null,
    contactAttempt: item.contactAttempt ? new Date(item.contactAttempt) : null,
    modified: item.modified ? new Date(item.modified) : null,
    wishedInstallationMonth: item.wishedInstallationMonth
      ? new Date(item.wishedInstallationMonth)
      : null,
    comments: item.comments.map(
      (comment) =>
        ({
          ...comment,
          created: new Date(comment.created)
        } as IComment)
    ),
    dealerFunded: item.dealerFunded,
    rightOfCancellationWaived: item.rightOfCancellationWaived,
    anchor: item.anchor,
    chargingStand: item.chargingStand,
    installationData: item.installationData
      ? {
          ...item.installationData,
          customerReminderDate: item.installationData.customerReminderDate
            ? new Date(item.installationData.customerReminderDate)
            : null
        }
      : new InstallationData()
  } as IOrderTableData;
};

const useRawData = (item: IOrderTableData): IRawOrderTableData => {
  return {
    ...item,
    applicationDate: item.applicationDate
      ? new Date(item.applicationDate)?.toISOString()
      : item.applicationDate,
    contractDate: item.contractDate
      ? new Date(item.contractDate)?.toISOString()
      : item.contractDate,
    contactAttempt: item.contactAttempt
      ? new Date(item.contactAttempt)?.toISOString()
      : item.contactAttempt,
    modified: item.modified ? item.modified.toISOString() : new Date().toISOString,
    wishedInstallationMonth: item.wishedInstallationMonth
      ? new Date(item.wishedInstallationMonth)?.toISOString()
      : item.wishedInstallationMonth,
    comments: item.comments.map(
      (comment) =>
        ({
          ...comment,
          created: comment.created.toISOString()
        } as IRawComment)
    ),
    steps: item.steps,
    installationData: {
      ...item.installationData,
      customerReminderDate: item.installationData.customerReminderDate
        ? new Date(item.installationData.customerReminderDate)?.toISOString()
        : item.installationData.customerReminderDate
    }
  } as IRawOrderTableData;
};

const useRawCreateOrderData = (item: IOrderTableData): IRawOrderCreateData => {
  return {
    ...item,
    id: null,
    applicationDate: item.applicationDate?.toISOString(),
    contractDate: item.contractDate?.toISOString(),
    contactAttempt: item.contactAttempt?.toISOString(),
    modified: new Date().toISOString(),
    wishedInstallationMonth: item.wishedInstallationMonth?.toISOString(),
    comments: item.comments.map(
      (comment) =>
        ({
          ...comment,
          created: comment.created.toISOString()
        } as IRawComment)
    ),
    deliveryAddressId: item.deliveryAddressId.length === 0 ? null : item.deliveryAddressId
  } as IRawOrderCreateData;
};

const OrderService = {
  getOrders: async (
    completed: boolean = false,
    fasttrack: boolean = false,
    query?: string
  ): Promise<IOrderTableData[]> => {
    LoadingService.showLoader('Indlæser ordrer..');
    const res = await orderApi.get<IRawOrderTableData[]>(`/Orders`, {
      params: {
        completed,
        fasttrack,
        query
      }
    });
    const cleanData = Array<IOrderTableData>();
    res.data.forEach((item) => cleanData.push(useCleanData(item)));

    LoadingService.showLoader('');
    return cleanData;
  },
  putOrder: async (order: IOrderTableData): Promise<IOrderTableData | null> => {
    LoadingService.showLoader('Gemmer rettelser...');
    const res = await orderApi.put<IRawOrderTableData>('/Orders', useRawData(order));
    LoadingService.showLoader('');
    return useCleanData(res.data);
  },
  postOrder: async (order: IOrderTableData): Promise<IOrderTableData | null> => {
    LoadingService.showLoader('Opretter ordre...');
    const res = await orderApi.post<IRawOrderTableData>('/Orders', useRawCreateOrderData(order));
    LoadingService.showLoader('');
    return useCleanData(res.data);
  },
  lockOrder: async (orderId: string): Promise<any> => {
    let model = new LockModel();
    model.orderId = orderId;
    const res = await orderApi.post<any>('/Orders/Lock', model);
    return res;
  },
  unlockOrder: async (orderId: string): Promise<any> => {
    let model = new LockModel();
    model.orderId = orderId;
    const res = await orderApi.post<any>('/Orders/Unlock', model);
    return res;
  },
  getAllLocks: async (): Promise<any> => {
    const res = await orderApi.get<any>('/Orders/GetAllLocks');
    return res;
  },
  deleteOrder: async (orderId: string): Promise<IOrderTableData[]> => {
    LoadingService.showLoader('Sletter ordre...');
    const res = await orderApi.delete<IOrderTableData[]>(`/Orders?orderId=${orderId}`);
    LoadingService.showLoader('');
    return res.data;
  },
  orderExist: async (addressId: string): Promise<boolean> => {
    const res = await orderApi.get<any>(`/Orders/OrderExist?addressId=${addressId}`);
    return res.data;
  }
};

export default OrderService;
