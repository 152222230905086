export interface IRawComment {
  id: string;
  orderId: string;
  created: string;
  author: string;
  content: string;
}

export interface IComment extends Omit<IRawComment, 'created'> {
  created: Date;
}

//Er ikke sikker på hvad den her laver
export interface CreateComment {
  orderId: string;
  content: string;
}

export const createNewComment = (newContent: string, orderId: string, author: string): IComment => {
  const newComment: IComment = {
    id: '',
    orderId: orderId,
    created: new Date(),
    author: author,
    content: newContent
  };
  return newComment;
};
