import { IOrderTableData } from '../../util/Models/OrderTableDataModel';

export interface IState {
  orders: Array<IOrderTableData>;
}

export const initialState: IState = {
  orders: []
};

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export enum Types {
  Create = 'CREATE_NEW_ORDER',
  Get = 'GET_ORDERS',
  Edit = 'EDIT_ORDER',
  Cancel = 'CANCEL_ORDER'
}

type OrderPayload = {
  [Types.Create]: IOrderTableData;
  [Types.Get]: IOrderTableData[];
  [Types.Edit]: IOrderTableData;
  [Types.Cancel]: IOrderTableData;
};

export type OrderActions = ActionMap<OrderPayload>[keyof ActionMap<OrderPayload>];

export const orderReducer = (state: IState, action: OrderActions): IState => {
  switch (action.type) {
    case Types.Create:
      if (state.orders.some((o) => o.id == action.payload.id)) {
        return state;
      }
      return { ...state, orders: [action.payload, ...state.orders] };
    case Types.Get:
      return { ...state, orders: [...action.payload] };
    case Types.Edit:
      return {
        ...state,
        orders: state.orders.map((order: IOrderTableData) => {
          if (order.id === action.payload.id) {
            return action.payload;
          } else {
            return order;
          }
        })
      };
    case Types.Cancel:
      state.orders = state.orders.filter((item) => item.id !== action.payload.id);
      return {
        ...state,
        orders: state.orders.map((order: IOrderTableData) => {
          if (order.id === action.payload.id) {
            return action.payload;
          } else {
            return order;
          }
        })
      };
    default:
      return state;
  }
};
