import React, { useCallback, useEffect, useState } from 'react';
import { useOrderContext } from '../Libs/data-access/Context/OrderContext';
import { Types } from '../Libs/data-access/Context/OrderReducer';
import OrderService from '../Libs/data-access/OrderService';
import Admin from './Admin';
import { ReceiveOrder } from '../Libs/data-access/signalR/RecieveOrder';
import Navbar from '../Libs/ui/Navbar/Navbar';
import { Route, Routes } from 'react-router-dom';
import { IOrderTableData } from '../Libs/util/Models/OrderTableDataModel';
import { OrderState } from '../Libs/util/Enums/OrderState';
import { SnackAndDialog } from '../Libs/features/SnackAndDialog/SnackAndDialog';
import { OrderLock } from '../Libs/util/Models/OrderLock';
import { Dictionary } from '../Libs/util/Interfaces/IDictionary';
import { LoadingBackdrop } from '../Libs/features/LoadingBackdrop/LoadingBackdrop';
import { msalInstance } from '..';
import { NotFound } from './NotFound';
import Report from './Report';
import ConfigurationService from '../Libs/data-access/ConfigurationService';
import { IConfiguration } from '../Libs/util/Models/ConfigurationModel';
import ConfirmDeleteModal from '../Libs/features/DeleteModal/ConfirmDeleteModal';
import OrderPage from '../Libs/features/OrderPageN2/OrderPage';
import { IContractInformation } from '../Libs/util/Models/ContractModel';
import ConfigurationContext from '../Libs/data-access/Context/ConfigurationContext';
import Data from './Data';
import { useMsal } from '@azure/msal-react';
import ContractService from '../Libs/data-access/ContractService';
import PlugControl from './PlugControl';

const ContentContainer: React.FC = () => {
  const { state, dispatch } = useOrderContext();
  const [filteredOrders, setFilteredOrders] = useState<IOrderTableData[]>([]);
  const [orderLocks, setOrderLocks] = useState<OrderLock[]>([]);
  const [allLockFetched, setAllLocksFetched] = useState(false);
  const readOnly = !msalInstance.getActiveAccount()?.idTokenClaims?.roles?.includes('Role.Users');
  const [sorted, setSorted] = useState(false);
  const [configuration, setConfiguration] = useState<IConfiguration>();
  const [allContracts, setAllContracts] = useState<Array<IContractInformation> | null>(null);

  const [onlyFastTrackOrders, setOnlyFastTrackOrders] = useState(false);
  const [onlyCompletedOrders, setOnlyCompletedOrders] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const { accounts } = useMsal();

  const getData = React.useCallback(
    async (completed: boolean, fasttrack: boolean) => {
      const res = await OrderService.getOrders(completed, fasttrack);
      res.forEach((order) => {
        order.state = OrderState.SAVED;
      });
      dispatch({
        type: Types.Get,
        payload: res
      });
    },
    [dispatch]
  );

  useEffect(() => {
    getData(onlyCompletedOrders, onlyFastTrackOrders);
  }, [getData]);

  const handleGetOrders = React.useCallback(
    (e: Event) => {
      let completed = (e as CustomEvent).detail;
      if (completed == null) {
        getData(onlyCompletedOrders, onlyFastTrackOrders);
      } else {
        setOnlyCompletedOrders(completed);
        getData(completed, onlyFastTrackOrders);
      }
      setCurrentPage(0);
    },
    [getData]
  );

  useEffect(() => {
    window.addEventListener('getOrders', handleGetOrders);

    return () => {
      window.removeEventListener('getOrders', handleGetOrders);
    };
  }, [handleGetOrders]);

  useEffect(() => {
    let user = msalInstance.getAllAccounts()[0].username;

    filteredOrders.forEach((order, index) => {
      order.lockedBy = '';
      let target = orderLocks.find((lock) => lock.orderId === order.id);
      if (target) {
        order.lockedBy = target.user;
        if (user === target.user) {
          if (!sorted) {
            filteredOrders.splice(index, 1);
            filteredOrders.unshift(order);
          }
        }
      }

      if (index === filteredOrders.length - 1) {
        setSorted(true);
      }
    });

    dispatch({
      type: Types.Get,
      payload: state.orders
    });
  }, [orderLocks]);

  useEffect(() => {
    if (filteredOrders.length > 0 && !allLockFetched) {
      getAllLocks();
    }
  }, [filteredOrders]);

  const getConfiguration = useCallback(async () => {
    const res = await ConfigurationService.getConfiguration();
    setConfiguration(res);
  }, [setConfiguration]);

  useEffect(() => {
    getConfiguration();
  }, [getConfiguration]);

  const getContracts = React.useCallback(async () => {
    const res = await ContractService.getContractInformation();
    setAllContracts(res);
  }, [setAllContracts]);

  useEffect(() => {
    getContracts();
  }, [getContracts]);

  const getAllLocks = async () => {
    let locks = (await OrderService.getAllLocks()) as Dictionary<string>;

    setAllLocksFetched(true);

    if (locks.data) {
      let arr = Array<OrderLock>();
      Object.entries(locks.data).forEach((item) => {
        arr.push(new OrderLock(item[0], item[1]));
      });

      setOrderLocks(arr);
    }
  };

  const newOrderFromSignalR = (order: IOrderTableData) => {
    dispatch({
      type: Types.Create,
      payload: order
    });
  };

  const updateOrderFromSignalR = (_order: IOrderTableData) => {
    dispatch({
      type: Types.Edit,
      payload: _order
    });
  };

  const orderLockChange = (result: Array<OrderLock>) => {
    setOrderLocks([...orderLocks, ...result]);
  };

  const configurationUpdates = (config: IConfiguration) => {
    setConfiguration(config);
  };

  const orderDeleted = async (orders: IOrderTableData[]) => {
    let locks = (await OrderService.getAllLocks()) as Dictionary<string>;
    let lockArr = Array<OrderLock>();
    if (locks.data) {
      Object.entries(locks.data).forEach((item) => {
        lockArr.push(new OrderLock(item[0], item[1]));
      });
    }

    setOrderLocks(lockArr);

    orders.forEach((order) => {
      order.applicationDate = order.applicationDate ? new Date(order.applicationDate) : null;
      order.wishedInstallationMonth = order.wishedInstallationMonth
        ? new Date(order.wishedInstallationMonth)
        : null;
      order.modified = order.modified ? new Date(order.modified) : null;
      order.contactAttempt = order.contactAttempt ? new Date(order.contactAttempt) : null;
      order.lockedBy = '';

      let target = lockArr.find((lock) => lock.orderId === order.id);
      if (target) {
        order.lockedBy = target.user;
      }
    });

    dispatch({
      type: Types.Get,
      payload: orders
    });
  };

  const ConfirmDeleteModalCallback = useCallback(
    (item: IOrderTableData) => {
      setFilteredOrders(state.orders.filter((o) => o.id !== item.id));
    },
    [setFilteredOrders]
  );

  const isAdmin = () => {
    if (accounts.length === 0) return false;
    return accounts[0].idTokenClaims?.roles?.includes('Role.Admin');
  };

  const hasDataAccess = () => {
    if (accounts.length === 0) return false;
    return accounts[0].idTokenClaims?.roles?.includes('Role.DataAccess');
  };

  return (
    <>
      <SnackAndDialog />
      <LoadingBackdrop />
      <ConfirmDeleteModal callback={ConfirmDeleteModalCallback} />
      <div>
        <Navbar />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <ReceiveOrder
                  orderLockChange={orderLockChange}
                  newOrderCb={newOrderFromSignalR}
                  updateOrderCb={updateOrderFromSignalR}
                  configurationUpdates={configurationUpdates}
                  orderDeleted={orderDeleted}
                />
                <ConfigurationContext.Provider value={configuration}>
                  <OrderPage
                    filteredOrders={filteredOrders}
                    readOnly={readOnly}
                    configuration={configuration}
                    setFilteredOrders={setFilteredOrders}
                    allContracts={allContracts ? allContracts : []}
                    onlyFastTrackOrders={onlyFastTrackOrders}
                    setOnlyFastTrackOrders={setOnlyFastTrackOrders}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    orderLocks={orderLocks}
                  />
                </ConfigurationContext.Provider>
              </>
            }
          />
          <Route
            path="/admin"
            element={
              <Admin
                getConfiguration={getConfiguration}
                readOnly={readOnly}
                configuration={configuration}
              />
            }
          />
          <Route path="/report" element={<Report />} />
          {(isAdmin() || hasDataAccess()) && <Route path="/data" element={<Data />} />}
          {isAdmin() && <Route path="/plug" element={<PlugControl />} />}
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </div>
    </>
  );
};

export default ContentContainer;
