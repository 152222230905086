import { Input, InputLabel, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { IValidation, InputType, validateInput } from '../../util/Helpers/InputValidationHelper';

interface Props {
  value: string;
  isValid: boolean;
  canEdit: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  id: string;
  label: string;
  validation?: IValidation;
}

const FormInput = ({ ...props }: Props) => {
  const [touched, setTouched] = useState(false);
  const inputType = props.validation ? props.validation.type : InputType.None;
  const validationErrorMessage = props.validation?.validationErrorMessage;

  useEffect(() => {
    setTouched(false);
  }, [props.canEdit]);

  const checkValidation = (targetValue: string) => {
    if (props.validation?.validationStatus) {
      props.validation.validationStatus(
        validateInput(inputType, targetValue, props.validation?.regex)
      );
    }
  };

  return (
    <InputWrapper fullWidth={true}>
      <Typography fontSize={14} fontWeight={'bold'}>
        {props.label}
      </Typography>
      {props.canEdit ? (
        <>
          <Input
            id={props.id}
            type="text"
            value={props.value}
            onChange={(e) => {
              checkValidation(e.target.value);
              props.handleChange(e as ChangeEvent<HTMLInputElement>);
              setTouched(true);
            }}
            autoComplete="off"
            error={!props.isValid && touched}
            onBlur={(e) => {
              checkValidation(e.target.value);
              setTouched(true);
            }}
          />
          {!props.isValid && touched && (
            <InputLabel sx={{ alignSelf: 'start', fontSize: 10 }}>
              {validationErrorMessage}
            </InputLabel>
          )}
        </>
      ) : (
        <Typography color={'#858383'}>{props.value}</Typography>
      )}
    </InputWrapper>
  );
};

const InputWrapper = styled.div<{ fullWidth: boolean }>`
  display: flex;
  justify-content: left;
  padding-right: 1rem;
  ${(props) =>
    !props.fullWidth &&
    css`
      max-width: 200px;
    `}

  flex-direction: column;
`;

export default FormInput;
