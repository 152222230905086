import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  Box,
  Checkbox,
  Tooltip
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import React, { useCallback } from 'react';
import { IHeadCell, SortOrder } from '../../../util/Models/TableHeadModels';

interface OrderTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  sortOrder: SortOrder;
  orderBy: keyof any;
  headCells: IHeadCell[];
  onlyFastTrackOrders: boolean;
  setOnlyFastTrackOrders: React.Dispatch<React.SetStateAction<boolean>>;
}

const OrderTableHead = (props: OrderTableHeadProps) => {
  const { onlyFastTrackOrders, setOnlyFastTrackOrders } = props;

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    props.onRequestSort(event, property);
  };

  const handleChange = useCallback(
    () => setOnlyFastTrackOrders(!onlyFastTrackOrders),
    [onlyFastTrackOrders]
  );

  return (
    <TableHead>
      <TableRow sx={{ '& th': { fontWeight: 'bold' } }}>
        <TableCell>
          <Tooltip title={onlyFastTrackOrders ? 'Vis alle ordrer' : 'Vis kun fasttrack ordrer'}>
            <Checkbox onChange={handleChange} sx={{ marginLeft: '-9.5px' }} />
          </Tooltip>
        </TableCell>
        {props.headCells.map((headCell) => (
          <TableCell
            key={headCell.id.toString()}
            sortDirection={props.orderBy === headCell.id ? props.sortOrder : false}
          >
            <TableSortLabel
              tabIndex={-1}
              active={props.orderBy === headCell.id}
              direction={props.orderBy === headCell.id ? props.sortOrder : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {props.orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {props.sortOrder === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default OrderTableHead;
