import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import DataService from '../../../../../data-access/DataService';
import MessageService, { SnackbarSeverity } from '../../../../../data-access/MessageService';
import { ImageInfoModel } from '../../../../../util/Models/ImageInfoModel';
import ImagePreview from './ImagePreview';
import ImageThumbnail from './ImageThumbnail';
import DragFileElement from './DragFileElement';

interface IImageUpload {
  orderId: string;
  canEdit: boolean;
  setPicturesUploaded: React.Dispatch<React.SetStateAction<number>>;
}

const ImageUpload = ({ orderId, canEdit, setPicturesUploaded }: IImageUpload) => {
  const acceptedFileTypes = ['.jpg', '.jpeg', '.png', '.heic', '.JPG', '.JPEG', '.PNG', '.HEIC'];
  const [selectedUpload, setSelectedUpload] = useState<FormData | null>(null);
  const [images, setImages] = useState<Array<ImageInfoModel>>([]);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [imagePreviews, setImagePreviews] = useState<Array<File> | null>(null);

  useEffect(() => {
    let mounted = true;

    const getImages = async () => {
      let images = await DataService.getImages(orderId);
      if (mounted) {
        setImagesLoaded(true);
        setImages(images);
      }
    };

    getImages();

    return () => {
      mounted = false;
    };
  }, [orderId]);

  useEffect(() => {
    setPicturesUploaded(images.length);
  }, [images]);

  const validFilesPicked = (files: Array<string>): boolean => {
    let valid = true;
    files.forEach((file) => {
      let fileType = file.substring(file.lastIndexOf('.'), file.length);
      if (!acceptedFileTypes.includes(fileType)) {
        valid = false;
      }
    });
    return valid;
  };

  const handleFileChange = (e: any) => {
    let target = document.getElementById('fileUpload') as HTMLInputElement;
    if (target && target.files) {
      if (!validFilesPicked(Array.from(target?.files).map((item) => item.name))) {
        MessageService.showSnackbar({
          message: 'Ugyldig filtype valgt. Kun billedfiler kan uploades.',
          severity: SnackbarSeverity.WARNING
        });
        return;
      }

      if (!checkValidImageCount(target.files)) {
        MessageService.showSnackbar({
          message: 'Der kan max uploades 5 billeder',
          severity: SnackbarSeverity.WARNING
        });
        return;
      }

      let formData = new FormData();

      for (let file of target.files) {
        formData.append(file.name, file);
        formData.append('orderId', orderId);
      }

      setImagePreviews([...target.files]);
      setSelectedUpload(formData);
    }
    target.value = '';
  };

  const handleUpload = async () => {
    if (selectedUpload != null) {
      let result = await DataService.uploadImages(selectedUpload);
      if (result) {
        setImages(result);
        setImagePreviews(null);
        setSelectedUpload(null);
      }
    }
  };

  const checkValidImageCount = (files: FileList): boolean => {
    return files.length <= 5;
  };

  const clearUpload = () => {
    setSelectedUpload(null);
    setImagePreviews(null);
  };

  const handleDrag = (e: any): void => {
    if (!canEdit) return;
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = function (e: any) {
    if (!canEdit) return;
    e.preventDefault();
    e.stopPropagation();
    let target = document.getElementById('fileUpload') as HTMLInputElement;
    let newData = e.dataTransfer.files as FileList;
    if (target && newData) {
      target.files = newData;
      if (!validFilesPicked(Array.from(target.files).map((item: any) => item.name))) {
        MessageService.showSnackbar({
          message: 'Ugyldig filtype valgt. Kun billedfiler kan uploades.',
          severity: SnackbarSeverity.WARNING
        });
        return;
      }
      if (!checkValidImageCount(target.files)) {
        MessageService.showSnackbar({
          message: 'Der kan max uploades 5 billeder',
          severity: SnackbarSeverity.WARNING
        });
        return;
      }
      let formData = new FormData();

      for (let file of target.files) {
        formData.append(file.name, file);
        formData.append('orderId', orderId);
      }

      setImagePreviews([...target.files]);
      setSelectedUpload(formData);
    }
    target.value = '';
  };

  return (
    <ImageContainer onDragEnter={handleDrag}>
      <DragFileElement id="drag-file-element" onDrop={handleDrop} canEdit={canEdit}>
        {!imagesLoaded && (
          <SpinnerWrapper>
            <CircularProgress />
            <Typography>Henter billeder...</Typography>
          </SpinnerWrapper>
        )}
        <Box sx={{ width: '100%' }}>
          {images?.map((item) => {
            return item.orderId === orderId && item.name.includes('electricalPanelCloseUpImage') ? (
              <ImageThumbnail
                key={item.id}
                item={item}
                orderId={orderId}
                images={images}
                setImages={setImages}
                canEdit={canEdit}
                label="Foto af eltavle - tæt på"
              />
            ) : (
              <></>
            );
          })}
          {images?.map((item) => {
            return item.orderId === orderId && item.name.includes('electricalPanelAwayImage') ? (
              <ImageThumbnail
                key={item.id}
                item={item}
                orderId={orderId}
                images={images}
                setImages={setImages}
                canEdit={canEdit}
                label="Foto af eltavle - 1,5 meter væk"
              />
            ) : (
              <></>
            );
          })}
          {images?.map((item) => {
            return item.orderId === orderId && item.name.includes('chargerPlacementImage') ? (
              <ImageThumbnail
                key={item.id}
                item={item}
                orderId={orderId}
                images={images}
                setImages={setImages}
                canEdit={canEdit}
                label="Placering af ladestander"
              />
            ) : (
              <></>
            );
          })}
          {images
            ?.filter(
              (i) =>
                !i.name.includes('electricalPanelCloseUpImage') &&
                !i.name.includes('electricalPanelAwayImage') &&
                !i.name.includes('chargerPlacementImage')
            )
            .map((item) => {
              return item.orderId === orderId ? (
                <ImageThumbnail
                  key={item.id}
                  item={item}
                  orderId={orderId}
                  images={images}
                  setImages={setImages}
                  canEdit={canEdit}
                  label={item.name.split('_').length > 1 ? item.name.split('_')[1] : item.name}
                />
              ) : (
                <></>
              );
            })}
        </Box>
        <ImagePreview filelist={imagePreviews} />
      </DragFileElement>
      {imagesLoaded && (
        <>
          <Box>
            <a id="downloadFile"></a>
            {canEdit && (
              <Box mt={'20px'} display="flex" justifyContent="flex-end">
                <Button variant="outlined" component="label">
                  Vælg billeder
                  <input
                    onChange={(e) => {
                      handleFileChange(e);
                    }}
                    type="file"
                    multiple
                    hidden
                    accept=".jpg,.jpeg,.png,.heic"
                    id="fileUpload"
                  />
                </Button>
                <Button
                  variant="contained"
                  onClick={handleUpload}
                  disabled={!selectedUpload}
                  sx={{ marginLeft: '10px' }}
                >
                  upload
                </Button>
                {selectedUpload && (
                  <Button
                    color="error"
                    variant="outlined"
                    onClick={clearUpload}
                    sx={{ marginLeft: '10px' }}
                  >
                    ryd
                  </Button>
                )}
              </Box>
            )}
          </Box>
        </>
      )}
    </ImageContainer>
  );
};

const SpinnerWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    margin-left: 10px;
  }
`;

const ImageContainer = styled.form`
  margin-top: -30px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2rem;
  width: 100%;
`;

export default ImageUpload;
