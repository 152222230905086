import { Box, Checkbox, Typography, styled } from '@mui/material';
import { OrderState } from '../../../../util/Enums/OrderState';
import { StepStatus } from '../../../../util/Enums/StepStatusEnum';
import { IStep } from '../../../../util/Models/StepModel';
import AutomaticStepButton from '../../../AutomaticStep/AutomaticStepButton';
import { FieldSettings } from '../OrderInfoBoxTabs/CustomerTab';

interface Props {
  isValid: boolean;
  setIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  fieldSettings: FieldSettings;
}

export const OnboardingField: React.FC<Props> = (props) => {
  const { order, canEdit, setOrder, configuration } = props.fieldSettings;

  const handleCheckChanged = (currentValue: string, index: number) => {
    let newSteps = order.steps.map((step, _index) => {
      if (index === _index) {
        return {
          ...step,
          value: currentValue === 'true' ? 'false' : 'true',
          status: currentValue === 'true' ? StepStatus.NotStarted : StepStatus.Completed
        };
      } else {
        return step;
      }
    });
    setOrder({ ...order, steps: newSteps, state: OrderState.DIRTY });
  };

  function sortData(input: Array<IStep>): Array<IStep> {
    return input.sort((a, b) =>
      a.sequenceNumber > b.sequenceNumber ? 1 : b.sequenceNumber > a.sequenceNumber ? -1 : 0
    );
  }

  function isAutomatic(step: IStep) {
    return configuration.automaticSteps != null && configuration.automaticSteps.includes(step.name);
  }

  function shouldShowStartButton(step: IStep) {
    return (
      step.name === 'TopDkLead' ||
      step.name === 'startMailDispatched' ||
      step.name === 'sentToMonta' ||
      step.name === 'contractAttachment'
    );
  }

  function getStepComponent(step: IStep, index: number) {
    if (shouldShowStartButton(step) && isAutomatic(step)) {
      return (
        <AutomaticStepButton
          disabled={true}
          step={step}
          order={order}
          setOrder={setOrder}
          canEdit={canEdit}
        />
      );
    }

    return (
      <StyledBox>
        <Checkbox
          checked={step.value === 'true' || step.status === StepStatus.Completed}
          onChange={() => {
            handleCheckChanged(step.value, index);
          }}
          // disabled={!canEdit || isAutomatic(step)}
          disabled={true}
        />
        <Typography key={'Text' + step.name + index}>
          {step.name === 'picturesSentToAtea' ? 'Sendt til Atea' : step.label}
        </Typography>
      </StyledBox>
    );
  }

  return (
    <Box display={'flex'} flexDirection={'column'} marginTop={'10px'}>
      {sortData(order.steps).map((step, index) => (
        <Box key={'' + step + index}>
          {step.name != 'confirmedInstallationDate' && getStepComponent(step, index)}
        </Box>
      ))}
    </Box>
  );
};

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
