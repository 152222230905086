import { Card, Typography, CardContent, CardActions, Button } from '@mui/material';

export interface IError {
  error: {
    message: string;
    stack: string;
  };
  resetErrorBoundary: () => void;
}

export function Error(result: IError) {
  const WrapperStyles = () => {
    return {
      style: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    };
  };

  const ErrorStyles = () => {
    return {
      sx: {
        minHeight: '400px',
        minWidth: '600px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }
    };
  };

  return (
    <div {...WrapperStyles()}>
      <Card {...ErrorStyles()}>
        <Typography variant="h3" color="text.primary" fontWeight={'bold'}>
          En fejl opstod
        </Typography>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            <b>Fejlbesked:</b>
            <br /> <div>{result?.error?.message || 'Ukendt fejl'}</div>
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            onClick={() => {
              result.resetErrorBoundary();
            }}
            size="small"
          >
            Til forsiden
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
