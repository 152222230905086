import axios from 'axios';
import { msalInstance } from '../..';
import { loginRequest } from '../../authConfig';
import { BFFUrl } from '../util/constants';
import { generateCsvFile } from '../util/Generators/ReportCsvGenerator';
import { IReport } from '../util/Models/ReportModel';

const uri = `${BFFUrl}/api/v1`;
const reportApi = axios.create({
  baseURL: uri
});

reportApi.interceptors.request.use(async function (config) {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  });

  if (response.accessToken) {
    if (config.headers) {
      config.headers['Authorization'] = `Bearer ${response.accessToken}`;
    }
  }
  return config;
});

const ReportService = {
  getReport: async (from: string, to: string): Promise<IReport[]> => {
    const result = await reportApi.get<IReport[]>(`/Report`, {
      params: {
        from: from,
        to: to
      }
    });

    const reportData = Array<IReport>();

    if (result.data.length === 0) {
      throw new Error('No data for the given date range.');
    } else {
      result.data.forEach((item) => reportData.push(item));
      generateCsvFile(reportData, from, to);
      return reportData;
    }
  }
};

export default ReportService;
