import axios from 'axios';
import { msalInstance } from '../..';
import { loginRequest } from '../../authConfig';
import { BFFUrl } from '../util/constants';
import { IComment, CreateComment } from '../util/Models/CommentModel';

const uri = `${BFFUrl}/api/v1`;
const orderApi = axios.create({
  baseURL: uri
});

orderApi.interceptors.request.use(async function (config) {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  });

  if (response.accessToken) {
    if (config.headers) {
      config.headers['Authorization'] = `Bearer ${response.accessToken}`;
    }
  }
  return config;
});

const CommentService = {
  createComment: async (comment: CreateComment): Promise<IComment | null> => {
    const res = await orderApi.post<any>('/Comment', comment);
    if (res.data) {
      const comment: IComment = {
        id: res.data.id,
        content: res.data.content,
        created: new Date(res.data.created),
        orderId: res.data.orderId,
        author: res.data.author
      };
      return comment;
    }
    return null;
  },
  deleteComment: async (id: string): Promise<boolean> => {
    const res = await orderApi.delete<any>(`/Comment?commentId=${id}`);
    return res.status == 200;
  }
};

export default CommentService;
