import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  TextareaAutosize,
  Tooltip,
  Typography
} from '@mui/material';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IOrderTableData } from '../../util/Models/OrderTableDataModel';
import ImageUpload from '../OrderPageN2/OrderInfoBox/OrderInfoBoxFields/ImageUpload/ImageUpload';
import FormInput from './FormInput';
import StepService from '../../data-access/StepService';
import { StepStatus } from '../../util/Enums/StepStatusEnum';
import { InputType } from '../../util/Helpers/InputValidationHelper';
import { OrderState } from '../../util/Enums/OrderState';
import AutocompleteFormInput from './AutocompleteFormInput';
import DatahubService from '../../data-access/DatahubService';
import SelectWithCopy from '../../ui/InputWithCopy/SelectWithCopy';
import { OrderStatusEnum } from '../../util/Enums/OrderStatusEnum';
import { getChargingStandField } from '../OrderPageN2/OrderInfoBox/OrderInfoBoxFields/ChargingStandField';
import { getAnchorField } from '../OrderPageN2/OrderInfoBox/OrderInfoBoxFields/AnchorField';

interface InstallationDataFormProps {
  order: IOrderTableData;
  setOrder: React.Dispatch<React.SetStateAction<IOrderTableData>>;
  canEdit: boolean;
}

export const InstallationDataForm: React.FC<InstallationDataFormProps> = (props) => {
  const { canEdit, order, setOrder } = props;
  const [showButtonSuccesColor, setShowButtonSuccesColor] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stepError, setStepError] = useState(false);
  const [stepErrorText, setStepErrorText] = useState('');
  const [picturesUploaded, setPicturesUploaded] = useState(0);
  const [sendHasJustBeenPressed, setSendHasJustBeenPressed] = useState(false);

  const [customerTypeValid, setCustomerTypeValid] = useState(
    order.installationData.customerType.length > 0
  );
  const [montaInstallJobUrlValid, setMontaInstallJobUrlValid] = useState(
    order.montaInstallJobUrl?.length > 0 || selectedCustomerTypeAllowedForMonta()
  );
  const [meterNumberValid, setMeterNumberValid] = useState(
    order.installationData.meterNumber.length > 0
  );
  const [distanceFromPanelToChargerValid, setDistanceFromPanelToChargerValid] = useState(
    order.installationData.distanceFromPanelToCharger.length > 0
  );
  const [numberOfHolesToDrillValid, setNumberOfHolesToDrillValid] = useState(
    order.installationData.numberOfHolesToDrill.length > 0
  );
  const [constructionInformationValid, setConstructionInformationValid] = useState(true);
  const [validData, setValidData] = useState(false);

  const [meterNumbers, setMeterNumbers] = useState<Array<string>>([]);
  const [meterNumbersLoading, setMeterNumbersLoading] = useState(true);

  const textColor = '#858383';

  const customerTypes = ['Privat kunde', 'Firmabilist', 'Fasttrack', 'Elvi Erhverv'];

  const noConstructionText = 'Nej, der skal ikke graves';
  const customerConstructionText =
    'Ja, men jeg står selv for det, så der er gravet, inden teknikeren kommer.';
  const norlysConstructionText =
    'Ja, og jeg vil gerne have et tilbud fra Norlys på grave- og anlægsarbejdet.';

  useEffect(() => {
    setValidData(
      customerTypeValid &&
        meterNumberValid &&
        distanceFromPanelToChargerValid &&
        numberOfHolesToDrillValid &&
        constructionInformationValid &&
        picturesUploaded >= 3 &&
        montaInstallJobUrlValid
    );
  }, [
    customerTypeValid,
    meterNumberValid,
    distanceFromPanelToChargerValid,
    numberOfHolesToDrillValid,
    constructionInformationValid,
    picturesUploaded,
    montaInstallJobUrlValid
  ]);

  useEffect(() => {
    setCustomerTypeValid(order.installationData.customerType.length > 0);
    setMontaInstallJobUrlValid(
      order.montaInstallJobUrl?.length > 0 || selectedCustomerTypeAllowedForMonta()
    );
    setMeterNumberValid(order.installationData.meterNumber.length > 0);
    setDistanceFromPanelToChargerValid(
      order.installationData.distanceFromPanelToCharger.length > 0
    );
    setNumberOfHolesToDrillValid(order.installationData.numberOfHolesToDrill.length > 0);

    setMeterNumbers([]);
    setMeterNumbersLoading(true);
    getMeteringNumber();
  }, [order.id]);

  useEffect(() => {
    setMontaInstallJobUrlValid(
      order.montaInstallJobUrl?.length > 0 || selectedCustomerTypeAllowedForMonta()
    );
  }, [order.installationData?.customerType]);

  useEffect(() => {
    if (!order.installationData.construction) {
      setOrder({
        ...order,
        installationData: { ...order.installationData, constructionInformation: '' }
      });
      setConstructionInformationValid(true);
    } else {
      setConstructionInformationValid(order.installationData.constructionInformation.length > 0);
    }
  }, [order.installationData.construction]);

  function updateInstallationData(constructionValue: string) {
    setOrder({
      ...order,
      installationData: {
        ...order.installationData,
        construction: constructionValue != noConstructionText,
        constructionInformation: constructionValue,
        pavementConstruction:
          constructionValue == norlysConstructionText
            ? order.installationData.pavementConstruction
            : false
      }
    });
  }

  function selectedCustomerTypeAllowedForMonta() {
    return (
      order.installationData?.customerType === 'Elvi Erhverv' ||
      order.installationData?.customerType === 'Firmabilist'
    );
  }

  const getMeteringNumber = async () => {
    const meterNumbers = await DatahubService.searchByAddress(order.installationAddressId);
    setMeterNumbers(meterNumbers);
    setMeterNumbersLoading(false);
  };

  const handleStatusChange = (newStatus: StepStatus) => {
    setStepError(newStatus === StepStatus.Error);
    let newSteps = order.steps.map((s, _index) => {
      if ('picturesSentToAtea' === s.name) {
        return { ...s, status: newStatus };
      } else {
        return s;
      }
    });

    setOrder({ ...order, steps: newSteps });
  };

  const startAutomaticStep = async () => {
    handleStatusChange(StepStatus.Started);
    try {
      let res = await StepService.executeStepOnOrder(
        'picturesSentToAtea',
        order.id,
        new Array<string>()
      );
      setLoading(false);
      setSendHasJustBeenPressed(false);
      for (let step of res.steps) {
        if (step.name == 'picturesSentToAtea') {
          if (step.status == StepStatus.Completed) {
            setShowButtonSuccesColor(true);
            setTimeout(() => {
              setShowButtonSuccesColor(false);
            }, 2000);
          } else if (step.status === StepStatus.Error) {
            handleStatusChange(step.status ? step.status : StepStatus.NotStarted);
            setStepErrorText(step.statusMessage ? step.statusMessage : '');
          }
        }
      }
    } catch (error) {
      setSendHasJustBeenPressed(false);
      let errorMessage = '';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      handleStatusChange(StepStatus.Error);
      setStepErrorText(errorMessage);
    }
  };

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setOrder({
        ...order,
        installationData: { ...order.installationData, [event.target.id]: event.target.value },
        state: OrderState.DIRTY
      });
    },
    [order]
  );

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!validData) return;
    if (sendHasJustBeenPressed) return;
    setSendHasJustBeenPressed(true);
    setLoading(true);
    startAutomaticStep();
  };

  const getAteaButtonText = () => {
    return order.status === OrderStatusEnum['Sendt til Atea']
      ? 'Send data igen'
      : 'Send data til Atea';
  };

  const handleConstructionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateInstallationData((event.target as HTMLInputElement).value);
  };

  return (
    <Container>
      <Box display={'flex'}>
        <Typography fontWeight={'bold'} fontSize={'14px'}>
          Status:{' '}
        </Typography>
        <Typography ml={'5px'} fontSize={'14px'}>
          {order.status === OrderStatusEnum['Sendt til Atea']
            ? 'Sendt til Atea'
            : 'Ikke sendt til Atea'}
        </Typography>
      </Box>

      <Box>
        <FormInput
          id={'montaInstallJobUrl'}
          label={'Monta installationsjob url'}
          value={order.montaInstallJobUrl}
          handleChange={(e) => {
            setOrder({
              ...order,
              montaInstallJobUrl: e.target.value.toString().trimEnd(),
              state: OrderState.DIRTY
            });
          }}
          // canEdit={canEdit} // TODO: Uncomment this line when we are ready to send data to Atea
          canEdit={false} // TODO: Remove this line when we are ready to send data to Atea
          validation={{
            type: InputType.Text,
            validationErrorMessage: 'Skal være udfyldt!',
            validationStatus: (valid: boolean) => {
              setMontaInstallJobUrlValid(valid);
            }
          }}
          isValid={montaInstallJobUrlValid}
        />
      </Box>

      <Box>
        <SelectWithCopy
          touchedInitially={true}
          disabled={!canEdit}
          label="Kundetype"
          fullWidth={true}
          values={customerTypes}
          error={!customerTypes.includes(order.installationData?.customerType)}
          value={customerTypes.indexOf(order.installationData.customerType)}
          onChange={(e: SelectChangeEvent) => {
            setOrder({
              ...order,
              installationData: { ...order.installationData, customerType: e.target.value },
              state: OrderState.DIRTY
            });
            setCustomerTypeValid(true);
          }}
        />
        {!customerTypes.includes(order.installationData?.customerType) && canEdit && (
          <Typography fontSize={'10px'} color={'rgba(0, 0, 0, 0.6);'}>
            Kundetype skal være valgt
          </Typography>
        )}
      </Box>

      <Box>
        <AutocompleteFormInput
          loading={meterNumbersLoading}
          loadingText="Indlæser målernumre..."
          id={'meterNumber'}
          label={'Målernummer'}
          value={order.installationData.meterNumber}
          handleChange={(e) => {
            setOrder({
              ...order,
              installationData: { ...order.installationData, meterNumber: e },
              state: OrderState.DIRTY
            });
          }}
          canEdit={canEdit}
          validation={{
            type: InputType.Text,
            validationErrorMessage: 'Skal være udfyldt',
            validationStatus: (valid: boolean) => {
              setMeterNumberValid(valid);
            }
          }}
          isValid={meterNumberValid}
          meteringNumbers={meterNumbers}
          error={false}
          errorField={''}
        />
      </Box>

      <Box>
        <FormInput
          id={'distanceFromPanelToCharger'}
          label={'Antal meter kabel'}
          value={order.installationData.distanceFromPanelToCharger}
          handleChange={handleChange}
          canEdit={canEdit}
          validation={{
            type: InputType.Numbers,
            validationErrorMessage: 'Skal være udfyldt, kun med tal',
            validationStatus: (valid: boolean) => {
              setDistanceFromPanelToChargerValid(valid);
            }
          }}
          isValid={distanceFromPanelToChargerValid}
        />
      </Box>

      <Box>
        <FormInput
          id={'numberOfHolesToDrill'}
          label={'Antal huller der skal bores'}
          value={order.installationData.numberOfHolesToDrill}
          handleChange={handleChange}
          canEdit={canEdit}
          validation={{
            type: InputType.Numbers,
            validationErrorMessage: 'Skal være udfyldt, kun med tal',
            validationStatus: (valid: boolean) => {
              setNumberOfHolesToDrillValid(valid);
            }
          }}
          isValid={numberOfHolesToDrillValid}
        />
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} alignContent={'center'}>
        <Grid item xs={6}>
          {getChargingStandField({ ...props, textColor: textColor })}
        </Grid>
        <Grid item xs={6}>
          {getAnchorField({ ...props, textColor: textColor })}
        </Grid>
      </Box>

      <Box>
        <Typography fontSize={14} fontWeight={'bold'}>
          Grave- og anlægsarbejde
        </Typography>
        <Box>
          <FormControl>
            <RadioGroup
              aria-labelledby="construction-group-label"
              name="construction-group"
              value={order.installationData.constructionInformation}
              onChange={handleConstructionChange}
            >
              <FormControlLabel
                value={noConstructionText}
                control={<Radio />}
                label="Nej"
                disabled={!canEdit}
                checked={!order.installationData.construction}
              />
              <FormControlLabel
                value={customerConstructionText}
                control={<Radio />}
                label="Ja, kunden graver selv"
                disabled={!canEdit}
              />
              <FormControlLabel
                value={norlysConstructionText}
                control={<Radio />}
                label="Ja, Norlys skal sende et tilbud"
                disabled={!canEdit}
              />
            </RadioGroup>
          </FormControl>
        </Box>

        <Box>
          {order.installationData.constructionInformation === norlysConstructionText ? (
            <FormControlLabel
              label="Der skal graves i belægning"
              control={
                <Checkbox
                  id={'pavementConstruction'}
                  checked={order.installationData.pavementConstruction}
                  onClick={() =>
                    setOrder({
                      ...order,
                      installationData: {
                        ...order.installationData,
                        pavementConstruction: !order.installationData.pavementConstruction
                      }
                    })
                  }
                />
              }
              disabled={!canEdit}
            />
          ) : (
            ''
          )}
        </Box>
      </Box>

      <Box>
        {canEdit ? (
          <TextareaAutosize
            placeholder="Ekstra informationer..."
            minRows={3}
            maxLength={1000}
            value={order.installationData.extraInformation}
            onChange={(e) =>
              setOrder({
                ...order,
                installationData: { ...order.installationData, extraInformation: e.target.value }
              })
            }
            style={{ width: '100%' }}
          />
        ) : (
          <>
            <Typography fontSize={14} fontWeight={'bold'}>
              Ekstra information
            </Typography>
            <Typography color={'#858383'}>
              {order.installationData.extraInformation.length > 0
                ? order.installationData.extraInformation
                : '-'}
            </Typography>
          </>
        )}
      </Box>

      <Box display={'flex'} flexDirection={'column'} marginBottom={'-20px'}>
        <Typography fontSize={14} fontWeight={'bold'}>
          Billeder
        </Typography>
      </Box>

      <Divider color={'transparent'}></Divider>
      <ImageUpload orderId={order.id} canEdit={canEdit} setPicturesUploaded={setPicturesUploaded} />
      {stepError && canEdit && (
        <Typography color={'red'} fontSize={16} marginBottom={'-15px'}>
          {stepErrorText}
        </Typography>
      )}
      {canEdit && (
        <Tooltip
          title={
            order.state === OrderState.DIRTY
              ? 'Gem ordren (CTRL + S) før du kan benytte knappen'
              : ''
          }
        >
          <span style={{ width: '100%' }}>
            <CustomButton
              type="button"
              variant={loading ? 'outlined' : 'contained'}
              stepSuccess={showButtonSuccesColor}
              onClick={handleSubmit}
              disabled={!validData || order.state === OrderState.DIRTY || sendHasJustBeenPressed}
              fullWidth={true}
            >
              {showButtonSuccesColor ? 'Sendt' : loading ? 'Sender' : getAteaButtonText()}
            </CustomButton>
          </span>
        </Tooltip>
      )}
    </Container>
  );
};

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2rem;
  width: 100%;
`;

const CustomButton = styled(Button)<{ stepSuccess: boolean }>`
  max-height: '45px';
  text-align: 'center';
  background-color: ${(props) => (props.stepSuccess ? '#4BB543' : null)} !important;
  transition: background-color 1s ease-in-out;
`;

export default InstallationDataForm;
