import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import DataService from '../Libs/data-access/DataService';
import LoadingService from '../Libs/data-access/LoadingService';
import MessageService, { SnackbarSeverity } from '../Libs/data-access/MessageService';
import { FileData } from '../Libs/util/Models/FileData';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Box } from '@mui/system';
import Paper from '@mui/material/Paper';
import { useMsal } from '@azure/msal-react';

const Data: React.FC = () => {
  const [selectedUpload, setSelectedUpload] = useState<FormData | null>(null);
  const [selectedUploadTitle, setSelectedUploadTitle] = useState('');

  const acceptedFileTypes = ['.xlsx', '.xlsm'];

  const [data, setData] = useState<Array<FileData>>([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    DataService.getFileList(10).then((res) => {
      setData(res);
    });
  };

  const tableStyle = () => {
    return {
      maxWidth: '1000px'
    };
  };

  const getDateString = useCallback((date: Date) => {
    return new Intl.DateTimeFormat('da-DK', { dateStyle: 'medium', timeStyle: 'short' }).format(
      date
    );
  }, []);

  const handleFileChange = () => {
    let target = document.getElementById('fileUpload') as HTMLInputElement;
    if (target && target.files) {
      if (!validFilePicked(target.files[0].name)) {
        MessageService.showSnackbar({
          message: 'Ugyldig filtype valgt. Kun Excel filer kan uploades.',
          severity: SnackbarSeverity.WARNING
        });

        return;
      }

      setSelectedUploadTitle(target.files[0].name);

      let formData = new FormData();
      formData.append('file', target.files[0]);
      setSelectedUpload(formData);
    }
    target.value = '';
  };

  const validFilePicked = (file: string): boolean => {
    let fileType = file.substring(file.lastIndexOf('.'), file.length);
    return acceptedFileTypes.includes(fileType);
  };

  const handleUpload = async () => {
    try {
      if (selectedUpload) {
        let result = await DataService.uploadFile(selectedUpload);

        if (result) {
          setSelectedUploadTitle('');
          setSelectedUpload(null);
          MessageService.showSnackbar({
            message: 'Fil uploaded med succes',
            severity: SnackbarSeverity.SUCCESS
          });

          fetchData();
        } else {
          MessageService.showSnackbar({
            message: 'Fejl under upload',
            severity: SnackbarSeverity.ERROR
          });
        }
      }
    } catch (exception: any) {
      MessageService.showDialog({
        title: 'Fejl under upload af fil',
        message: exception.message || 'Ukendt fejl'
      });
      LoadingService.showLoader('');
    }
  };

  const handleDownload = (item: FileData) => {
    if (item.fileContent == null) return;

    let download = document.getElementById('downloadFile') as HTMLAnchorElement;
    let bom = new Uint8Array([0xef, 0xbb, 0xbf]); // Ensures that Excel can read æøå
    const blob = new Blob([bom, decode(item.fileContent)], {
      type: 'data:text/csv;charset=utf-8'
    });

    let url = window.URL.createObjectURL(blob);
    download.download = item.name;
    download.href = url;
    download.click();
  };

  function decode(base64: string) {
    const binaryString = window.atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    const decoder = new TextDecoder('utf-8');
    const decodedText = decoder.decode(bytes);

    return decodedText;
  }

  const clearUpload = () => {
    setSelectedUpload(null);
    setSelectedUploadTitle('');
  };

  return (
    <Box m={'30px'}>
      <Box mt={'50px'}>
        <a id="downloadFile"></a>
        <Button variant="outlined" component="label">
          Vælg Excel fil
          <input onChange={handleFileChange} type="file" hidden accept=".xlsx" id="fileUpload" />
        </Button>
        <Button
          variant="contained"
          onClick={handleUpload}
          disabled={!selectedUpload}
          sx={{ marginLeft: '10px' }}
        >
          upload
        </Button>
        {selectedUpload && (
          <Button
            color="error"
            variant="outlined"
            onClick={clearUpload}
            sx={{ marginLeft: '10px' }}
          >
            ryd
          </Button>
        )}
      </Box>
      {selectedUploadTitle?.length > 0 && (
        <Box m={'5px'}>
          <Typography>Valgt fil: {selectedUploadTitle}</Typography>
        </Box>
      )}
      <Box mt={'50px'}>
        <div>
          <TableContainer sx={{ ...tableStyle() }} component={Paper}>
            <Table size="small" aria-label="upload table">
              <TableHead>
                <TableRow>
                  <TableCell width={'50%'}>
                    <Typography fontWeight={'bold'} fontSize={'14px'}>
                      Navn
                    </Typography>
                  </TableCell>
                  <TableCell width={'20%'}>
                    <Typography fontWeight={'bold'} fontSize={'14px'}>
                      Upload dato
                    </Typography>
                  </TableCell>
                  <TableCell width={'20%'}>
                    <Typography fontWeight={'bold'} fontSize={'14px'}>
                      Oprettet af
                    </Typography>
                  </TableCell>
                  <TableCell width={'10%'}>
                    <Typography fontWeight={'bold'} fontSize={'14px'}>
                      Download
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell>
                      {row.createdOn ? getDateString(new Date(row.createdOn)) : ''}
                    </TableCell>
                    <TableCell>{row.user || '-'}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          handleDownload(row);
                        }}
                      >
                        <CloudDownloadIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Box>
    </Box>
  );
};

export default Data;
