import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { DatePicker } from '@mui/x-date-pickers';
import { IconButton, TextField, TextFieldProps, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import daLocale from 'date-fns/locale/da';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { formatDate } from '../../util/Formatters/FormatDate';

interface Props {
  value: Date | null;
  maxDate?: Date | null;
  minDate?: Date | null;
  readOnly?: boolean;
  onlyMonth?: boolean;
  monthselectedDate?: number;
  onChange: (value: Date | null) => void;
  error?: boolean;
  dataTestId?: string;
  label?: string;
  fullWidth?: boolean;
  showAsterisk?: boolean;
  disabled?: boolean;
}

const DateWithCopy: React.FC<Props> = (props) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(props.value);
  const [iconColor, setIconColor] = useState('black');
  const [hidden, setHidden] = useState(true);
  const { maxDate, minDate } = props;
  const [touched, setTouched] = useState(false);

  const copy = async () => {
    let dateString: string | null = '';

    if (props.onlyMonth && selectedDate) {
      dateString = new Date(selectedDate).toLocaleString('dk', { month: 'long' });
    } else if (selectedDate) {
      dateString = formatDate(selectedDate);
    }

    await navigator.clipboard.writeText(dateString ? dateString : '');

    setIconColor('green');
  };

  const handleChange = (newDate: Date | null) => {
    newDate?.setHours(12); // added to avoid sommer/winter UTC conversion
    setSelectedDate(newDate);
    props.onChange(newDate);
  };

  const onBlurHandler = useCallback(() => setTouched(true), []);

  function getRenderInputBody(params: TextFieldProps) {
    if (props.error == undefined) {
      return <TextField {...params} sx={{ width: props.fullWidth ? '100%' : '' }} />;
    } else
      return (
        <TextField
          {...params}
          error={touched ? props.error : undefined}
          sx={{ width: props.fullWidth ? '100%' : '' }}
        />
      );
  }

  return (
    <div style={{ width: props.fullWidth ? '100%' : '' }}>
      {props.label && (
        <>
          <Typography fontSize={'14px'} fontWeight={'bold'}>
            {props.label}{' '}
            {props.showAsterisk && (
              <span
                style={{
                  fontSize: '18px',
                  verticalAlign: 'sub',
                  position: 'absolute',
                  paddingLeft: '10px',
                  color: props.error && touched ? 'red' : ''
                }}
              >
                *
              </span>
            )}
          </Typography>
        </>
      )}
      <InputWrapper
        fullWidth={props.fullWidth || false}
        onMouseEnter={() => setHidden(false)}
        onMouseLeave={() => {
          setHidden(true);
          setIconColor('black');
        }}
        onBlur={onBlurHandler}
        data-testid={props.dataTestId}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={daLocale}>
          <DatePicker
            disabled={props.disabled}
            views={props.onlyMonth ? ['year', 'month'] : ['year', 'month', 'day']}
            value={selectedDate}
            mask=""
            onChange={(newDate) => {
              handleChange(newDate);
            }}
            renderInput={(params) => getRenderInputBody(params)}
            inputFormat={props.onlyMonth ? 'MMMM' : 'dd-MM-yyyy'}
            readOnly={props.readOnly}
            maxDate={maxDate ? maxDate : null}
            minDate={minDate ? minDate : null}
          />
        </LocalizationProvider>

        {!hidden && (
          <ButtonWrapper>
            <IconButton
              aria-label="copy content"
              onClick={copy}
              tabIndex={-1}
              data-testid={props.dataTestId + '-copy'}
            >
              <ContentCopyRoundedIcon sx={{ color: iconColor, fontSize: '1rem' }} />
            </IconButton>
          </ButtonWrapper>
        )}
      </InputWrapper>
    </div>
  );
};

const InputWrapper = styled.div<{ fullWidth: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-right: 1rem;
  ${(props) =>
    !props.fullWidth &&
    css`
      max-width: 200px;
    `}

  > .MuiTextField-root {
    > .MuiInputBase-root {
      max-height: 2.5rem;
      ${(props) => (props.fullWidth ? `width: 100%;` : `width: 200px;`)}
    }
  }
`;
const ButtonWrapper = styled.div`
  display: hidden !important;
  margin: 0.25rem -2rem 0 0;
`;

export default DateWithCopy;
