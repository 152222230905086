import { Box, styled } from '@mui/material';
import React, { FC, useState } from 'react';
interface DragFileElementProps {
  children: React.ReactNode;
  id: string;
  onDrop: (e: React.DragEvent) => void;
  canEdit: boolean;
}

const DragFileElement: FC<DragFileElementProps> = ({ children, id, onDrop, canEdit }) => {
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  const handleDrop = (e: React.DragEvent) => {
    setIsDraggingOver(false);
    onDrop(e);
  };

  return (
    <StyledBox
      id={id}
      onDragEnter={handleDragOver}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      isDraggingOver={isDraggingOver}
      canEdit={canEdit}
    >
      {children}
    </StyledBox>
  );
};

const StyledBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDraggingOver'
})<{
  isDraggingOver: boolean;
  canEdit: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  border-width: 2px;
  border-radius: 1rem;
  width: 100%;
  height: 100%;
  border-style: ${(props) => (props.canEdit ? 'dashed' : 'none')};
  border-color: ${(props) => (props.isDraggingOver ? '#3f51b5' : '#cbd5e1')};
  min-height: 5rem;
  margin-bottom: 1px;
`;

export default DragFileElement;
