import { useEffect, useState } from 'react';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import {
  Button,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  Badge,
  Typography
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import styled from 'styled-components';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { DateFilterElement, FilterDates, StatusFilterElement } from '../FilterTypes';
import { FilterStepModel } from '../../../util/Models/FilterStepModel';
import moment from 'moment';

interface IFilterButtonProps {
  addFilter: React.Dispatch<FilterStepModel | StatusFilterElement | DateFilterElement>;
  removeFilter: React.Dispatch<FilterStepModel | StatusFilterElement | DateFilterElement>;
  resetFilter: boolean;
}

const DateFilterButton = (props: IFilterButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [selectDate, setSelectDate] = useState<number | null>(-1);
  const [dateFiltElm, setDateFiltElm] = useState<DateFilterElement>();
  const [numberOfFilterApplied, setNumberOfFiltersApplied] = useState(0);

  const open = Boolean(anchorEl);

  const filterDates = Array<[FilterDates, string]>(
    ['applicationDate', 'Bestillingsdato'],
    ['contactAttempt', 'Kontaktforsøg'],
    ['modified', 'Ændringsdato'],
    ['wishedInstallationMonth', 'Ønsket installationsmåned'],
    ['confirmedInstallationDate', 'Installationsdato bekræftet']
  );

  useEffect(() => {
    if (dateFiltElm) {
      props.removeFilter(dateFiltElm);
      setNumberOfFiltersApplied(numberOfFilterApplied > 0 ? numberOfFilterApplied - 1 : 0);
    }
    if (selectDate != null && selectDate >= 0) {
      const tempElm = { date: filterDates[selectDate][0], fromDate: fromDate, toDate: toDate };
      setDateFiltElm(tempElm);
      props.addFilter(tempElm);
      setNumberOfFiltersApplied(numberOfFilterApplied + 1);
    }
    if (selectDate == -1) {
      setNumberOfFiltersApplied(0);
    }
  }, [fromDate, toDate, selectDate]);

  useEffect(() => {
    setFromDate(null);
    setToDate(null);
    setSelectDate(-1);
  }, [props.resetFilter]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (event: any) => {
    setSelectDate(event.target.value);
  };

  return (
    <>
      <StyledFilterButton>
        <Tooltip title="Tilføj dato filter">
          <Button
            color="primary"
            variant="text"
            onClick={handleClick}
            sx={{ height: '40px', minWidth: '110px' }}
          >
            <Typography>DATO</Typography>
            <Badge invisible={numberOfFilterApplied === 0} variant="dot" color="error">
              <CalendarTodayOutlinedIcon
                color="inherit"
                sx={{ fontSize: '1.3em', marginTop: '-2px', marginLeft: '4px' }}
              />
            </Badge>
          </Button>
        </Tooltip>
        <Menu
          id="datefilter-positioned-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <FormControl fullWidth={true} sx={{ padding: '10px' }}>
            <InputLabel id="select-datefield-label">Vælg datofelt</InputLabel>
            <Select
              labelId="select-datefield-label"
              label="Vælg datofelt"
              id="select-date-field"
              value={selectDate}
              onChange={handleSelect}
            >
              <MenuItem value={-1}>Vælg datofelt</MenuItem>
              {filterDates.map((k, i) => (
                <MenuItem key={i} value={i}>
                  {k[1]}
                </MenuItem>
              ))}
            </Select>
            <div style={{ marginTop: '20px' }}></div>
            <DesktopDatePicker
              label="Fra dato"
              inputFormat="dd/MM/yyyy"
              value={fromDate}
              onChange={setFromDate}
              renderInput={(params) => <TextField {...params} />}
            />
            <div style={{ marginTop: '20px' }}></div>
            <DesktopDatePicker
              label="Til dato"
              inputFormat="dd/MM/yyyy"
              value={toDate}
              onChange={(D: Date | null) =>
                setToDate(D != null ? moment(D).endOf('day').toDate() : null)
              }
              renderInput={(params) => <TextField {...params} />}
            />
            <Divider />
          </FormControl>
        </Menu>
      </StyledFilterButton>
    </>
  );
};

const StyledFilterButton = styled.span`
  margin: 0px 12px;
`;

export default DateFilterButton;
