import axios from 'axios';
import { BFFUrl } from '../util/constants';
import { msalInstance } from '../..';
import { loginRequest } from '../../authConfig';
import { DatahubAddressDto } from '../util/Models/DatahubAddressDto';

const uri = `${BFFUrl}/api/v1`;
const datahubApi = axios.create({
  baseURL: uri
  // baseURL: 'http://localhost:5217/api/v1/'
});

datahubApi.interceptors.request.use(async function (config) {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error(
      'No active account! Verify a user has been signed in and setActiveAccount has been called.'
    );
  }

  const response = await msalInstance.acquireTokenSilent({
    ...loginRequest,
    account: account
  });

  if (response.accessToken) {
    if (config.headers) {
      config.headers['Authorization'] = `Bearer ${response.accessToken}`;
    }
  }
  return config;
});

const DatahubService = {
  searchByAddress: async (addressId: string): Promise<Array<string>> => {
    const model = {
      AddressId: addressId
    };

    const res = await datahubApi.post(`/Datahub`, model);
    return res.data;
  }
};

export default DatahubService;
