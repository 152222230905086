import { Box, Button, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CommentService from '../../../data-access/CommentService';
import { useEditOrderContext } from '../../../data-access/Context/EditOrderContext';
import { useOrderContext } from '../../../data-access/Context/OrderContext';
import { OrderActions, Types } from '../../../data-access/Context/OrderReducer';
import LoadingService from '../../../data-access/LoadingService';
import MessageService, { SnackbarSeverity } from '../../../data-access/MessageService';
import OrderService from '../../../data-access/OrderService';
import { useSaveKeysDown } from '../../../hooks/useKeyDown';
import { OrderState } from '../../../util/Enums/OrderState';
import { IComment } from '../../../util/Models/CommentModel';
import { IOrderTableData } from '../../../util/Models/OrderTableDataModel';
import { AxiosError } from 'axios';

interface Props {
  order: IOrderTableData;
  setOrder: React.Dispatch<React.SetStateAction<IOrderTableData>>;
  isValidOrderInfoBox: boolean;
  close: () => void;
  lockedBy: string;
  username: string;
}

const OrderInfoBoxFooter: React.FC<Props> = (props) => {
  const { editingState, setEditingState, newComment } = useEditOrderContext();
  const [buttonText, setButtonText] = useState(editingState ? 'GEM' : 'REDIGER');
  const { dispatch } = useOrderContext();
  const [validSave, setEnableSave] = useState(false);
  const [lockedBySomeoneElse, setLockedBySomeoneElse] = useState(false);

  useEffect(() => {
    window.addEventListener('orderDeleted', () => {
      props.close();
      setEditingState(false);
    });
  }, []);

  useEffect(() => {
    setLockedBySomeoneElse(props.lockedBy?.length > 0 && props.lockedBy !== props.username);
  }, [props.lockedBy, props.username]);

  useEffect(() => {
    let validInfoBox = props.isValidOrderInfoBox && newComment.length == 0;
    setEnableSave(validInfoBox);
  }, [props.isValidOrderInfoBox, newComment]);

  useSaveKeysDown(() => {
    if (editingState && validSave) {
      saveOrder(props.order, dispatch, props.setOrder, props.username);
    }
  }, ['s', 'S']);

  const handleClick = () => {
    if (editingState) {
      saveOrder(props.order, dispatch, props.setOrder, props.username);
    }
    setEditingState(!editingState);
  };

  useEffect(() => {
    if (editingState) {
      setButtonText('GEM');
      OrderService.lockOrder(props.order.id);
      sessionStorage.setItem('activeOrder', props.order.id);
    } else {
      setButtonText('REDIGER');
      OrderService.unlockOrder(props.order.id);
      sessionStorage.removeItem('activeOrder');
    }
  }, [editingState]);

  const handleDelete = () => {
    let event = new CustomEvent('confirmDelete', { detail: props.order });
    window.dispatchEvent(event);
  };

  return (
    <Box sx={OrderInfoBoxFooterStyle}>
      <Box display={'flex'} flexDirection={'column'} sx={{ paddingLeft: '24px' }} flex={'1'}>
        <Typography fontWeight={'bold'} sx={{ marginLeft: '-1px' }}>
          {props.lockedBy?.length > 0 ? 'Redigeres af:' : 'Senest redigeret af:'}
        </Typography>
        <Typography>
          {props.lockedBy?.length > 0 ? props.lockedBy : props.order?.modifiedBy}
        </Typography>
      </Box>
      <Button
        sx={{ marginRight: '10px' }}
        onClick={handleDelete}
        variant="text"
        data-testid={'delete-order-button'}
        disabled={!editingState}
      >
        slet
      </Button>
      <Tooltip title={newComment.length > 0 ? 'Du har en ugemt kommentar' : ''}>
        <span>
          <Button
            variant={'contained'}
            onClick={handleClick}
            disabled={lockedBySomeoneElse || (editingState && !validSave)}
            sx={ButtonStyle}
            data-testid={'edit-order-button'}
          >
            {buttonText}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};

const saveOrder = async (
  order: IOrderTableData,
  dispatch: React.Dispatch<OrderActions>,
  setOrder: React.Dispatch<React.SetStateAction<IOrderTableData>>,
  username: string
) => {
  try {
    let res: IOrderTableData | null = null;
    if (order.id.length < 36) {
      order.comments.forEach((c) => {
        c.orderId = '00000000-0000-0000-0000-000000000000';
      });
      let comments = [...order.comments];
      order.comments = Array<IComment>();
      res = await OrderService.postOrder(order);

      if (res !== null) {
        res.comments = comments;

        dispatch({
          type: Types.Cancel,
          payload: order
        });

        dispatch({
          type: Types.Create,
          payload: res
        });

        comments.forEach((c) => {
          c.orderId = res!.id;
          CommentService.createComment(c);
        });

        MessageService.showSnackbar({
          message: 'Ordre gemt med succes',
          severity: SnackbarSeverity.SUCCESS
        });
      } else {
        MessageService.showSnackbar({
          message: 'Fejl opstod under oprettelse af ordre',
          severity: SnackbarSeverity.ERROR
        });
      }
    } else {
      let comments = [...order.comments];
      order.comments = Array<IComment>();
      res = await OrderService.putOrder(order);
      order.comments = comments;
      order.modified = new Date();
      order.modifiedBy = username;

      if (res) {
        setOrder(res);

        MessageService.showSnackbar({
          message: 'Rettelser gemt med succes',
          severity: SnackbarSeverity.SUCCESS
        });
      } else {
        MessageService.showSnackbar({
          message: 'Fejl opstod under redigering af ordre',
          severity: SnackbarSeverity.ERROR
        });
      }
    }
    if (res == null) {
      let error =
        `Something went wrong while saving order. Please correct the details below, if they look wrong: \n
    Name: ` +
        order.customer.name +
        `\n
    Mail: ` +
        order.customer.email +
        `\n
    Phone Number: ` +
        order.customer.phone +
        `\n
    Address: ` +
        order.installationAddress;
      MessageService.showDialog({ title: 'An error occurred', message: error });
    }

    setOrder({ ...order, state: OrderState.SAVED });
  } catch (e) {
    dispatch({
      type: Types.Cancel,
      payload: order
    });
    let errorTitle = 'En fejl opstod i forbindelse med opdatering af ordren';
    if (e instanceof AxiosError && e.response) {
      let errorMessage = e.response.data.includes('Order is newer in database.')
        ? 'Der er kommet opdateringer til ordren. Refresh din browser, inden du laver nye ændringer.'
        : e.response.data;
      showError(errorTitle, errorMessage);
    } else {
      showError(errorTitle, e);
    }
  }
};

const showError = (title: string, exception: any) => {
  if (typeof exception === 'string') {
    MessageService.showDialog({ title: title, message: exception });
  } else {
    MessageService.showDialog({ title: title, message: exception?.message || 'Ukendt fejl' });
  }
  LoadingService.showLoader('');
};

const OrderInfoBoxFooterStyle = {
  position: 'relative',
  display: 'flex',
  height: '90px',
  width: '100%',
  padding: '20px 20px 20px 20px',
  justifyContent: 'space-between',
  borderTop: 1,
  borderColor: 'lightgrey',
  alignItems: 'center'
};

const ButtonStyle = {
  minWidth: '100px',
  maxHeight: '45px',
  textAlign: 'center'
};

export default OrderInfoBoxFooter;
