import { Typography, IconButton, Box, Divider } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from 'styled-components';
import DataService from '../../../../../data-access/DataService';
import MessageService, { SnackbarSeverity } from '../../../../../data-access/MessageService';
import { ImageInfoModel } from '../../../../../util/Models/ImageInfoModel';

export interface IImageThumbnail {
  item: ImageInfoModel;
  orderId: string;
  images: Array<ImageInfoModel>;
  setImages: React.Dispatch<React.SetStateAction<ImageInfoModel[]>>;
  canEdit: boolean;
  label?: string;
}

const ImageThumbnail = ({ item, orderId, images, setImages, canEdit, label }: IImageThumbnail) => {
  const showImagePreview = (e: React.MouseEvent<HTMLImageElement>, image: ImageInfoModel) => {
    e.preventDefault();
    let event = new CustomEvent('imagePreview', { detail: image });
    window.dispatchEvent(event);
  };

  const showTimestamp = (date: string | Date) => {
    if (typeof date === 'string') {
      return new Intl.DateTimeFormat('da-DK', { dateStyle: 'medium', timeStyle: 'short' }).format(
        new Date(date)
      );
    } else {
      return new Intl.DateTimeFormat('da-DK', { dateStyle: 'medium', timeStyle: 'short' }).format(
        date
      );
    }
  };

  return (
    <>
      <ImageThumbnailWrapper onClick={(e) => e.preventDefault()}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <img
            src={'data:image/jpeg;base64, ' + item.base64Content}
            width={'100px'}
            onClick={(e) => showImagePreview(e, item)}
          />
          <Box>
            <Typography>{label ?? item.name}</Typography>
            <Typography fontSize={'12px'}>{showTimestamp(item.modified)}</Typography>
            {item.sentToAtea !== null && (
              <Typography fontSize={'12px'}>
                {'Sendt: ' + showTimestamp(item.sentToAtea)}
              </Typography>
            )}
          </Box>
        </Box>
        <IconButton
          title="Slet billede"
          onClick={async () => {
            let result = await DataService.deleteImage(orderId, item.id);
            if (result) {
              setImages([...images.filter((image) => image.id !== item.id)]);
              MessageService.showSnackbar({
                message: 'Billede slettet med succes',
                severity: SnackbarSeverity.SUCCESS
              });
            } else {
              MessageService.showSnackbar({
                message: 'Sletning af billede fejlede',
                severity: SnackbarSeverity.ERROR
              });
            }
          }}
        >
          {canEdit && <DeleteIcon />}
        </IconButton>
      </ImageThumbnailWrapper>
      <Divider />
    </>
  );
};

const ImageThumbnailWrapper = styled(Box)`
  margin: 10px;
  display: flex;
  align-items: center;
  div {
    flex: 1;
    margin-left: 10px;
  }

  img {
    cursor: pointer;
  }

  button {
    place-self: start;
  }
`;

export default ImageThumbnail;
