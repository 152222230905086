import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { IOrderTableData } from '../../../util/Models/OrderTableDataModel';
import { useEffect, useState } from 'react';
import { IConfiguration } from '../../../util/Models/ConfigurationModel';
import { CustomerTab } from './OrderInfoBoxTabs/CustomerTab';
import { OnboardingTab } from './OrderInfoBoxTabs/OnboardingTab';
import { IContractInformation } from '../../../util/Models/ContractModel';
import { ContractTab } from './OrderInfoBoxTabs/ContractTab';
import { InstallationDataTab } from './OrderInfoBoxTabs/InstallationDataTab';

interface Props {
  order: IOrderTableData;
  canEdit: boolean;
  setOrder: React.Dispatch<React.SetStateAction<IOrderTableData>>;
  setIsValid: (isValid: boolean) => void;
  configuration: IConfiguration;
  userName: string;
  allContracts: Array<IContractInformation>;
  isNewOrder: boolean;
}

const OrderInfoBoxContent: React.FC<Props> = (props) => {
  const { order, canEdit, configuration, userName, allContracts } = props;
  const [isValidCustomerTab, setIsValidCustomerTab] = useState(true);
  const [isValidOnboardingTab, setIsValidOnboardingTab] = useState(true);
  const [isValidContractTab, setIsValidContractTab] = useState(true);

  const [value, setValue] = useState('1');
  const secondaryTextColor = '#858383';

  useEffect(() => {
    if (props.isNewOrder) {
      setValue('2');
    }
  }, []);

  useEffect(() => {
    props.setIsValid(isValidCustomerTab && isValidOnboardingTab && isValidContractTab);
  }, [isValidCustomerTab, isValidOnboardingTab, isValidContractTab]);

  function getTabList(setValue: React.Dispatch<React.SetStateAction<string>>) {
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };

    return (
      <Box sx={{ marginLeft: '25px', marginBottom: '20px' }}>
        <TabList onChange={handleChange} aria-label="Order tabs" sx={{ fontWeight: 'bold' }}>
          <Tab label="Onboarding" value="1" sx={TabHeaderStyle} data-testid={'onboarding-tab'} />
          <Tab label="Kunde" value="2" sx={TabHeaderStyle} data-testid={'customer-tab'} />
          <Tab
            label="Installationsdata"
            value="3"
            sx={TabHeaderStyle}
            data-testid={'installationdata-tab'}
          />
          <Tab label="Kontrakt" value="4" sx={TabHeaderStyle} data-testid={'contract-tab'} />
        </TabList>
      </Box>
    );
  }

  return (
    <Box sx={OrderInfoBoxContentStyle}>
      <TabContext value={value}>
        {getTabList(setValue)}
        <Box sx={{ overflowY: 'auto', overflowWrap: 'anywhere' }}>
          <TabPanel value="1" sx={TabPanelStyle}>
            <OnboardingTab
              order={order}
              canEdit={canEdit}
              setOrder={props.setOrder}
              setIsValid={setIsValidOnboardingTab}
              configuration={configuration}
              userName={userName}
              secondaryTextColor={secondaryTextColor}
            />
          </TabPanel>
          <TabPanel value="2" sx={TabPanelStyle}>
            <CustomerTab
              order={order}
              canEdit={canEdit}
              setOrder={props.setOrder}
              setIsValid={setIsValidCustomerTab}
              configuration={configuration}
              userName={userName}
              secondaryTextColor={secondaryTextColor}
            />
          </TabPanel>
          <TabPanel value="3" sx={TabPanelStyle}>
            <InstallationDataTab canEdit={canEdit} order={order} setOrder={props.setOrder} />
          </TabPanel>
          <TabPanel value="4" sx={TabPanelStyle}>
            <ContractTab
              order={order}
              canEdit={canEdit}
              setOrder={props.setOrder}
              setIsValid={setIsValidContractTab}
              configuration={configuration}
              userName={userName}
              secondaryTextColor={secondaryTextColor}
              allContracts={allContracts}
            />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
};

export default OrderInfoBoxContent;

const OrderInfoBoxContentStyle = {
  display: 'flex',
  flexDirection: 'column',
  margingBottom: '20px',
  width: '100%',
  minHeight: '300px',
  typography: 'body1',
  padding: '20px',
  flexGrow: 1,
  marginLeft: '-4px'
};
const TabHeaderStyle = {
  fontWeight: 'bold'
};

const TabPanelStyle = {
  padding: '0px 24px'
};
