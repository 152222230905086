import { Box, Button } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { IState } from '../../data-access/Context/OrderReducer';
import FilterWrap from '../../ui/Filter/Filter';
import { OrderState } from '../../util/Enums/OrderState';
import { IConfiguration } from '../../util/Models/ConfigurationModel';
import { IOrderTableData } from '../../util/Models/OrderTableDataModel';
import { CreateOrder } from '../CreateOrder/CreateOrder';
import Searchbar from '../Searchbar/Searchbar';

interface Props {
  state: IState;
  readOnly: boolean;
  setFilteredOrders: React.Dispatch<React.SetStateAction<IOrderTableData[]>>;
  configuration?: IConfiguration;
  setNewOrderToDisplay: React.Dispatch<React.SetStateAction<IOrderTableData | null>>;
  setIsNewOrder: React.Dispatch<React.SetStateAction<boolean>>;
  onlyFastTrackOrders: boolean;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const OrderPageHeader: React.FC<Props> = (props) => {
  const { state, readOnly, setFilteredOrders } = props;
  const [resetFilter, setResetFilter] = useState(true);
  const [showCreateOrderModal, setShowCreateOrderModal] = useState<boolean>(false);

  return (
    <>
      <CreateOrder
        showOrder={showCreateOrderModal}
        setShowOrder={setShowCreateOrderModal}
        setNewOrderToDisplay={props.setNewOrderToDisplay}
        setIsNewOrder={props.setIsNewOrder}
      ></CreateOrder>
      <Box sx={OrderPageHeaderStyle}>
        <Searchbar
          onlyFastTrackOrders={props.onlyFastTrackOrders}
          setCurrentPage={props.setCurrentPage}
        />
        <FilterWrap
          state={state}
          setFilteredOrders={setFilteredOrders}
          resetFilter={resetFilter}
          setCurrentPage={props.setCurrentPage}
        />
        {readOnly ? null : (
          <Button
            disabled={state.orders.some((o) => o.state === OrderState.NEW)}
            color="primary"
            variant="contained"
            sx={{ height: '45px' }}
            onClick={() => {
              setShowCreateOrderModal(true);
              setResetFilter(!resetFilter);
            }}
            data-testid="create-new-order"
          >
            + Ny Ordre
          </Button>
        )}
      </Box>
    </>
  );
};

const OrderPageHeaderStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: '16px 0',
  margin: '0 0 16px 16px'
};

export default OrderPageHeader;
