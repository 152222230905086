import { Box, Button, IconButton, TextField, Typography, styled } from '@mui/material';
import { PlugIntervalModel } from '../Libs/util/Models/IntervalModel';
import { useEffect, useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import daLocale from 'date-fns/locale/da';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DeleteIcon from '@mui/icons-material/Delete';
import PlugService from '../Libs/data-access/PlugService';

const PlugControl: React.FC = () => {
  const [isPlugActive, setIsPlugActive] = useState(false);
  const [showPlugQuestion, setShowPlugQuestion] = useState(true);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [intervals, setIntervals] = useState<PlugIntervalModel[]>([]);
  const [showSave, setShowSave] = useState(false);
  const [showSavedSuccesfully, setShowSavedSuccesfully] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const dbIntervals = await PlugService.getPlugs();
      setIntervals(dbIntervals.filter((i) => new Date(i.to) > new Date()));

      const isActive = await PlugService.isPlugActive();
      setIsPlugActive(isActive);
    };

    fetchData();
  }, []);

  const handleSave = async () => {
    await PlugService.setPlugs(intervals);

    setShowSave(false);
    setShowSavedSuccesfully(true);
    setTimeout(() => {
      setShowSavedSuccesfully(false);
    }, 2000);
  };

  const handlePlugQuestion = async () => {
    const isActive = await PlugService.isPlugActive();
    setIsPlugActive(isActive);

    setShowPlugQuestion(false);
    setTimeout(() => {
      setShowPlugQuestion(true);
    }, 2000);
  };

  const handleAddInterval = (newInterval: PlugIntervalModel) => {
    const newArray = [...intervals, newInterval];
    newArray.sort((a, b) => {
      return new Date(b.from) > new Date(a.from) ? -1 : 1;
    });
    setIntervals(newArray);
    setFromDate(null);
    setToDate(null);
    setShowSave(true);
  };

  const handleDeleteInterval = (index: number) => {
    setIntervals([...intervals].filter((_, i) => i !== index));
    setShowSave(true);
  };

  function getTimeString(time: string) {
    return (
      '' +
      new Date(time).toLocaleDateString() +
      ' kl. ' +
      new Date(time).toLocaleTimeString('da-DK', {
        hour: '2-digit',
        minute: '2-digit'
      })
    );
  }
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={daLocale}>
        <CenterContent>
          <PlugContainer>
            <Typography variant="h4" sx={{ marginBottom: '2rem' }}>
              Planlagte propper
            </Typography>
            <BorderedBox>
              {intervals.map((interval, index) => {
                return (
                  <IntervalLine key={interval.from + index} mt={'1rem'}>
                    <Typography width={'18rem'}>{'Fra ' + getTimeString(interval.from)}</Typography>
                    <Typography width={'11rem'}>{'Til ' + getTimeString(interval.to)}</Typography>
                    <IconButton
                      onClick={() => handleDeleteInterval(index)}
                      aria-label="delete"
                      sx={{ alignSelf: 'flex-end' }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </IntervalLine>
                );
              })}
            </BorderedBox>
            <DateBox>
              <DateTimePicker
                label="Fra"
                value={fromDate}
                onChange={setFromDate}
                renderInput={(params) => <TextField {...params} />}
                minDateTime={new Date(new Date().getTime() - 10 * 60000)} // 10 minutes ago
              />
              <Box sx={{ marginLeft: '2rem' }}>
                <DateTimePicker
                  label="Til"
                  defaultCalendarMonth={fromDate ?? undefined}
                  value={toDate}
                  onChange={setToDate}
                  renderInput={(params) => <TextField {...params} />}
                  minDateTime={fromDate ?? new Date()}
                  disabled={fromDate == null}
                />
              </Box>
            </DateBox>
            <Button
              onClick={() =>
                handleAddInterval({
                  from: fromDate!.toISOString(),
                  to: toDate!.toISOString()
                })
              }
              disabled={fromDate == null || toDate == null}
              sx={{ marginTop: '1rem' }}
            >
              Tilføj interval
            </Button>
            {showSave && (
              <Button
                onClick={() => handleSave()}
                disabled={fromDate != null || toDate != null}
                variant="contained"
                sx={{ marginTop: '1rem' }}
              >
                Save
              </Button>
            )}
            {showSavedSuccesfully && (
              <SavedSuccesfullyBox>
                <Typography variant="h6">Data er gemt</Typography>
              </SavedSuccesfullyBox>
            )}
            <CenterContent sx={{ marginTop: '4rem' }}>
              {showPlugQuestion ? (
                <Button onClick={() => handlePlugQuestion()}>Er der prop i nu?</Button>
              ) : (
                <Typography variant="h6" sx={{ marginBottom: '2rem' }}>
                  {isPlugActive ? 'Ja' : 'Nej'}
                </Typography>
              )}
            </CenterContent>
          </PlugContainer>
        </CenterContent>
      </LocalizationProvider>
    </>
  );
};

export default PlugControl;

const CenterContent = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const PlugContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
`;

const BorderedBox = styled(Box)`
  border: 1px dashed;
  border-color: grey;
  padding: 0 1rem 1rem 1rem;
`;

const IntervalLine = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DateBox = styled(Box)`
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
`;

const SavedSuccesfullyBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: green;
`;
