export interface IConfiguration {
  prices: Array<number>;
  salesChannels: Array<string>;
  partners: Array<string>;
  outbounds: Array<string>;
  companyCars: Array<string>;
  automaticSteps?: string;
}

export const createNewConfiguration = (
  prices: string[],
  salesChannels: string[],
  partners: string[],
  outbounds: string[],
  companyCars: string[]
): IConfiguration => {
  const newConfiguration: IConfiguration = {
    prices: prices.map((str) => Number(str)),
    salesChannels: salesChannels,
    partners: partners,
    outbounds: outbounds,
    companyCars: companyCars
  };
  return newConfiguration;
};
