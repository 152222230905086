import { Box, Checkbox, FormControlLabel, SelectChangeEvent, Typography } from '@mui/material';
import { FIRMABIL, OUTBOUND, PARTNERSKAB, TCC } from '../../../../assets/PreselectedValues';
import InputWithCopy from '../../../../ui/InputWithCopy/InputWithCopy';
import SelectWithCopy from '../../../../ui/InputWithCopy/SelectWithCopy';
import { EnergyCusomerStatusEnum } from '../../../../util/Enums/EnergyCustomerStatusEnum';
import { OrderState } from '../../../../util/Enums/OrderState';
import { OwnerTypeEnum } from '../../../../util/Enums/OwnerTypeEnum';
import { IConfiguration } from '../../../../util/Models/ConfigurationModel';
import { IOrderTableData } from '../../../../util/Models/OrderTableDataModel';
import { PriceService } from '../../../../util/Services/PriceService';
import DawaSuggestionFeature from '../../../DawaSuggestion/DawaSuggestion';
import { FieldSettings } from '../OrderInfoBoxTabs/CustomerTab';
import { InputType } from '../../../../util/Helpers/InputValidationHelper';

export function getPriceField(
  isValidPrice: boolean,
  setIsValidPrice: React.Dispatch<React.SetStateAction<boolean>>,
  fieldSettings: FieldSettings
) {
  const { order, canEdit, setOrder, textColor, configuration } = fieldSettings;

  const pricesAsStrings = [
    ...configuration.prices.map((num) => PriceService.numberToCurrency(num))
  ];
  if (
    order.price != null &&
    !pricesAsStrings.includes(PriceService.numberToCurrency(order.price))
  ) {
    pricesAsStrings.push(PriceService.numberToCurrency(order.price));
  }

  let showPriceWarningIcon =
    order.price != null ? !configuration.prices.includes(order.price) : true;

  return (
    <Box sx={{ position: 'relative' }} data-testid={'price'}>
      <Typography fontWeight={'bold'}>Pris</Typography>
      <Box display="flex" flexDirection="row">
        {canEdit ? (
          <>
            <SelectWithCopy
              fullWidth={true}
              values={pricesAsStrings}
              error={!isValidPrice}
              value={pricesAsStrings.indexOf(
                PriceService.numberToCurrency(order.price != null ? order.price : -1)
              )}
              onChange={(e: SelectChangeEvent) => {
                setOrder({
                  ...order,
                  price: PriceService.currencyToNumber(e.target.value),
                  state: OrderState.DIRTY
                });
                setIsValidPrice(true);
              }}
              dataTestId={'customer-price'}
              showWarningIcon={showPriceWarningIcon}
            />
          </>
        ) : (
          <Typography color={textColor}>
            {PriceService.numberToCurrency(Number(order.price))}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export function getEnergyCustomerField(
  isValidEnergyCustomer: boolean,
  setIsValidEnergyCustomer: React.Dispatch<React.SetStateAction<boolean>>,
  fieldSettings: FieldSettings
) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;

  return (
    <Box data-testid={'energy-customer'}>
      <Typography fontWeight={'bold'}>Norlys Energikunde</Typography>
      <Box display="flex" flexDirection="row">
        {canEdit ? (
          <SelectWithCopy
            fullWidth={true}
            values={Object.keys(EnergyCusomerStatusEnum).filter((v) => isNaN(Number(v)))}
            value={order.energyCustomer}
            error={!isValidEnergyCustomer}
            onChange={(e: SelectChangeEvent) => {
              const newEnum: EnergyCusomerStatusEnum =
                EnergyCusomerStatusEnum[e.target.value as keyof typeof EnergyCusomerStatusEnum];
              setOrder({
                ...order,
                energyCustomer: newEnum,
                state: OrderState.DIRTY
              });
              setIsValidEnergyCustomer(true);
            }}
            dataTestId={'customer-norlys-energy'}
          />
        ) : (
          <Typography color={textColor}>{EnergyCusomerStatusEnum[order.energyCustomer]}</Typography>
        )}
      </Box>
    </Box>
  );
}

export function getChannelField(
  isValidChannel: boolean,
  setIsValidChannel: React.Dispatch<React.SetStateAction<boolean>>,
  fieldSettings: FieldSettings,
  channelValues: string[]
) {
  const { order, canEdit, setOrder, textColor, configuration } = fieldSettings;
  let showChannelWarningIcon =
    order.channel == null || !configuration.salesChannels.includes(order.channel);

  function getChannelValue() {
    if (order.channel == null) {
      return -1;
    } else if (channelValues.includes(order.channel)) {
      return channelValues.indexOf(order.channel);
    } else {
      return -1;
    }
  }

  return (
    <Box data-testid={'channel'}>
      <Typography fontWeight={'bold'}>Kanal</Typography>
      <Box display="flex" flexDirection="row">
        {canEdit ? (
          <>
            <SelectWithCopy
              fullWidth={true}
              values={channelValues}
              value={getChannelValue()}
              error={!isValidChannel}
              onChange={(e: SelectChangeEvent) => {
                setOrder({
                  ...order,
                  channel: e.target.value,
                  partner: '',
                  state: OrderState.DIRTY
                });
                setIsValidChannel(true);
              }}
              dataTestId={'sales-channel'}
              showWarningIcon={showChannelWarningIcon}
            />
          </>
        ) : (
          <Typography color={textColor}>{order.channel}</Typography>
        )}
      </Box>
    </Box>
  );
}

function getPartnerText(order: IOrderTableData): string {
  if (order.channel == PARTNERSKAB) return 'Partnerskab';
  if (order.channel == OUTBOUND) return 'Outbound';
  if (order.channel == TCC) return 'TCC';
  if (order.channel == FIRMABIL) return 'Firmabil';
  return '';
}

function shouldShowWarningIcon(order: IOrderTableData, configuration: IConfiguration) {
  if (order.partner == null) return true;
  if (order.channel == PARTNERSKAB && !configuration.partners.includes(order.partner)) return true;
  if (order.channel == FIRMABIL && !configuration.companyCars.includes(order.partner)) return true;
  if (
    (order.channel == OUTBOUND || order.channel == TCC) &&
    !configuration.outbounds.includes(order.partner)
  )
    return true;
  return false;
}

function getPartnerArray(order: IOrderTableData, configuration: IConfiguration): Array<string> {
  const tempPartner = [...configuration.partners];
  if (order.partner != null && order.partner != '' && !tempPartner.includes(order.partner)) {
    tempPartner.push(order.partner);
  }
  return tempPartner;
}

function getOutboundArray(order: IOrderTableData, configuration: IConfiguration): Array<string> {
  const tempOutbound = [...configuration.outbounds];
  if (order.partner != null && order.partner != '' && !tempOutbound.includes(order.partner)) {
    tempOutbound.push(order.partner);
  }
  return tempOutbound;
}

function getCompanyCarArray(order: IOrderTableData, configuration: IConfiguration): Array<string> {
  const tempCompanyCars = [...configuration.companyCars];
  if (order.partner != null && order.partner != '' && !tempCompanyCars.includes(order.partner)) {
    tempCompanyCars.push(order.partner);
  }
  return tempCompanyCars;
}

export function getPartnerField(isValidPartner: boolean, fieldSettings: FieldSettings) {
  const { order, canEdit, setOrder, textColor, configuration } = fieldSettings;
  const showPartnerField =
    order.channel == PARTNERSKAB ||
    order.channel == OUTBOUND ||
    order.channel == TCC ||
    order.channel == FIRMABIL;

  let showWarningIcon = shouldShowWarningIcon(order, configuration);
  let partnerValues = getPartnerArray(order, configuration);
  let outboundValues = getOutboundArray(order, configuration);
  let companyCarValues = getCompanyCarArray(order, configuration);

  function getChannelValue(channelValues: string[]) {
    if (order.partner != null && channelValues.includes(order.partner)) {
      return channelValues.indexOf(order.partner);
    } else {
      return -1;
    }
  }

  return (
    <Box data-testid={'partner'}>
      {showPartnerField && (
        <>
          <Typography fontWeight={'bold'}>{getPartnerText(order)}</Typography>
          <Box display="flex" flexDirection="row">
            {canEdit ? (
              <>
                {order.channel == PARTNERSKAB && (
                  <SelectWithCopy
                    fullWidth={true}
                    values={partnerValues}
                    value={getChannelValue(partnerValues)}
                    error={!isValidPartner}
                    onChange={(e: SelectChangeEvent) => {
                      setOrder({ ...order, partner: e.target.value });
                    }}
                    dataTestId={'partner-channel'}
                    showWarningIcon={showWarningIcon}
                    touchedInitially={true}
                  />
                )}
                {order.channel == OUTBOUND && (
                  <SelectWithCopy
                    fullWidth={true}
                    values={outboundValues}
                    value={getChannelValue(outboundValues)}
                    error={!isValidPartner}
                    onChange={(e: SelectChangeEvent) => {
                      setOrder({ ...order, partner: e.target.value });
                    }}
                    dataTestId={'partner-channel'}
                    showWarningIcon={showWarningIcon}
                    touchedInitially={true}
                  />
                )}
                {order.channel == TCC && (
                  <SelectWithCopy
                    fullWidth={true}
                    values={outboundValues}
                    value={getChannelValue(outboundValues)}
                    error={!isValidPartner}
                    onChange={(e: SelectChangeEvent) => {
                      setOrder({ ...order, partner: e.target.value });
                    }}
                    dataTestId={'partner-channel'}
                    showWarningIcon={showWarningIcon}
                    touchedInitially={true}
                  />
                )}
                {order.channel == FIRMABIL && (
                  <SelectWithCopy
                    fullWidth={true}
                    values={companyCarValues}
                    value={getChannelValue(companyCarValues)}
                    error={!isValidPartner}
                    onChange={(e: SelectChangeEvent) => {
                      setOrder({ ...order, partner: e.target.value });
                    }}
                    dataTestId={'partner-channel'}
                    showWarningIcon={showWarningIcon}
                    touchedInitially={true}
                  />
                )}
              </>
            ) : (
              <Typography color={textColor}>{order.partner}</Typography>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

export function getContractTypeField(
  isValidOwnerType: boolean,
  setIsValidOwnerType: React.Dispatch<React.SetStateAction<boolean>>,
  fieldSettings: FieldSettings
) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;

  return (
    <Box data-testid={'contract-type'}>
      <Typography fontWeight={'bold'}>Kontrakttype</Typography>
      {canEdit ? (
        <SelectWithCopy
          fullWidth={true}
          values={Object.keys(OwnerTypeEnum).filter((v) => isNaN(Number(v)))}
          value={order.ownerType}
          error={!isValidOwnerType}
          onChange={(e: SelectChangeEvent) => {
            const newEnum: OwnerTypeEnum =
              OwnerTypeEnum[e.target.value as keyof typeof OwnerTypeEnum];
            setOrder({
              ...order,
              subscriptionContractId: '',
              subscriptionContractCompanyId: '',
              installationContractId: '',
              installationContractCompanyId: '',
              ownerType: newEnum,
              state: OrderState.DIRTY
            });
            setIsValidOwnerType(true);
          }}
          dataTestId={'contract-type'}
        />
      ) : (
        <Typography color={textColor}>
          {OwnerTypeEnum[order.ownerType].replace(/_/g, ' ')}
        </Typography>
      )}
    </Box>
  );
}

export function getDeleveryAddressField(
  isValidDeliveryAddress: boolean,
  setIsValidDeliveryAddress: React.Dispatch<React.SetStateAction<boolean>>,
  fieldSettings: FieldSettings
) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;

  return (
    <Box data-testid={'delivery-address'}>
      <Typography fontWeight={'bold'}>Alt. leveringsadresse</Typography>
      {canEdit ? (
        <div style={{ display: 'flex' }}>
          <Checkbox
            sx={{ marginLeft: '-12px' }}
            checked={order.separateDeliveryAddress}
            onChange={() => {
              setOrder({
                ...order,
                separateDeliveryAddress: !order.separateDeliveryAddress,
                deliveryAddress: '',
                deliveryAddressId: '00000000-0000-0000-0000-000000000000',
                state: OrderState.DIRTY
              });
            }}
            data-testid="alternative-delivery-address-checkbox"
          />

          <DawaSuggestionFeature
            disabled={!order.separateDeliveryAddress}
            value={order.deliveryAddress}
            suggestionPicked={(item) => {
              setIsValidDeliveryAddress(true);
              setOrder({
                ...order,
                deliveryAddress: item.tekst,
                deliveryAddressId: item.adresse.id,
                state: OrderState.DIRTY
              });
            }}
            validation={
              order.separateDeliveryAddress
                ? {
                    type: InputType.Text,
                    validationErrorMessage: 'Adresse skal være udfyldt',
                    validationStatus: (valid: boolean) => {
                      setIsValidDeliveryAddress(valid);
                    }
                  }
                : undefined
            }
            showValidationError={!isValidDeliveryAddress}
            onChange={() => {
              setIsValidDeliveryAddress(false);
            }}
            dataTestId={'alternative-delivery-address-select'}
          />
        </div>
      ) : (
        <Typography color={textColor}>
          {order.separateDeliveryAddress ? order.deliveryAddress : '-'}
        </Typography>
      )}
    </Box>
  );
}

export function getReferenceIdField(
  setIsValidReferenceId: React.Dispatch<React.SetStateAction<boolean>>,
  fieldSettings: FieldSettings
) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;

  return (
    <Box data-testid={'reference-id'}>
      <Typography fontWeight={'bold'}>Reference-ID</Typography>
      {canEdit ? (
        <InputWithCopy
          value={order.referenceId}
          validation={{
            type: InputType.Regex,
            regex: '^[a-zA-Z0-9]{0,30}$|^$',
            validationErrorMessage: 'Max 30 karakterer (A-Z og 0-9)',
            validationStatus: (valid: boolean) => {
              setIsValidReferenceId(valid);
            }
          }}
          onChange={(newReferenceId: string) => {
            setOrder({
              ...order,
              referenceId: newReferenceId,
              state: OrderState.DIRTY
            });
          }}
          dataTestId={'reference-id'}
          validateBeforeTouch={true}
        />
      ) : (
        <Typography color={textColor}>{order.referenceId ? order.referenceId : '-'}</Typography>
      )}
    </Box>
  );
}

export function getRemovalOfChargingStandField(fieldSettings: FieldSettings) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;

  return (
    <Box data-testid={'removal-of-charging-stand'}>
      {canEdit && (
        <FormControlLabel
          control={
            <Checkbox
              checked={order.removalOfChargingStand}
              onClick={() => {
                setOrder({
                  ...order,
                  removalOfChargingStand: !order.removalOfChargingStand,
                  state: OrderState.DIRTY
                });
              }}
            />
          }
          label={'Udskiftning'}
        />
      )}
      {!canEdit && (
        <>
          <Typography fontWeight={'bold'}>Udskiftning</Typography>
          <Typography color={textColor}>{order.removalOfChargingStand ? 'Ja' : 'Nej'}</Typography>
        </>
      )}
    </Box>
  );
}

export function getDealerFundedField(fieldSettings: FieldSettings) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;

  return (
    <Box data-testid={'dealer-funded'}>
      {canEdit && (
        <FormControlLabel
          control={
            <Checkbox
              checked={order.dealerFunded}
              onClick={() => {
                setOrder({
                  ...order,
                  dealerFunded: !order.dealerFunded,
                  state: OrderState.DIRTY
                });
              }}
            />
          }
          label={'Finansieret'}
        />
      )}
      {!canEdit && (
        <>
          <Typography fontWeight={'bold'}>Finansieret</Typography>
          <Typography color={textColor}>{order.dealerFunded ? 'Ja' : 'Nej'}</Typography>
        </>
      )}
    </Box>
  );
}

export function getFastTrackField(fieldSettings: FieldSettings) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;

  return (
    <Box data-testid={'fast-track'}>
      {canEdit && (
        <FormControlLabel
          control={
            <Checkbox
              checked={order.rightOfCancellationWaived}
              onClick={() => {
                setOrder({
                  ...order,
                  rightOfCancellationWaived: !order.rightOfCancellationWaived,
                  state: OrderState.DIRTY
                });
              }}
            />
          }
          label={'Fast track'}
        />
      )}
      {!canEdit && (
        <>
          <Typography fontWeight={'bold'}>Fast track</Typography>
          <Typography color={textColor}>
            {order.rightOfCancellationWaived ? 'Ja' : 'Nej'}
          </Typography>
        </>
      )}
    </Box>
  );
}

export function getGroupConsentField(fieldSettings: FieldSettings) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;

  const handleGroupConsentChecked = () => {
    setOrder({
      ...order,
      customer: { ...order.customer, groupConsent: !order.customer.groupConsent },
      state: OrderState.DIRTY
    });
  };

  return (
    <Box data-testid={'group-consent'}>
      {canEdit && (
        <FormControlLabel
          control={
            <Checkbox checked={order.customer.groupConsent} onClick={handleGroupConsentChecked} />
          }
          label={'Koncernsamtykke'}
        />
      )}
      {!canEdit && (
        <>
          <Typography fontWeight={'bold'}>Koncernsamtykke</Typography>
          <Typography color={textColor}>{order.customer.groupConsent ? 'Ja' : 'Nej'}</Typography>
        </>
      )}
    </Box>
  );
}
