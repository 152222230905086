export enum OrderStatusEnum {
  'Bestilling',
  'Ny Ordre',
  'Opfølgning',
  'Sendt til Atea',
  'Afventer kunde',
  'Kunde afvisning',
  'Annullering',
  'Gennemført',
  'Afventer leverandørskifte',
  'Kundedata modtaget',
  'Klar til Atea',
  'Fejl'
}

export enum OrderedOrderStatusEnum {
  'Fejl',
  'Ny Ordre',
  'Bestilling',
  'Kundedata modtaget',
  'Klar til Atea',
  'Sendt til Atea',
  'Afventer leverandørskifte',
  'Opfølgning',
  'Afventer kunde',
  'Annullering',
  'Kunde afvisning',
  'Gennemført'
}

export function statusStringToEnumNumber(status: string) {
  let n = OrderStatusEnum[status as keyof typeof OrderStatusEnum];
  if (typeof n === 'undefined') {
    return -1;
  }
  return n;
}

export function statusToAppearanceSequence(n: number) {
  if (n == -1) return n;
  let name = OrderStatusEnum[n];
  return OrderedOrderStatusEnum[name as keyof typeof OrderedOrderStatusEnum];
}

export function appearanceSequenceToStatus(n: number) {
  if (n == -1) return n;
  let name = OrderedOrderStatusEnum[n];
  return OrderStatusEnum[name as keyof typeof OrderStatusEnum];
}
