import { Box, Typography } from '@mui/material';
import InputWithCopy from '../../../../ui/InputWithCopy/InputWithCopy';
import { OrderState } from '../../../../util/Enums/OrderState';
import { FieldSettings } from '../OrderInfoBoxTabs/CustomerTab';

export function getSellerInformationField(fieldSettings: FieldSettings) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;

  return (
    <>
      <Typography fontWeight={'bold'}>Sælger</Typography>
      <Box display="flex" flexDirection="row" data-testid={'seller-name'}>
        {canEdit ? (
          <InputWithCopy
            fullWidth={true}
            value={order.shopDetails?.sellerName ? order.shopDetails.sellerName : ''}
            onChange={(e: string) => {
              setOrder({
                ...order,
                shopDetails: { ...order.shopDetails, sellerName: e },
                state: OrderState.DIRTY
              });
            }}
          />
        ) : (
          <Typography color={textColor}>
            {order.shopDetails?.sellerName.length > 0 ? order.shopDetails?.sellerName : '-'}
          </Typography>
        )}
      </Box>
    </>
  );
}

export function getSellerEmailInformationField(fieldSettings: FieldSettings) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;

  return (
    <Box data-testid={'seller-email'}>
      <Typography fontWeight={'bold'}>Sælger e-mail</Typography>
      {canEdit ? (
        <InputWithCopy
          fullWidth={true}
          value={order.shopDetails?.sellerEmail ? order.shopDetails.sellerEmail : ''}
          onChange={(e: string) => {
            setOrder({
              ...order,
              shopDetails: { ...order.shopDetails, sellerEmail: e },
              state: OrderState.DIRTY
            });
          }}
        />
      ) : (
        <Typography color={textColor}>
          {order.shopDetails?.sellerEmail.length > 0 ? order.shopDetails?.sellerEmail : '-'}
        </Typography>
      )}
    </Box>
  );
}

export function getShopNameField(fieldSettings: FieldSettings) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;

  return (
    <Box data-testid={'shop-name'}>
      <Typography fontWeight={'bold'}>Shopnavn</Typography>
      {canEdit ? (
        <InputWithCopy
          fullWidth={true}
          value={order.shopDetails?.shopName ? order.shopDetails.shopName : ''}
          onChange={(e: string) => {
            setOrder({
              ...order,
              shopDetails: { ...order.shopDetails, shopName: e },
              state: OrderState.DIRTY
            });
          }}
        />
      ) : (
        <Typography color={textColor}>
          {order.shopDetails?.shopName.length > 0 ? order.shopDetails?.shopName : '-'}
        </Typography>
      )}
    </Box>
  );
}

export function getSellerIdField(fieldSettings: FieldSettings) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;
  return (
    <Box data-testid={'seller-id'}>
      <Typography fontWeight={'bold'}>Sælger ID</Typography>
      {canEdit ? (
        <InputWithCopy
          fullWidth={true}
          value={order.shopDetails?.sellerId ? order.shopDetails.sellerId : ''}
          onChange={(e: string) => {
            setOrder({
              ...order,
              shopDetails: { ...order.shopDetails, sellerId: e },
              state: OrderState.DIRTY
            });
          }}
        />
      ) : (
        <Typography color={textColor}>
          {order.shopDetails?.sellerId.length > 0 ? order.shopDetails?.sellerId : '-'}
        </Typography>
      )}
    </Box>
  );
}

export function getCampaignField(fieldSettings: FieldSettings) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;
  return (
    <Box data-testid={'campaign'}>
      <Typography fontWeight={'bold'}>Kampagne</Typography>
      {canEdit ? (
        <InputWithCopy
          fullWidth={true}
          value={order.shopDetails?.campaign ? order.shopDetails.campaign : ''}
          onChange={(e: string) => {
            setOrder({
              ...order,
              shopDetails: { ...order.shopDetails, campaign: e },
              state: OrderState.DIRTY
            });
          }}
        />
      ) : (
        <Typography color={textColor}>
          {order.shopDetails?.campaign?.length > 0 ? order.shopDetails?.campaign : '-'}
        </Typography>
      )}
    </Box>
  );
}

export function getShopIdField(fieldSettings: FieldSettings) {
  const { order, canEdit, setOrder, textColor } = fieldSettings;
  return (
    <Box data-testid={'shop-id'}>
      <Typography fontWeight={'bold'}>Butiksnummer</Typography>
      {canEdit ? (
        <InputWithCopy
          fullWidth={true}
          value={order.shopDetails?.shopId ? order.shopDetails.shopId : ''}
          onChange={(e: string) => {
            setOrder({
              ...order,
              shopDetails: { ...order.shopDetails, shopId: e },
              state: OrderState.DIRTY
            });
          }}
        />
      ) : (
        <Typography color={textColor}>
          {order.shopDetails?.shopId?.length > 0 ? order.shopDetails?.shopId : '-'}
        </Typography>
      )}
    </Box>
  );
}
