import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Switch,
  FormControlLabel,
  Tooltip,
  Typography
} from '@mui/material';
import { FilterStepModel } from '../../../util/Models/FilterStepModel';
import FilterStepItem from './FilterStepItem';
import {
  statusToAppearanceSequence,
  OrderedOrderStatusEnum,
  appearanceSequenceToStatus,
  OrderStatusEnum
} from '../../../util/Enums/OrderStatusEnum';
import styled from 'styled-components';
import Divider from '@mui/material/Divider';
import Badge from '@mui/material/Badge';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { DateFilterElement, StatusFilterElement } from '../FilterTypes';

interface IFilterButtonProps {
  addFilter: (elm: FilterStepModel | StatusFilterElement | DateFilterElement) => void;
  removeFilter: (elm: FilterStepModel | StatusFilterElement | DateFilterElement) => void;
  resetFilter: boolean;
  steps: FilterStepModel[];
}

const FilterButton = (props: IFilterButtonProps, ref: any) => {
  const { addFilter, removeFilter, resetFilter, steps } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedStatus, setSelectedStatus] = useState(-1);
  const [onlyCompletedOrders, setOnlyCompletedOrders] = useState(false);
  const [numberOfFilterApplied, setNumberOfFiltersApplied] = useState(0);
  const prevStatusRef = useRef<number>(-1);

  const open = Boolean(anchorEl);

  useEffect(() => {
    clearFilters();
  }, [resetFilter]);

  useEffect(() => {
    removeFilter({ status: prevStatusRef.current as OrderStatusEnum });
    prevStatusRef.current = selectedStatus;
    if (selectedStatus !== -1) {
      addFilter({ status: selectedStatus });
    }
  }, [selectedStatus]);

  useEffect(() => {
    countAppliedFilters();
  }, [onlyCompletedOrders, selectedStatus, resetFilter]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const countAppliedFilters = () => {
    let count = 0;

    steps.forEach((element) => {
      if (element.shouldApplyFilter) {
        count++;
      }
    });

    if (onlyCompletedOrders) {
      count++;
    }

    if (selectedStatus >= 0) {
      count++;
    }

    setNumberOfFiltersApplied(count);
  };

  const handleSelect = (event: any) => {
    let value = appearanceSequenceToStatus(event.target.value);
    setSelectedStatus(value);
  };

  const clearFilters = () => {
    steps.forEach((step) => {
      step.filterOnItem = false;
      step.shouldApplyFilter = false;
    });
    setSelectedStatus(-1);
  };

  const handleOrderSwitch = (_e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const event = new CustomEvent('getOrders', { detail: checked });

    setSelectedStatus(-1);

    setOnlyCompletedOrders(checked);

    window.dispatchEvent(event);
  };

  const handleStepFilterChange: React.Dispatch<FilterStepModel> = (elm: FilterStepModel) => {
    if (elm.shouldApplyFilter) {
      removeFilter({
        label: elm.label,
        name: elm.name,
        shouldApplyFilter: elm.shouldApplyFilter,
        filterOnItem: !elm.filterOnItem
      });
      addFilter({
        label: elm.label,
        name: elm.name,
        shouldApplyFilter: elm.shouldApplyFilter,
        filterOnItem: elm.filterOnItem
      });
    } else {
      removeFilter({
        label: elm.label,
        name: elm.name,
        shouldApplyFilter: !elm.shouldApplyFilter,
        filterOnItem: elm.filterOnItem
      });
      removeFilter({
        label: elm.label,
        name: elm.name,
        shouldApplyFilter: !elm.shouldApplyFilter,
        filterOnItem: !elm.filterOnItem
      });
    }

    countAppliedFilters();
  };

  return (
    <>
      <StyledFilterButton>
        <Tooltip title="Tilføj filtre">
          <Button
            color="primary"
            variant="text"
            onClick={handleClick}
            sx={{ height: '40px', minWidth: '110px' }}
          >
            <Typography>FILTRE</Typography>
            <Badge badgeContent={numberOfFilterApplied} color="info">
              <FilterAltOutlinedIcon
                sx={{ fontSize: '1.4em', marginTop: '-2px', marginLeft: '3px' }}
                color="inherit"
              />
            </Badge>
          </Button>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <FormControl fullWidth={true} sx={{ padding: '10px', marginTop: '10px' }}>
            <InputLabel id="select-status-label">Vælg status</InputLabel>
            <Select
              labelId="select-status-label"
              label="Vælg status"
              id="select-status"
              value={statusToAppearanceSequence(selectedStatus)}
              onChange={handleSelect}
              disabled={onlyCompletedOrders}
            >
              <MenuItem value={-1}>Alle statusser</MenuItem>
              {Object.keys(OrderedOrderStatusEnum)
                .filter((v) => isNaN(Number(v)) && v !== 'Gennemført')
                .map((k, i) => (
                  <MenuItem key={i} value={i}>
                    {k}
                  </MenuItem>
                ))}
            </Select>
            <FormControlLabel
              sx={{ marginLeft: '5px', marginTop: '20px', marginBottom: '20px' }}
              control={<Switch checked={onlyCompletedOrders} onChange={handleOrderSwitch} />}
              label="Kun gennemførte ordrer"
            />
            <Divider />
          </FormControl>
          {steps.map((step, key) => (
            <MenuItem key={key}>
              <FilterStepItem item={step} onchange={handleStepFilterChange}></FilterStepItem>
              {step.name === 'picturesSentToAtea' ? 'Sendt til Atea' : step.label}
            </MenuItem>
          ))}
        </Menu>
      </StyledFilterButton>
    </>
  );
};

const StyledFilterButton = styled.span``;

export default FilterButton;
