import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar
} from '@mui/material';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { EMobilityDialog, EMobilitySnackbar } from '../../data-access/MessageService';
import { ImageInfoModel } from '../../util/Models/ImageInfoModel';

const ImageDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 80vw;
    max-height: 80vh;

    img {
      max-width: -webkit-fill-available;
    }
  }
`;

export const SnackAndDialog = () => {
  const [snack, setSnack] = useState<EMobilitySnackbar | null>(null);
  const [snackOpen, setSnackOpen] = useState<boolean>(false);

  const [dialog, setDialog] = useState<EMobilityDialog | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [imagePreview, setImagePreview] = useState<ImageInfoModel | null>(null);

  useEffect(() => {
    window.addEventListener('EMobilitySnackbar', handleSnackbar);
    window.addEventListener('EMobilityDialog', handleDialog);

    window.addEventListener('imagePreview', handleImagePreview);
  }, []);

  const handleImagePreview = (e: Event) => {
    let event = (e as CustomEvent).detail as ImageInfoModel;
    setImagePreview(event);
  };

  const handleSnackbar = (e: Event) => {
    let event = (e as CustomEvent).detail as EMobilitySnackbar;
    setSnack(event);
    setSnackOpen(true);
  };

  const handleDialog = (e: Event) => {
    let event = (e as CustomEvent).detail as EMobilityDialog;
    setDialog(event);
    setDialogOpen(true);
  };

  return (
    <>
      <Snackbar
        onClick={() => {
          setSnackOpen(false);
        }}
        open={snackOpen}
        autoHideDuration={snack?.autoHideDuration || 5000}
        onClose={() => {
          setSnackOpen(false);
        }}
        message={snack?.message}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ marginTop: '-15px' }}
      >
        <Alert severity={snack?.severity}>{snack?.message}</Alert>
      </Snackbar>

      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        sx={{ width: '80vw' }}
      >
        <DialogTitle id="alert-dialog-title">{dialog?.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialog?.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-TestId="close-dialog"
            onClick={() => {
              setDialogOpen(false);
            }}
            autoFocus
          >
            Luk
          </Button>
        </DialogActions>
      </Dialog>

      {/* IMAGE PREVIEW */}
      <ImageDialog
        className="image-dialog"
        open={imagePreview != null}
        onClose={() => {
          setImagePreview(null);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">{imagePreview?.name}</DialogTitle>
        <DialogContent>
          <img src={'data:image/jpeg;base64, ' + imagePreview?.base64Content} />
        </DialogContent>
      </ImageDialog>
    </>
  );
};
