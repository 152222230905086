import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  styled
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { IOrderTableData } from '../../../util/Models/OrderTableDataModel';
import OrderInfoBoxContent from './OrderInfoBoxContent';
import OrderInfoBoxFooter from './OrderInfoBoxFooter';
import OrderInfoBoxHeader from './OrderInfoBoxHeader';
import CloseIcon from '@mui/icons-material/Close';
import OrderService from '../../../data-access/OrderService';
import { IConfiguration } from '../../../util/Models/ConfigurationModel';
import { useEditOrderContext } from '../../../data-access/Context/EditOrderContext';
import { IContractInformation } from '../../../util/Models/ContractModel';
import { useKeyDown } from '../../../hooks/useKeyDown';
import usePrompt from '../../../hooks/usePrompt';
import { OrderLock } from '../../../util/Models/OrderLock';
import { OrderState } from '../../../util/Enums/OrderState';

interface Props {
  children?: React.ReactNode;
  order: IOrderTableData;
  onClose: () => void;
  configuration: IConfiguration;
  userName: string;
  allContracts: Array<IContractInformation>;
  orderLocks: OrderLock[];
  cancelEdit: () => void;
  isNewOrder: boolean;
}

export const OrderInfoBox: React.FC<Props> = (props) => {
  const { editingState, setEditingState, newComment, setNewComment } = useEditOrderContext();
  const [order, setOrder] = useState(props.order);
  const [isValidOrderInfoBoxHeader, setIsValidOrderInfoBoxHeader] = useState(true);
  const [isValidOrderInfoBoxContent, setIsValidOrderInfoBoxContent] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    setOrder(props.order);
  }, [props.order]);

  useKeyDown(() => {
    confirmClose();
  }, ['Escape']);

  const confirmClose = useCallback(() => {
    if (editingState && (order.state === OrderState.DIRTY || newComment.length > 0)) {
      setOpenDialog(true);
    } else {
      handleClose();
    }
  }, [editingState, newComment, order.state]);

  const handleClose = useCallback(() => {
    if (editingState) {
      setEditingState(false);
      setNewComment('');
      OrderService.unlockOrder(order.id);
      props.cancelEdit();
    }
    props.onClose();
  }, [editingState]);

  const orderLockedBy = () => {
    let target = props.orderLocks.find((o) => o.orderId === order.id);
    if (target) {
      return target.user;
    }
    return '';
  };

  function getDialogText() {
    return (
      <DialogContent sx={{ paddingBottom: '10px' }}>
        {order.state === OrderState.DIRTY && (
          <DialogContentText sx={{ color: 'red' }}>Du har ugemte ændringer</DialogContentText>
        )}
        {newComment.length > 0 && (
          <DialogContentText sx={{ color: 'red' }}>Du har en ugemt kommentar</DialogContentText>
        )}
      </DialogContent>
    );
  }

  usePrompt('Ændringer, du har foretaget, gemmes måske ikke!', editingState);

  return (
    <Box sx={OrderInfoBoxStyle}>
      <CloseButtonStyle startIcon={<CloseIcon />} onClick={confirmClose} />
      <OrderInfoBoxHeader order={order} />
      <OrderInfoBoxContent
        order={order}
        canEdit={editingState}
        setOrder={setOrder}
        setIsValid={(isValid: boolean) => setIsValidOrderInfoBoxContent(isValid)}
        configuration={props.configuration}
        userName={props.userName}
        allContracts={props.allContracts}
        isNewOrder={props.isNewOrder}
      />
      <OrderInfoBoxFooter
        username={props.userName}
        setOrder={setOrder}
        lockedBy={orderLockedBy()}
        close={props.onClose}
        order={order}
        isValidOrderInfoBox={isValidOrderInfoBoxContent && isValidOrderInfoBoxHeader}
      />
      <Dialog
        aria-labelledby="dialog-title"
        aria-describedby="dialog-descroption"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <IconButton
          style={{ position: 'absolute', top: '0', right: '0' }}
          onClick={() => setOpenDialog(false)}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle id="dialog-title">Er du sikker på, at du vil lukke ordren?</DialogTitle>
        {getDialogText()}

        <StyledDialogActions>
          <Button autoFocus onClick={handleClose} variant={'contained'} color={'primary'}>
            Kassér ændringer
          </Button>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
          >
            Nej jeg vil ikke lukke ordren
          </Button>
        </StyledDialogActions>
      </Dialog>
    </Box>
  );
};

const StyledDialogActions = styled(DialogActions)`
  display: block !important;
  padding-top: 0px !important;
  text-align: center;
`;

const OrderInfoBoxStyle = {
  borderLeft: 1,
  borderColor: 'lightgrey',
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
};

const CloseButtonStyle = styled(Button)`
  position: absolute !important;
  right: 20px;
  top: 20px;
  justify-content: center;
  max-width: 20px;
  & > span {
    margin: 0;
  }
`;

export default OrderInfoBox;
