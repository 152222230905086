import { IconButton, Input } from '@mui/material';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import OrderService from '../../data-access/OrderService';
import ClearIcon from '@mui/icons-material/Clear';
import { useOrderContext } from '../../data-access/Context/OrderContext';
import { Types } from '../../data-access/Context/OrderReducer';

interface Props {
  onlyFastTrackOrders: boolean;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const Searchbar = (props: Props) => {
  const [completed, setCompleted] = useState(false);
  const { dispatch } = useOrderContext();
  const [query, setQuery] = useState<string>('');

  const onlyCompletedListener = (e: any) => {
    setCompleted(e.detail);
  };

  useEffect(() => {
    window.removeEventListener('getOrders', onlyCompletedListener);
    window.addEventListener('getOrders', onlyCompletedListener);
  }, []);

  const handleSearch = async () => {
    let result = await OrderService.getOrders(completed, props.onlyFastTrackOrders, query);

    dispatch({
      type: Types.Get,
      payload: result
    });

    props.setCurrentPage(0);
  };

  useEffect(() => {
    handleSearch();
  }, [props.onlyFastTrackOrders]);

  const clearSearch = () => {
    setQuery('');

    const event = new CustomEvent('getOrders', { detail: completed });

    window.dispatchEvent(event);
  };

  const handleKeyUp = (e: any) => {
    if (e.key === 'Enter' && query.length > 0) {
      handleSearch();
    }

    if (query.length === 0) {
      clearSearch();
    }
  };

  return (
    <SearchWrapper>
      <Input
        onKeyUp={handleKeyUp}
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        placeholder="Søg"
        endAdornment={
          <>
            <IconButton disabled={query.length === 0} onClick={clearSearch} aria-label="clear">
              {query.length === 0 ? <SearchIcon /> : <ClearIcon />}
            </IconButton>
          </>
        }
      ></Input>
    </SearchWrapper>
  );
};
const SearchWrapper = styled.div`
  padding-right: 5px;
  input {
    width: 200px;
  }
`;

export default Searchbar;
