import { List, ListItemButton } from '@mui/material';
import { useImperativeHandle, useState, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { IDawaSuggestion } from '../../util/Interfaces/IDawaSuggestion';

export interface IDawaSuggestionFeature {
  data: Array<IDawaSuggestion>;
  click: (item: IDawaSuggestion) => void;
  open: boolean;
  selected: number;
}

export type DawaSuggestionHandle = {
  toggleOpen: (open: boolean) => void;
};

const Wrapper = styled.div<{ isOpen: boolean }>`
  position: absolute;
  background-color: transparent;
  z-index: 50;
  box-shadow: none;

  ${(props) =>
    props.isOpen &&
    css`
      background-color: white;
      box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%),
        0px 3px 14px 2px rgb(0 0 0 / 12%);
    `}

  .dawa-list {
    transition: 0.2s transform;
    transition: 0.5s opacity;
    opacity: 1;
  }

  .dawa-list-closed {
    transform: translateY(-9999px);
    height: 0;
    opacity: 0;
  }
`;

const DawaSuggestionList = forwardRef<DawaSuggestionHandle, IDawaSuggestionFeature>(
  (props: IDawaSuggestionFeature, ref) => {
    const [suggestionsOpen, setSuggestionsOpen] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      toggleOpen(open: boolean) {
        setSuggestionsOpen(open);
      }
    }));

    const handleListItemClick = (item: IDawaSuggestion) => {
      props.click(item);
    };

    return (
      <Wrapper isOpen={suggestionsOpen && props.data.length > 0}>
        <List className={`dawa-list ${!suggestionsOpen && 'dawa-list-closed'}`}>
          {props.data.map((item, key) => (
            <ListItemButton
              tabIndex={-1}
              aria-autocomplete="list"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleListItemClick(item);
              }}
              key={item.adresse.id}
              selected={props.selected == key ? true : false}
            >
              {item.tekst}
            </ListItemButton>
          ))}
        </List>
      </Wrapper>
    );
  }
);

export default DawaSuggestionList;
