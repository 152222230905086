import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Icon, Tooltip } from '@mui/material';

import React from 'react';

const WarningIcon: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Tooltip title={title}>
      <Icon>
        <WarningAmberRoundedIcon color="warning" />{' '}
      </Icon>
    </Tooltip>
  );
};

export default WarningIcon;
