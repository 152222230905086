import { Configuration, RedirectRequest } from '@azure/msal-browser';

// const msalId = Cookies.get('msal_client_id') as string
// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: 'de60c056-e730-4253-9bb3-ed90a48e25df' as string,
    authority: 'https://login.microsoftonline.com/a6230a1c-393a-4c9e-9938-a643402658d9',
    redirectUri: '/',
    postLogoutRedirectUri: '/'
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  prompt: 'select_account',
  scopes: [`${msalConfig.auth.clientId}/.default`]
};
