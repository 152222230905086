export class OrderLock{
    orderId:string = "";
    user:string = "";

    constructor(_orderId:string, _user:string){
        this.orderId = _orderId;
        this.user = _user;
    }
   

}