import { useEffect } from 'react';

export const useOnClickOutside = (ref: any, onClickOutside: any) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      let outside = !ref.current.contains(event.target);
      if (outside) {
        onClickOutside();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [ref, onClickOutside]);
};
