import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { createNewConfiguration, IConfiguration } from '../Libs/util/Models/ConfigurationModel';
import ConfigurationService from '../Libs/data-access/ConfigurationService';
import { useNavigate } from 'react-router-dom';
import MessageService, { SnackbarSeverity } from '../Libs/data-access/MessageService';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import usePrompt from '../Libs/hooks/usePrompt';
import { PriceService } from '../Libs/util/Services/PriceService';

interface Props {
  readOnly: boolean;
  configuration?: IConfiguration;
  getConfiguration: () => void;
}

enum ListTypes {
  Prices,
  SalesChannels,
  Partners,
  Outbounds,
  CompanyCars
}
const Admin: React.FC<Props> = (props) => {
  const { readOnly } = props;
  const [price, setPrice] = useState('');
  const [prices, setPrices] = useState<string[]>([]);
  const [priceError, setPriceError] = useState(false);

  const [salesChannel, setSalesChannel] = useState('');
  const [salesChannels, setSalesChannels] = useState<string[]>([]);

  const [partner, setPartner] = useState('');
  const [partners, setPartners] = useState<string[]>([]);

  const [outbound, setOutbound] = useState('');
  const [outbounds, setOutbounds] = useState<string[]>([]);

  const [companyCar, setCompanyCar] = useState('');
  const [companyCars, setCompanyCars] = useState<string[]>([]);

  const [data, setData] = useState<IConfiguration>();

  const [disableSave, setDisableSave] = useState<boolean>(true);

  const navigate = useNavigate();

  const englishNumberFormatter = new Intl.NumberFormat('en-GB', {
    useGrouping: false
  });

  useEffect(() => {
    setData(props.configuration);
  }, [props.configuration]);

  useEffect(() => {
    updateLists();
  }, [data]);

  function updateLists() {
    let tempPrices = data?.prices.map(String);
    setPrices(tempPrices ? tempPrices : []);
    let tempSalesChannels = data?.salesChannels;
    setSalesChannels(tempSalesChannels ? tempSalesChannels : []);
    let tempPartners = data?.partners;
    setPartners(tempPartners ? tempPartners : []);
    let tempOutbounds = data?.outbounds;
    setOutbounds(tempOutbounds ? tempOutbounds : []);
    let tempCompanyCars = data?.companyCars;
    setCompanyCars(tempCompanyCars ? tempCompanyCars : []);
  }

  function validateAndSetPrices() {
    const regex = /^[0-9.,]+$/;

    let newPrice = englishNumberFormatter.format(Number(price));

    if (regex.test(newPrice)) {
      setPriceError(false);
      setPrices([...prices, newPrice]);
      setPrice('');
      setDisableSave(false);
    } else {
      setPriceError(true);
    }
  }

  function validateAndSetSalesChannels() {
    const sorted = [...salesChannels, salesChannel];
    setSalesChannels(sorted);
    setSalesChannel('');
    setDisableSave(false);
  }

  function validateAndSetPartners() {
    const sorted = [...partners, partner];
    setPartners(sorted);
    setPartner('');
    setDisableSave(false);
  }

  function validateAndSetOutbounds() {
    const sorted = [...outbounds, outbound];
    setOutbounds(sorted);
    setOutbound('');
    setDisableSave(false);
  }

  function validateAndSetCompanyCars() {
    const sorted = [...companyCars, companyCar];
    setCompanyCars(sorted);
    setCompanyCar('');
    setDisableSave(false);
  }

  function handleDelete(index: number, list: string[], listType: ListTypes): void {
    if (listType === ListTypes.Prices) {
      setPrices(list.filter((_, i) => i !== index));
    } else if (listType === ListTypes.SalesChannels) {
      setSalesChannels(list.filter((_, i) => i !== index));
    } else if (listType === ListTypes.Partners) {
      setPartners(list.filter((_, i) => i !== index));
    } else if (listType === ListTypes.Outbounds) {
      setOutbounds(list.filter((_, i) => i !== index));
    } else if (listType === ListTypes.CompanyCars) {
      setCompanyCars(list.filter((_, i) => i !== index));
    }
    setDisableSave(false);
  }

  const saveConfiguration = async (navigateBack?: boolean) => {
    setDisableSave(true);

    let res = await ConfigurationService.addConfiguration(
      createNewConfiguration(prices, salesChannels, partners, outbounds, companyCars)
    );
    if (res) {
      setData(res);

      MessageService.showSnackbar({
        message: 'Konfiguration gemt med succes',
        severity: SnackbarSeverity.SUCCESS
      });

      props.getConfiguration();

      if (navigateBack) {
        navigate('/');
      }
    } else {
      MessageService.showDialog({
        title: 'Der opstod en fejl',
        message: 'Det lykkedes ikke at gemme konfigurationen.'
      });
    }
  };

  const handleChangeSortOrder = (
    listType: ListTypes,
    down: boolean,
    index: number,
    list: string[]
  ) => {
    setDisableSave(false);

    let arr = [...list];

    let first = '';
    let second = '';

    first = list[down ? index + 1 : index - 1];
    second = list[index];

    arr.splice(index, 1, first);
    arr.splice(down ? index + 1 : index - 1, 1, second);

    if (listType === ListTypes.Prices) {
      setPrices(arr);
    } else if (listType === ListTypes.SalesChannels) {
      setSalesChannels(arr);
    } else if (listType === ListTypes.Partners) {
      setPartners(arr);
    } else if (listType === ListTypes.Outbounds) {
      setOutbounds(arr);
    } else if (listType === ListTypes.CompanyCars) {
      setCompanyCars(arr);
    }
  };

  function generateLinesFromList(list: string[], listType: ListTypes) {
    return list.map((value, index) => (
      <ListItem
        draggable={true}
        key={index}
        secondaryAction={
          <div className="action-buttons">
            <IconButton
              disabled={index === 0}
              onClick={() => handleChangeSortOrder(listType, false, index, list)}
              edge="end"
              aria-label="change-order"
            >
              <ArrowCircleUpIcon />
            </IconButton>
            <IconButton
              disabled={index === list.length - 1}
              onClick={() => handleChangeSortOrder(listType, true, index, list)}
              edge="end"
              aria-label="change-order"
            >
              <ArrowCircleDownIcon />
            </IconButton>
            <IconButton
              disabled={disableDelete(value)}
              onClick={() => handleDelete(index, list, listType)}
              edge="end"
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          </div>
        }
      >
        <ListItemText
          primary={!isNaN(Number(value)) ? PriceService.numberToCurrency(Number(value)) : value}
        />
      </ListItem>
    ));
  }

  const configurationStyle = () => {
    return {
      sx: {
        flexGrow: 1,
        maxWidth: 1280,
        padding: '0px 24px'
      }
    };
  };

  const disableDelete = (value: string) => {
    return ['9995', 'Telefon', 'Partnerskab', 'Outbound', 'TCC', 'Firmabil'].includes(
      value.toString()
    );
  };

  const isBlocking = () => {
    return !disableSave;
  };

  usePrompt(
    'Du har rettet i konfigurationen uden at gemme. Hvis du klikker OK, gemmes dine rettelser ikke.',
    isBlocking()
  );

  return (
    <>
      {readOnly ? null : (
        <>
          <Box sx={{ padding: '24px 0px 24px 24px' }}>
            <Button
              onClick={() => saveConfiguration(true)}
              variant="contained"
              disabled={readOnly || disableSave}
            >
              Gem konfiguration og returner til forside
            </Button>
            <Button
              sx={{ marginLeft: '20px' }}
              onClick={() => saveConfiguration()}
              variant="contained"
              disabled={readOnly || disableSave}
              data-testid="save-configuration"
            >
              Gem konfiguration
            </Button>
          </Box>
          <Box {...configurationStyle()} mb="24px">
            <Grid container spacing={2} width={'100%'}>
              <Grid item xs={12} md={6} lg={2.4}>
                <TextField
                  onChange={(e) => setPrice(e.target.value)}
                  onKeyUp={(e) => (e.key === 'Enter' ? validateAndSetPrices() : null)}
                  label="Tilføj pris og tryk enter"
                  variant="outlined"
                  value={price}
                  fullWidth
                  error={priceError}
                  helperText={priceError ? 'Pris skal være et tal' : null}
                  type={'number'}
                />
                <CustomList>{generateLinesFromList(prices, ListTypes.Prices)}</CustomList>
              </Grid>
              <Grid item xs={12} md={6} lg={2.4}>
                <TextField
                  onChange={(e) => setSalesChannel(e.target.value)}
                  onKeyUp={(e) => (e.key === 'Enter' ? validateAndSetSalesChannels() : null)}
                  label="Tilføj salgskanal og tryk enter"
                  variant="outlined"
                  value={salesChannel}
                  fullWidth
                />
                <CustomList>
                  {generateLinesFromList(salesChannels, ListTypes.SalesChannels)}
                </CustomList>
              </Grid>
              <Grid item xs={12} md={6} lg={2.4}>
                <TextField
                  onChange={(e) => setPartner(e.target.value)}
                  onKeyUp={(e) => (e.key === 'Enter' ? validateAndSetPartners() : null)}
                  label="Tilføj partner og tryk enter"
                  variant="outlined"
                  value={partner}
                  fullWidth
                />
                <CustomList>{generateLinesFromList(partners, ListTypes.Partners)}</CustomList>
              </Grid>
              <Grid item xs={12} md={6} lg={2.4}>
                <TextField
                  onChange={(e) => setOutbound(e.target.value)}
                  onKeyUp={(e) => (e.key === 'Enter' ? validateAndSetOutbounds() : null)}
                  label="Tilføj outbound og tryk enter"
                  variant="outlined"
                  value={outbound}
                  fullWidth
                />
                <CustomList>{generateLinesFromList(outbounds, ListTypes.Outbounds)}</CustomList>
              </Grid>

              <Grid item xs={12} md={6} lg={2.4}>
                <TextField
                  onChange={(e) => setCompanyCar(e.target.value)}
                  onKeyUp={(e) => (e.key === 'Enter' ? validateAndSetCompanyCars() : null)}
                  label="Tilføj firmabil partner og tryk enter"
                  variant="outlined"
                  value={companyCar}
                  fullWidth
                />
                <CustomList>{generateLinesFromList(companyCars, ListTypes.CompanyCars)}</CustomList>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

const CustomList = styled(List)`
  background-color: rgb(245, 245, 245);
  box-shadow: 0px 5px 2px -5px rgb(0 0 0 / 10%), 0px 2px 2px 1px rgb(0 0 0 / 5%),
    0px 3px 4px 2px rgb(0 0 0 / 5%);
  max-height: 75vh;
  overflow-y: auto;

  .action-buttons {
    transform: scale(0);
  }

  > li:hover {
    background-color: rgba(241, 123, 37, 0.05);

    .action-buttons {
      transform: scale(1);
    }
  }
`;

export default Admin;
