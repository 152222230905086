import { Box, Table, TableBody, TablePagination } from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { IConfiguration } from '../../../util/Models/ConfigurationModel';
import { IOrderTableData } from '../../../util/Models/OrderTableDataModel';
import { IHeadCell, SortOrder } from '../../../util/Models/TableHeadModels';
import { useMsal } from '@azure/msal-react';
import OrderListItem from './OrderListItem';
import styled from 'styled-components';
import OrderTableHead from './OrderTableHead';
import { OrderLock } from '../../../util/Models/OrderLock';

interface Props {
  filteredOrders: Array<IOrderTableData>;
  readOnly: boolean;
  configuration?: IConfiguration;
  onlyFastTrackOrders: boolean;
  setOnlyFastTrackOrders: Dispatch<SetStateAction<boolean>>;
  onSelectOrder: (state: any) => void;
  orderLocks: OrderLock[];
  selectedOrderId: string;
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const OrderTable: React.FC<Props> = (props) => {
  const { filteredOrders, onlyFastTrackOrders, setOnlyFastTrackOrders } = props;
  const { accounts } = useMsal();
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
  const [orderBy, setOrderBy] = useState<string>('applicationDate');
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [openId, setOpenId] = useState<string>('');

  const headerCells: IHeadCell[] = [
    {
      id: 'customer.name',
      numeric: false,
      label: 'Navn'
    },
    {
      id: 'installationAddress',
      numeric: false,
      label: 'Adresse'
    },
    {
      id: 'sonwinCustomerNumber',
      numeric: false,
      label: 'Kundenummer'
    },
    {
      id: 'customer.phone',
      numeric: true,
      label: 'Telefon'
    },
    {
      id: 'applicationDate',
      numeric: false,
      label: 'Bestillingsdato'
    },
    {
      id: 'status',
      numeric: false,
      label: 'Status'
    }
  ];

  const handleRequestSort = (_event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sort = (orderby: string, arr: any[]) => {
    const prop = orderby.split('.');
    let len = prop.length;
    const desc = sortOrder == 'desc' ? 1 : -1;
    arr.sort(function (a, b) {
      let i = 0;
      while (i < len) {
        a = a[prop[i] as keyof any];
        b = b[prop[i] as keyof any];
        i++;
      }
      if (a < b) {
        return -1 * desc;
      } else if (a > b) {
        return 1 * desc;
      } else {
        return 0;
      }
    });
    return arr;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    props.setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    props.setCurrentPage(0);
  };

  return (
    <TableBox>
      <Table stickyHeader data-testid="orders-table">
        <OrderTableHead
          sortOrder={sortOrder}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          headCells={headerCells}
          onlyFastTrackOrders={onlyFastTrackOrders}
          setOnlyFastTrackOrders={setOnlyFastTrackOrders}
        />
        <TableBody>
          {filteredOrders &&
            sort(orderBy, filteredOrders)
              .slice(props.currentPage * rowsPerPage, props.currentPage * rowsPerPage + rowsPerPage)
              .map((dataRow, _index) => {
                if (dataRow.id === openId) {
                  dataRow.lockedBy = accounts[0]?.username || '';
                  setOpenId('');
                }
                return (
                  <OrderListItem
                    lockedBy={props.orderLocks.find((o) => o.orderId === dataRow.id)?.user || ''}
                    onClick={() => props.onSelectOrder(dataRow)}
                    key={dataRow.id}
                    data={dataRow}
                    userName={accounts[0]?.username || ''}
                    isSelected={props.selectedOrderId === dataRow.id}
                  />
                );
              })}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        rowsPerPageOptions={[10, 15, 30, 60, 100]}
        count={filteredOrders.length}
        rowsPerPage={rowsPerPage}
        page={props.currentPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage="Ordrer pr. side:"
        sx={{ mb: 2 }}
      />
    </TableBox>
  );
};

const TableBox = styled(Box)`
  overflow-x: hidden;
  max-height: calc(100vh - 142px); // Navbar 65 + OrderPageHeader 77 = 142px
  margin: 0 16px;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export default OrderTable;
